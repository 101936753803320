import "./App.css";
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { LoadingProvider, useLoading } from './context/LoadingContext';
import LoadingOverlay from "./components/LoadingOverlay";
import RecommendedCarousel from "./components/RecommendedCarousel";
import RouteChangeHandler from './components/RouteChangeHandler';
import Simulation from './pages/Simulation';
import Plinko from './pages/Plinko';
import PlinkoSim from './pages/PlinkoSim';
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import Home from './pages/Home';
import SignIn from './components/SignIn';
import Register from './components/Register';
import Tables from './pages/Tables';
import Slots from './pages/Slots';
import Originals from './pages/Originals';
import AllGames from './pages/AllGames';
import Dice from './pages/Dice';
import Mines from './pages/Mines';
import Keno from './pages/Keno';
import SidebarNav from "./components/SidebarNav";
import SlotGame from './pages/slots/SlotGame';
import ResetPassword from "./pages/ResetPassword";
import { AuthProvider } from './context/AuthContext';
import Settings from './pages/Settings';
import ResponsibleGambling from './pages/footer-pages/ResponsibleGambling';
import Accessibility from './pages/footer-pages/Accessibility';
import CodeOfEthics from './pages/footer-pages/CodeOfEthics';
import Disclaimer from './pages/footer-pages/Disclaimer';
import PrivacyPolicy from './pages/footer-pages/PrivacyPolicy';
import Terms from './pages/footer-pages/Terms';
import Promo from './pages/Promo';
import ScrollToTop from './components/ScrollToTop';

function App() {

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  // const [isLoading, setIsLoading] = useState(false);

  // useEffect(() => {
  //   const handleStart = () => setIsLoading(true);
  //   const handleComplete = () => setIsLoading(false);

  //   // Simulate navigation events (e.g., from React Router)
  //   window.addEventListener('startLoading', handleStart);
  //   window.addEventListener('stopLoading', handleComplete);

  //   return () => {
  //     window.removeEventListener('startLoading', handleStart);
  //     window.removeEventListener('stopLoading', handleComplete);
  //   };
  // }, []);

  return (
    <>
      <AuthProvider>
        <LoadingProvider>
          <Router>
            {/* <LoadingOverlay /> */}
            <RouteChangeHandler />
            <ScrollToTop />
            <div className={`app-container ${isSidebarOpen ? 'sidebar-open' : ''}`}>
              <SidebarNav isOpen={isSidebarOpen} setIsOpen={setIsSidebarOpen} toggleSidebar={toggleSidebar} />
              <div className={`main-content ${isSidebarOpen ? 'shifted' : ''}`}>
                <Navbar />
                <Routes>
                  <Route path="/" exact={true} element={<Home />} />
                  <Route path="/plinko" exact={true} element={<Plinko />} />
                  <Route path="/plinkosim" exact={true} element={<PlinkoSim />} />
                  <Route path="/simulation" exact={true} element={<Simulation />} />
                  <Route path="keno" exact={true} element={<Keno />} />
                  <Route path="/signin" exact={true} element={<SignIn />} />
                  <Route path="/register" exact={true} element={<Register />} />
                  <Route path="/originals" exact={true} element={<Originals />} />
                  <Route path="/tables" exact={true} element={<Tables />} />
                  <Route path="/slots" exact={true} element={<Slots />} />
                  <Route path="/all-games" exact={true} element={<AllGames />} />
                  <Route path="/dice" exact={true} element={<Dice />} />
                  <Route path="/mines" exact={true} element={<Mines />} />
                  {/* <Route path="/crash" exact={true} element={<Crash />} /> */}
                  {/* <Route path="/blackjack" exact={true} element={<Blackjack />} /> */}
                  {/* <Route path="/wheel" exact={true} element={<Wheel />} /> */}
                  {/* <Route path="/pvpcoin" exact={true} element={<PVPCoin />} /> */}
                  {/* <Route path="/tower" exact={true} element={<Tower />} /> */}
                  <Route path="/reset-password" element={<ResetPassword />} />
                  {/* <Route path="/baccarat" exact={true} element={<Baccarat />} /> */}
                  <Route path="/slotgame/:gameId" exact={true} element={<SlotGame />} />
                  {/* <Route path="/roulette" exact={true} element={<Roulette />} /> */}
                  <Route path="/settings" exact={true} element={<Settings />} />
                  <Route path="/responsible" exact={true} element={<ResponsibleGambling />} />
                  <Route path="/accessibility" exact={true} element={<Accessibility />} />
                  <Route path="/code-of-ethics" exact={true} element={<CodeOfEthics />} />
                  <Route path="/disclaimer" exact={true} element={<Disclaimer />} />
                  <Route path="/privacy-policy" exact={true} element={<PrivacyPolicy />} />
                  <Route path="/terms" exact={true} element={<Terms />} />
                  <Route path="/promo" exact={true} element={<Promo />} />
                </Routes>
                <Footer />
              </div>
            </div>
          </Router>
        </LoadingProvider>
      </AuthProvider>
    </>
  );
}

export default App;