import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins, faHatWizard } from '@fortawesome/free-solid-svg-icons';

const Livefeed = () => {

  const [recentGames, setRecentGames] = useState([]);

  useEffect(() => {
    // Establish WebSocket connection
    const ws = new WebSocket("https://backend-service-643728542364.southamerica-east1.run.app"); // Replace with your server's address

    ws.onopen = () => {
      console.log("WebSocket connected to the server");
    };

    // Handle incoming messages
    ws.onmessage = (event) => {
      try {
        const message = JSON.parse(event.data);
        console.log("Message received from WebSocket:", message); // Debug log

        if (message.type === "RECENT_GAMES_UPDATE") {
          setRecentGames(message.data); // Update state with received data
          console.log("Updated recentGames state:", message.data); // Debug log for state update
        }
      } catch (error) {
        console.error("Error parsing WebSocket message:", error);
      }
    };

    ws.onclose = () => {
      console.log("WebSocket disconnected");
    };

    ws.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    // Clean up WebSocket connection on unmount
    return () => {
      ws.close();
    };
  }, []);

  return (
    <div className="livefeed">
      <h1 className="game-slider-header" style={{ marginBottom: "20px" }}>
        <FontAwesomeIcon icon={faHatWizard} className="game-icons slider-head-icon" />
        Recent Games
      </h1>
      <div className="livefeed-row top-livefeed-row">
        <div className="livefeed-row-one">Game</div>
        <div className="livefeed-row-one">User</div>
        <div className="livefeed-row-one">Wager</div>
        <div className="livefeed-row-one livefeed-multiplier">Multiplier</div>
        <div className="livefeed-row-one livefeed-payout">Payout</div>
      </div>

      {recentGames.length === 0 ? (
        <p>No recent games to display</p>
      ) : (
        recentGames.map((game, index) => (
          <div key={index} className="livefeed-row">
            <div className="livefeed-game-name">{game.game}</div>
            <div className="livefeed-username">{game.user}</div>
            <div className="livefeed-bet-amount">
              <FontAwesomeIcon icon={faCoins} className="livefeed-coin" />
              ${game.betAmount.toFixed(2)}
            </div>
            <div className="livefeed-multiplier">{game.multiplier.toFixed(2)}x</div>
            <div className="livefeed-payout">
              <FontAwesomeIcon icon={faCoins} className="livefeed-coin" />
              ${game.payout.toFixed(2)}
            </div>
          </div>
        ))
      )}

      {/* {data.map((item, index) => (
        <div key={index} className="livefeed-row">
          <div className="livefeed-game-name">{item.game}</div>
          <div className="livefeed-username">{item.username}</div>
          <div className="livefeed-bet-amount">
            <FontAwesomeIcon icon={faCoins} className="livefeed-coin" />
            {item.betAmount}
          </div>
          <div className="livefeed-multiplier">{item.multiplier}x</div>
          <div className="livefeed-payout">
            <FontAwesomeIcon icon={faCoins} className="livefeed-coin" />
            {item.payout}
          </div>
        </div>
      ))} */}
    </div>
  );
};

export default Livefeed;

// import React, { useEffect, useState } from "react";

// const LiveGameFeed = () => {
//   const [recentGames, setRecentGames] = useState([]);

//   useEffect(() => {
//     const socket = new WebSocket("ws://localhost:8080");

//     socket.onmessage = (event) => {
//       const newGame = JSON.parse(event.data);
//       setRecentGames((prevGames) => [newGame, ...prevGames].slice(0, 10)); // Limit to 10 recent games
//     };

//     return () => {
//       socket.close(); // Cleanup on component unmount
//     };
//   }, []);

//   return (
//     <div>
//       <h2>Recent Games</h2>
//       <table>
//         <thead>
//           <tr>
//             <th>Game</th>
//             <th>User</th>
//             <th>Bet Amount</th>
//             <th>Multiplier</th>
//             <th>Payout</th>
//           </tr>
//         </thead>
//         <tbody>
//           {recentGames.map((game, index) => (
//             <tr key={index}>
//               <td>{game.game}</td>
//               <td>{game.username}</td>
//               <td>{game.betAmount.toFixed(2)}</td>
//               <td>{game.multiplier.toFixed(2)}x</td>
//               <td>{game.payout.toFixed(2)}</td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// };

// export default LiveGameFeed;

