import { React, useContext, useState } from 'react';
import './Promo.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGift, faStar, faTrophy } from '@fortawesome/free-solid-svg-icons';
import { AuthContext } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import ErrorModal from '../components/ErrorModal/ErrorModal';
import SuccessModal from '../components/SuccessModal/SuccessModal';

const Promo = () => {
    const { username, promoActivatedButEnded, promotion } = useContext(AuthContext);
    const navigate = useNavigate();
    const [successMessage, setSuccessMessage] = useState(null); // State to store success messages
    const [errorMessage, setErrorMessage] = useState(null); // State for error messages

    const openSignInModel = () => navigate('/?signInModel=signin&tab=signin');

    const handleActivatePromotion = async () => {
        try {
            const response = await axios.post(
                'https://backend-service-643728542364.southamerica-east1.run.app/api/users/activatePromotion',
                {},
                { withCredentials: true }
            );

            if (response.status === 200) {
                // alert('Promotion Activated!');
                setSuccessMessage('Promotion Activated!');
                // setTimeout(() => setSuccessMessage(null), 5000);
            } else {
                // alert('Failed to activate promotion. Please try again.');
                setErrorMessage('Failed to activate promotion. Please try again.');
                // setTimeout(() => setErrorMessage(null), 5000);
            }
        } catch (error) {
            console.error('Error activating promotion:', error);
            // alert('Error activating promotion. Please contact support.');
            setErrorMessage('Error activating promotion. Please contact support.');
            // setTimeout(() => setErrorMessage(null), 5000);
        }
    };

    const handleCancelPromotion = async () => {
        try {
            const response = await axios.post(
                'https://backend-service-643728542364.southamerica-east1.run.app/api/users/cancelPromotion',
                {},
                { withCredentials: true }
            );

            if (response.status === 200) {
                // alert('Promotion Canceled!');
                setSuccessMessage('Promotion Canceled!');
                // setTimeout(() => setSuccessMessage(null), 5000);
            } else {
                // alert('Failed to cancel promotion. Please try again.');
                setErrorMessage('Failed to cancel promotion. Please try again.');
                // setTimeout(() => setErrorMessage(null), 5000);
            }
        } catch (error) {
            console.error('Error canceling promotion:', error);
            // alert('Error activating promotion. Please contact support.');
            setErrorMessage('Error activating promotion. Please contact support.');
            // setTimeout(() => setErrorMessage(null), 5000);
        }
    };

    return (
        <div className="main">
            <SuccessModal
                message={successMessage}
                onClose={() => setSuccessMessage(null)}
                duration={5000}
            />

            <ErrorModal
                message={errorMessage}
                onClose={() => setErrorMessage(null)}
                duration={5000}
            />

            <div className="promo-container">

                {/* TEMPORARY TEST BUTTON FOR SUCCESSMODAL */}
                {/* <button
                    className="test-success-button"
                    onClick={() => setSuccessMessage('This is a test success message!')}>
                    Show Test Success Modal
                </button>

                <button
                    className="test-success-button"
                    onClick={() => setErrorMessage('This is a test success message!')}>
                    Show Test Error Modal
                </button> */}


                <h1 className="promo-title">Promotions</h1>
                <p className="promo-description">
                    Sign up for a promotion that matches your play style, then make your first deposit. To complete the tier you're on and unlock the next, be sure to use all your freespins and complete any requirements for that tier. The next bonus will be available following your next deposit.
                </p>

                <div className="promo-main">
                    <div className="promo-left">
                        <div className="promo-box">
                            <h2 className="promo-box-title">40x Wager Locked</h2>
                            <div className="promo-steps">
                                <span className="promo-steps-numbers">1</span>
                                <div className="promo-step">
                                    <p className="promo-step-title">First Deposit</p>
                                    <p>100% Bonus<br />20 Freespins</p>
                                </div>
                                <span className="promo-steps-numbers">2</span>
                                <div className="promo-step">
                                    <p className="promo-step-title">Second Deposit</p>
                                    <p>50% Bonus<br />20 Freespins</p>
                                </div>
                                <span className="promo-steps-numbers">3</span>
                                <div className="promo-step">
                                    <p className="promo-step-title">Third Deposit</p>
                                    <p>100% Bonus<br />20 Freespins</p>
                                </div>
                            </div>
                            {username ? (
                                promoActivatedButEnded ? (
                                    <button className="promo-button">Promotion can't be activated</button>
                                ) : promotion ? (
                                    <button className="promo-button" onClick={handleCancelPromotion}>
                                        Cancel Promotion
                                    </button>
                                ) : (
                                    <button className="promo-button" onClick={handleActivatePromotion}>
                                        Activate Promotion
                                    </button>
                                )
                            ) : (
                                <button className="promo-button" onClick={openSignInModel}>
                                    Log In To Activate
                                </button>
                            )}
                            <p className="promo-box-footer">
                                Games with a Return to Player (RTP) rate over 97.4% are disabled when active. This includes games like Blackjack, some live games, certain slots, and all sports betting. Max bet is 1% of the amount you deposit to the promotion.
                            </p>
                        </div>
                    </div>

                    {/* Right Section */}
                    <div className="promo-right">
                        <div className="promo-info">
                            <div className="promo-info-header">
                                <FontAwesomeIcon icon={faGift} className="promo-icon" />
                                <h3>Freespins</h3>
                            </div>
                            <p>
                                You will be awarded with freespins for popular Pragmatic Play games that must be used to move on to the next tier.
                            </p>
                        </div>
                        <div className="promo-info">
                            <div className="promo-info-header">
                                <FontAwesomeIcon icon={faStar} className="promo-icon" />
                                <h3>Deposit Bonuses</h3>
                            </div>
                            <p>
                                Your deposit will receive a bonus of extra tokens on top, the use of which varies depending on the promotion you select.
                            </p>
                        </div>
                        <div className="promo-info">
                            <div className="promo-info-header">
                                <FontAwesomeIcon icon={faTrophy} className="promo-icon" />
                                <h3>Rewards</h3>
                            </div>
                            <p>
                                All rewards earned need to be claimed and used in order to move on to the next tier, as well as any wager locks met (including the wager lock issued on freespin rewards).
                            </p>
                        </div>
                    </div>
                </div>

                {/* Terms and Conditions Section */}
                <div className="promo-terms">
                    <h2 className="terms-title">Promotions Terms and Conditions</h2>

                    <div className="terms-section">
                        <h3>Wager Lock Promotion</h3>
                        <ul>
                            <li>Minimum deposit of $30 USD value in any deposit method.</li>
                            <li>Maximum deposit of $700 USD value in any deposit method.</li>
                            <li>The promotional balance can be used to wager on any game on Roulo.</li>
                            <li>Once you have opted in and made your deposit, the bonus is added automatically. In case your bonus is not added automatically, please contact live support before playing.</li>
                            <li>The First Deposit Bonus is only claimable along with a player's first deposit. A First Deposit Bonus that was not redeemed along with the first deposit cannot be claimed later.</li>
                            <li>The First Deposit Bonus has a wagering lock of 40x (forty). You must wager your promotional balance times 40 before it can be withdrawn.</li>
                            <li>The First Deposit Free Spins are claimed automatically at the time of deposit. Please contact live support in case your free spins are not added.</li>
                        </ul>
                    </div>

                    <div className="terms-section">
                        <h3>General Promotion Terms</h3>
                        <ul>
                            <li>Promotions can be enabled via the promotions page at https://roulobets.com/promotions.</li>
                            <li>If you are eligible for a bonus and it does not get added automatically, please contact Live Support before placing any bet.</li>
                            <li>When a deposit bonus is attached, all funds are locked under the wagering requirement.</li>
                            <li>If a bonus/free spins bonus is forfeited/canceled, any winnings will also be forfeited.</li>
                            <li>We may change the terms and conditions, the promotion itself, or limit eligibility at our sole discretion.</li>
                            <li>Freespins are only available if you're playing from a country supported by Pragmatic Play.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Promo;
