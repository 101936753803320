import React, { useState, useEffect, useContext, useRef } from 'react';
import './Mines.css'; // Ensure this file is properly linked for the styles
import axios from 'axios'; // For API requests
import { AuthContext } from '../context/AuthContext';
import ErrorModal from '../components/ErrorModal/ErrorModal';
import DiamondImg from './imgs/mines_diamond.png';
import MineImg from './imgs/mines_bomb2.png'
import selectSoundFile from '../pages/game_sounds/Mines/Mine_Selection.mp3';
import explosionSoundFile from '../pages/game_sounds/Mines/Mines_Bomb.mp3';
import GameDescCard from '../components/GameDescCard';
import gameImg from '../components/img/mines_final.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeUp, faVolumeMute } from '@fortawesome/free-solid-svg-icons';

function Mines() {
    const { balance, bonus, selectedBalanceType, betMax, calculateWinnings } = useContext(AuthContext); // Assuming `username` determines if the user is logged in
    const [grid, setGrid] = useState(Array(25).fill(null)); // Initialize grid with 25 null values
    const [flipped, setFlipped] = useState(Array(25).fill(false)); // State for flipped cards
    const [flippedTileCount, setFlippedTileCount] = useState(0);
    const [mines, setMines] = useState(1); // Number of mines
    const [minePositions, setMinePositions] = useState([]); // Positions of mines
    const [exploded, setExploded] = useState(false); // State for explosion
    const [gameId, setGameId] = useState(null); // Track the game session
    const [winnings, setWinnings] = useState(0); // Track winnings
    const [betAmount, setBetAmount] = useState(0); // Bet amount
    const [outcome, setOutcome] = useState('pending'); // Outcome of the game
    const [manualGameActive, setManualGameActive] = useState(false);
    const [currentMultiplier, setCurrentMultiplier] = useState(0);
    const [explodedIndex, setExplodedIndex] = useState(null); // Track the specific index that exploded
    const [selectedManualTiles, setSelectedManualTiles] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null); // State for error messages
    const [isPlaying, setIsPlaying] = useState(false); // Track button state
    const selectSound = new Audio(selectSoundFile);
    const explosionSound = new Audio(explosionSoundFile);
    const [isMuted, setIsMuted] = useState(false);
    const isMutedRef = useRef(isMuted);

    /* MANUAL OR AUTOPLAY CONSTS */
    const [mode, setMode] = useState('Manual'); // 'Manual' or 'Auto'
    const [numberOfGames, setNumberOfGames] = useState(0); // Tracks the number of autoplay games
    const [isAutoPlaying, setIsAutoPlaying] = useState(false);
    const isAutoPlayingRef = useRef(false);
    const [selectedAutoTiles, setSelectedAutoTiles] = useState([]);
    const [tempAutoTiles, setTempAutoTiles] = useState([]); // Temp store for auto mode selections
    const [isStoppingAutoPlay, setIsStoppingAutoPlay] = useState(false);
    // const [winAdjustment, setWinAdjustment] = useState('Reset'); // Tracks win adjustment strategy (e.g., Reset or Increase)
    // const [winAdjustmentValue, setWinAdjustmentValue] = useState(0); // Percentage increase on win
    // const [lossAdjustment, setLossAdjustment] = useState('Reset'); // Tracks loss adjustment strategy (e.g., Reset or Increase)
    // const [lossAdjustmentValue, setLossAdjustmentValue] = useState(0); // Percentage increase on loss

    selectSound.volume = 0.3;
    explosionSound.volume = 0.5

    // Initialize the grid and reset states when the component mounts
    useEffect(() => {
        if (gameId) {
            initializeGrid();
        }
    }, [mines]);

    const initializeGrid = () => {
        const newGrid = Array.from({ length: 25 }, (_, i) => i); // Create a grid with 25 cells
        setGrid(newGrid);
        setFlipped(Array(25).fill(false)); // Reset flipped state
        setExploded(false); // Reset explosion state
        setExplodedIndex(null); // Reset the exploded index
        setWinnings(0); // Reset winnings
        setOutcome('pending'); // Reset outcome
    };

    useEffect(() => {
        isMutedRef.current = isMuted;
    }, [isMuted]);

    const playSound = (sound) => {
        if (!isMutedRef.current) {
            sound.currentTime = 0; // Reset sound playback
            sound.play();
        }
    };

    // Function to start the game by calling the backend
    const startGame = async () => {
        if (isPlaying) return;
        setIsPlaying(true);
        document.querySelector('.button-grid').classList.remove('game-started');

        //         if (isPlaying && isAutoPlaying) {
        //             console.log(isAutoPlaying);
        //             return;
        //         }

        try {
            const response = await axios.post('https://backend-service-643728542364.southamerica-east1.run.app/api/mines/start/mines', {
                betAmount,
                minesCount: mines,
            }, { withCredentials: true });

            const { gameId: id, minePositions: positions } = response.data;

            setGameId(id);
            console.log("gameId in startGame: ", id);
            setBetAmount(betAmount);
            setMinePositions(positions); // Save mine positions from the backend
            setFlipped(Array(25).fill(false)); // Reset flipped grid
            setExploded(false);
            setExplodedIndex(null);
            setSelectedManualTiles([]); // Clear previous selections
            setWinnings(0);
            setOutcome('pending');
            document.querySelector('.button-grid').classList.add('game-started');
            // setTimeout(() => setIsPlaying(false), 4000); // Unlock after game ends
        } catch (error) {
            console.error('Error starting Mines game:', error);
            const errorMsg =
                error.response?.data?.error || 'An unexpected error occurred.';
            setErrorMessage(errorMsg);
        }
        // setIsPlaying(false);
    };

    // Function to flip a tile by calling the backend
    const flipTile = async (index) => {
        // Prevent flipping if the tile is already flipped or the game is over
        if (!isPlaying || flipped[index] || outcome !== 'pending' || explodedIndex !== null) {
            console.log("isPlaying: ", isPlaying);
            return;
        }

        console.log("Flipping tile at index:", index, "Game ID:", gameId);

        // Flip tile immediately for instant response
        setFlipped(prev => {
            const updatedFlipped = [...prev];
            updatedFlipped[index] = true;
            return updatedFlipped;
        });

        setSelectedManualTiles(prev => [...prev, index]);

        // if (selectedManualTiles.length + 1 === (25 - mines)) {
        //     cashOut();
        // }

        console.log("gameIdin flipTile: ", gameId)

        // Prevent selection sound if clicking a mine
        if (!minePositions.includes(index)) {
            selectSound.currentTime = 0; // Reset sound playback in case selections are too quick
            playSound(selectSound); // Play explosion sound if not muted
        }

        console.log("flipped: ", flipped);

        try {
            const response = await axios.post('https://backend-service-643728542364.southamerica-east1.run.app/api/mines/flip', {
                gameId,
                cellIndex: index,
            }, { withCredentials: true });

            const { flippedCells, winnings: newWinnings, outcome: newOutcome } = response.data;

            console.log("newWinnings in flipTile: ", newWinnings);

            // Update the frontend state based on the backend response
            const updatedFlipped = [...flipped];
            updatedFlipped[index] = true;  // Only flip the clicked tile
            setFlippedTileCount(1);
            setWinnings((newWinnings * betAmount).toFixed(2));
            setOutcome(newOutcome);

            if (newOutcome === 'lose') {
                setIsPlaying(false);
                setOutcome('lose');
                setExplodedIndex(index);  // Set the exploded index to the clicked mine tile
                setFlipped(Array(25).fill(true));
                setFlippedTileCount(0);
                playSound(explosionSound);
                return;
            }

            console.log("winnings in flipTile: ", winnings);
        } catch (error) {
            console.error('Error flipping tile:', error);
            const errorMsg =
                error.response?.data?.error || 'An unexpected error occurred.';
            setErrorMessage(errorMsg);
        }
    };

    // Function to flip a tile by calling the backend
    const flipAutoTiles = async (indeces, currentGameId) => {
        // Prevent flipping if the tile is already flipped or the game is over
        if (selectedAutoTiles.length > 0 || currentGameId) {
            console.log("selectedAutoTiles in flipAutoTiles: ", selectedAutoTiles);
            console.log("currentGameId: ", currentGameId);
            console.log("indices: ", indeces);
        }

        // If autoplay is active, flip ALL tiles instead of just one
        try {
            console.log("start of try catch block");

            const response = await axios.post('https://backend-service-643728542364.southamerica-east1.run.app/api/mines/flip/auto', {
                gameId: currentGameId,
                cellIndeces: indeces,
            }, { withCredentials: true });

            const { winnings: newWinnings, outcome: newOutcome } = response.data;

            console.log("newWinnings in flipAutoTiles: ", newWinnings);

            // setWinnings((newWinnings * betAmount).toFixed(2));
            console.log("winnings in flipAutoTiles: ", winnings);
            setWinnings((newWinnings * betAmount).toFixed(2));
            setOutcome(newOutcome);

            // Handle win/loss logic
            if (newOutcome === 'lose') {
                const hitMine = minePositions.find(cell => !flipped[cell]); // Find the mine hit
                setExplodedIndex(hitMine);
                setWinnings(0);
                setOutcome('lose');
                setTimeout(() => setFlipped(Array(25).fill(true)), 50); // Small delay to avoid visual glitches
                console.log("Mine hit! Round lost.");
            } else {
                // Ensure winnings are updated correctly on a win
                const response = await axios.post('https://backend-service-643728542364.southamerica-east1.run.app/api/mines/cashout', {
                    gameId: currentGameId,
                }, { withCredentials: true });
                const { winnings: newWinnings } = response.data;
                setWinnings(newWinnings);
                setOutcome("cashed_out");
                setTimeout(() => setFlipped(Array(25).fill(true)), 50); // Small delay to avoid visual glitches
                console.log(`Round won! Winnings: ${newWinnings}`);
            }

            if (newOutcome !== 'lose') {
                playSound(selectSound);
            }
        } catch (error) {
            console.error("Error flipping all tiles during autoplay:", error);
        }

        // Prevent selection sound if clicking a mine
        // if (!minePositions.includes(indeces)) {
        //     selectSound.currentTime = 0; // Reset sound playback in case selections are too quick
        //     playSound(selectSound); // Play explosion sound if not muted
        // }
        // console.log("flipped: ", flipped);
    };

    // Function to cash out by calling the backend
    const cashOut = async () => {
        setIsPlaying(false);
        setFlippedTileCount(0);
        try {
            const response = await axios.post('https://backend-service-643728542364.southamerica-east1.run.app/api/mines/cashout', {
                gameId,
            }, { withCredentials: true });

            const { winnings: finalWinnings } = response.data;
            setWinnings(finalWinnings);
            setOutcome('cashed_out');
            setFlipped(Array(25).fill(true)); // Flip all tiles when cashing out
        } catch (error) {
            console.error('Error cashing out:', error);
            const errorMsg =
                error.response?.data?.error || 'An unexpected error occurred.';
            setErrorMessage(errorMsg);
        }
    };

    const handleMineChange = (e) => {
        setMines(parseInt(e.target.value, 10)); // Set number of mines from dropdown
    };

    const handlePlayOrCashOut = () => {
        if (outcome === 'lose' || !isPlaying) {
            startGame();
            return; // Prevent cashout attempt after loss
        }

        if (gameId && outcome === 'pending' && flippedTileCount > 0) {
            console.log("Or is it here?");
            cashOut();
        } else {
            // setFlipped(Array(25).fill(false)); // Reset board before starting a new game
            console.log("is it here?");
            startGame();
            // setManualGameActive(true); // Mark manual game as active
        }
    };

    const handleAmountChange = (e) => {
        const value = parseFloat(e.target.value);
        if (!isNaN(value) && value >= 0) {
            setBetAmount(value);
        } else if (e.target.value === '') {
            setBetAmount(''); // Allow clearing the input
        }
    }

    const handleSelectAutoTile = (index) => {
        setFlipped(Array(25).fill(false)); // Reset flipped grid
        setSelectedAutoTiles((prevTiles) => {
            if (prevTiles.includes(index)) {
                return prevTiles.filter(tile => tile !== index); // Remove tile if already selected
            } else if (prevTiles.length < (25 - mines)) {
                return [...prevTiles, index]; // Add tile if limit isn't reached
            } else {
                return prevTiles; // Do not exceed limit
            }
        });
        // 🔹 Reset payout display
        // setWinnings(0);
        setOutcome("pending");
    };

    const handleAutoPlay = async () => {
        if (isAutoPlaying) {
            setIsAutoPlaying(false);
            isAutoPlayingRef.current = false;
            return;
        }

        if (isAutoPlayingRef.current) {
            handleStopAutoPlay(); // Stop autoplay properly
            return;
        }

        if (selectedAutoTiles.length === 0) {
            setErrorMessage("Please select at least one tile before starting autoplay.");
            return;
        }

        setIsAutoPlaying(true);
        isAutoPlayingRef.current = true;
        setIsStoppingAutoPlay(false); // Reset stop state when autoplay starts
        console.log("selectedAutoTiles in autoPlay: ", selectedAutoTiles);

        for (let i = 0; i < numberOfGames; i++) {
            if (!isAutoPlayingRef.current) break;

            try {
                // Reset the board before each round
                setFlipped(Array(25).fill(false)); // Hide previous tiles
                setExplodedIndex(null);
                setOutcome('pending');
                setWinnings(0);

                // await new Promise(resolve => setTimeout(resolve, 80)); // Delay before flipping

                // 🔹 Start a new game for each autoplay round
                const response = await axios.post('https://backend-service-643728542364.southamerica-east1.run.app/api/mines/start/mines', {
                    betAmount,
                    minesCount: mines,
                }, { withCredentials: true });

                const { gameId: newGameId, minePositions: positions } = response.data;

                console.log(`Autoplay Round ${i + 1} - New Game ID: `, newGameId);

                setMinePositions(positions);

                // Now call flipTile with the updated gameId
                await flipAutoTiles(selectedAutoTiles, newGameId);
                setFlipped(Array(25).fill(true));

                await new Promise(resolve => setTimeout(resolve, 200)); // Delay before next round

            } catch (error) {
                console.error(`Error in autoplay round ${i + 1}:`, error);
                break;
            }
        }

        setIsAutoPlaying(false);
        isAutoPlayingRef.current = false;
        setFlipped(Array(25).fill(false)); // Reset board
        setExplodedIndex(null);
        setWinnings(0);
        setCurrentMultiplier(null);
    };

    const handleStopAutoPlay = () => {
        if (!isAutoPlaying) return; // Prevent stopping if autoplay isn't active

        setIsStoppingAutoPlay(true); // Disable "Start Autoplay" button while stopping
        setIsAutoPlaying(false); // Stop autoplay
    };

    useEffect(() => {
        if (mode === 'Manual') {
            setTempAutoTiles(selectedAutoTiles);
            setSelectedAutoTiles([]); // Clear selections in Manual mode
        } else if (mode === 'Auto') {
            setSelectedAutoTiles(tempAutoTiles); // Restore selections in Auto mode
        }
    }, [mode]);

    useEffect(() => {
        if (outcome === 'cashed_out') {
            setFlipped(Array(25).fill(true)); // Instantly show all tiles
        }
    }, [outcome]);

    useEffect(() => {
        setGameId(null);
        setFlipped(Array(25).fill(false));
    }, [mines]);

    useEffect(() => {
        if (outcome === 'pending' && betAmount > 0) {
            const calculatedMultiplier = winnings > 0 ? (winnings / betAmount).toFixed(2) : '0.00';
            console.log("calculateMultiplier: ", calculatedMultiplier);
            console.log("winnings: ", winnings);
            console.log("betAmount: ", betAmount);
            setCurrentMultiplier(calculatedMultiplier);

            // setNextWinnings((winnings + (betAmount * 0.5)).toFixed(2));
        } else if (outcome === 'lose') {
            setCurrentMultiplier(0);
            setWinnings(0);
            return;
        }
    }, [winnings, betAmount, outcome]);

    useEffect(() => {
        // setManualGameActive(false); // Reset manual mode when switching between Auto/Manual
        setFlipped(Array(25).fill(false)); // Reset tiles
        setSelectedAutoTiles([]); // Clear auto-selected tiles
        setOutcome("pending");
        setWinnings(0);
        setExplodedIndex(null); // 🔹 Reset the exploded tile
    }, [mode]); // Runs when `mode` changes (Manual <-> Auto)

    const betMaxAmount = () => {
        if (selectedBalanceType === "primary") {
            if (balance > 500) {
                setBetAmount(500);
            } else {
                setBetAmount(balance);
            }
        } else if (selectedBalanceType === "promotional") {
            setBetAmount(betMax);
        }
    };

    return (
        <div className="main" id="mines-main">
            <ErrorModal
                message={errorMessage}
                onClose={() => setErrorMessage(null)}
                duration={5000}
            />

            <div className="game-container-body">
                <div className="game-container" style={{ marginTop: '100px' }}>
                    <div className="side-panel">
                        <div className="mode-tabs">
                            <button
                                className={`mode-tab ${mode === 'Manual' ? 'active' : ''}`}
                                onClick={() => setMode('Manual')}
                                disabled={isPlaying || isAutoPlaying}
                            >
                                Manual
                            </button>
                            <button
                                className={`mode-tab ${mode === 'Auto' ? 'active' : ''}`}
                                onClick={() => setMode('Auto')}
                                disabled={isPlaying || isAutoPlaying}
                            >
                                Auto
                            </button>
                        </div>

                        {mode === 'Manual' && (
                            <div className="manual-controls">
                                <div className="side-inputs" style={{ marginBottom: '5px' }}>
                                    <label>Amount:</label>
                                    <div className="amount-container">
                                        <span className="amount-icon">$</span>
                                        <input
                                            type="number"
                                            value={betAmount}
                                            onFocus={(e) => e.target.select()}  // Select all text when clicking
                                            onChange={handleAmountChange}
                                            onBlur={() => {
                                                setBetAmount((prev) => (prev === '' ? '0.00' : parseFloat(prev).toFixed(2)));
                                            }}
                                            disabled={isPlaying}
                                            className="amount-input"
                                            step="0.01"
                                            min="0" // This prevents decrementing below 0 using the spinner buttons
                                        />
                                        <div className="amount-buttons">
                                            <button
                                                className="amount-button"
                                                // onClick={() => setAmount((prev) => (prev / 2).toFixed(2))}
                                                onClick={() => setBetAmount((prev) => (prev / 2))}
                                                disabled={isPlaying}
                                            >
                                                ½
                                            </button>
                                            <button
                                                className="amount-button"
                                                onClick={() => setBetAmount((prev) => (prev * 2))}
                                                disabled={isPlaying}
                                            >
                                                2×
                                            </button>
                                            <button
                                                className="amount-button"
                                                onClick={betMaxAmount}
                                                disabled={isPlaying}
                                            >
                                                MAX
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="side-inputs">
                                    <label>Mines:</label>
                                    <select value={mines} onChange={handleMineChange} disabled={isPlaying}>
                                        {[...Array(24).keys()].map((num) => (
                                            <option key={num} value={num + 1}>{num + 1}</option>
                                        ))}
                                    </select>
                                </div>

                                <button
                                    id="play-button"
                                    className={`btn-${gameId && outcome === 'pending' ? 'cashout' : 'play'}`}
                                    onClick={handlePlayOrCashOut}
                                    disabled={betAmount === 0}
                                    style={{ marginTop: '10px' }}
                                >
                                    {outcome === 'pending' && isPlaying ? 'Cash Out' : 'Play'}
                                </button>

                            </div>
                        )}

                        {mode === 'Auto' && (
                            <div className="auto-controls">
                                <div className="side-inputs">
                                    <label>Amount:</label>
                                    <div className="amount-container">
                                        <span className="amount-icon">$</span>
                                        <input
                                            type="number"
                                            value={betAmount}
                                            onFocus={(e) => e.target.select()}  // Select all text when clicking
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                if (value === '') {
                                                    setBetAmount('');  // Allow empty input for user convenience
                                                } else if (/^\d*\.?\d*$/.test(value)) {
                                                    setBetAmount(value);  // Allow valid decimal numbers
                                                }
                                            }}
                                            onBlur={() => {
                                                setBetAmount((prev) => (prev === '' ? '0.00' : parseFloat(prev).toFixed(2)));
                                            }}
                                            className="amount-input"
                                            disabled={isAutoPlaying}
                                            step="0.01"
                                            min="0" // This prevents decrementing below 0 using the spinner buttons
                                        />
                                        <div className="amount-buttons">
                                            <button
                                                className="amount-button"
                                                onClick={() => setBetAmount((prev) => (prev / 2))}
                                                disabled={isAutoPlaying}
                                            >
                                                ½
                                            </button>
                                            <button
                                                className="amount-button"
                                                onClick={() => setBetAmount((prev) => (prev * 2))}
                                                disabled={isAutoPlaying}
                                            >
                                                2×
                                            </button>
                                            <button
                                                className="amount-button"
                                                onClick={betMaxAmount}
                                                disabled={isAutoPlaying}
                                            >
                                                MAX
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="side-inputs">
                                    <label>Mines:</label>
                                    <select value={mines} onChange={handleMineChange} disabled={isAutoPlaying}>
                                        {[...Array(24).keys()].map((num) => (
                                            <option key={num} value={num + 1}>{num + 1}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="side-inputs">
                                    <label>Number of Games:</label>
                                    <input
                                        type="number"
                                        value={numberOfGames}
                                        onFocus={(e) => e.target.select()}  // Select all text when clicking
                                        onChange={(e) => setNumberOfGames(parseInt(e.target.value, 10))}
                                        disabled={isAutoPlaying}
                                        min="1"
                                    />
                                </div>
                                <button
                                    id="play-button"
                                    className="clear-picks-btn"
                                    onClick={() => setSelectedAutoTiles([])}
                                    disabled={isAutoPlaying}
                                >
                                    Clear Picks
                                </button>
                                <button id="play-button" onClick={handleAutoPlay} disabled={betAmount === 0 || numberOfGames === 0 || isStoppingAutoPlay}>
                                    {isAutoPlaying ? 'Stop Autoplay' : 'Start Autoplay'}
                                </button>
                            </div>
                        )}

                        <div className="side-panel winnings">
                            Payout: <span className="winnings-amount">${winnings}</span>
                            (<span className="multiplier">{currentMultiplier}x</span>)
                        </div>

                        <div className="side-panel" style={{ position: 'relative' }}>
                            <button
                                className="mute-button"
                                onClick={() => setIsMuted((prev) => !prev)}
                            >
                                <FontAwesomeIcon icon={isMuted ? faVolumeMute : faVolumeUp} />
                            </button>
                        </div>

                    </div>

                    <div className="game-panel mines">

                        {outcome === 'cashed_out' && currentMultiplier !== null && (
                            <div className="center-display mines-win-fadein">
                                <div className="multiplier">{currentMultiplier}x</div>
                                <div className="divider"></div> {/* Small line separating multiplier and winnings */}
                                <div className="mines-winnings">${parseFloat(winnings).toFixed(2)}</div>
                            </div>
                        )}
                        <div className={`button-grid ${isPlaying ? 'game-started' : ''} ${outcome !== 'pending' ? 'game-over' : ''}`}>
                            {grid.map((_, index) => (
                                <div
                                    className={`grid-button ${flipped[index] ? 'flipped' : ''} 
                                        ${selectedManualTiles.includes(index) ? 'selected' : ''} 
                                        ${index === explodedIndex ? 'exploded' : ''} 
                                        ${selectedAutoTiles.includes(index) ? 'selected-auto' : ''}`} // ✅ Only highlight selected tiles in Auto mode                                    key={index}
                                    onClick={() => (mode === "Auto" ? handleSelectAutoTile(index) : flipTile(index))}
                                >
                                    <div className="front"></div>
                                    <div className="back">
                                        {flipped[index] && minePositions.includes(index) ? (
                                            <img src={MineImg} alt="Mine" className="tile-image mine" />
                                        ) : flipped[index] ? (
                                            <img src={DiamondImg} alt="Diamond" className="tile-image diamond" />
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            {/* EXAMPLE OF PASSING IN GAME DETAILS */}
            <GameDescCard
                gameTitle="Mines"
                totalBets={6896668}
                gameDescription="Mines is a thrilling game of chance where players strategically avoid hidden mines."
                bigWins={[
                    { user: 'Player1', bet: 2000, multiplier: 292.94, payout: 585880 },
                    { user: 'Player2', bet: 989.59, multiplier: 292.94, payout: 289890.49 },
                    { user: 'Player3', bet: 1500, multiplier: 150.5, payout: 225750 },
                ]}
                luckyWins={[
                    { user: 'Player3', bet: 1500, multiplier: 150.5, payout: 225750 },
                    { user: 'Player2', bet: 989.59, multiplier: 292.94, payout: 289890.49 },
                    { user: 'Player1', bet: 2000, multiplier: 292.94, payout: 585880 },
                ]}
                gameImageUrl={gameImg}
            />
        </div >
    );
}

export default Mines;