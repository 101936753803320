import React, { useRef, useEffect, useState, useContext } from 'react';
import './Game.css';
import './Plinko.css';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';
import PlinkoImg from '../components/img/plinko.png';
import RouloBall from '../components/img/mini_logo.png';
// import GameFooter from '../components/GameFooter';
import ErrorModal from '../components/ErrorModal/ErrorModal';
import GameDescCard from '../components/GameDescCard';
import gameImg from '../components/img/plinko_final.png'
import dropSoundFile from '../pages/game_sounds/Plinko/plinko_ball.mp3';
import hitSoundFile from '../pages/game_sounds/Cashout_Win/Win_Cashout.mp3';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeUp, faVolumeMute } from '@fortawesome/free-solid-svg-icons';

function Game() {
  const { balance, bonus, selectedBalanceType, betMax } = useContext(AuthContext); // Assuming `username` determines if the user is logged in
  const canvasRef = useRef(null);
  const [rows, setRows] = useState(15);
  const [risk, setRisk] = useState('Low');
  const [hitMultiplier, setHitMultiplier] = useState(null);
  const [betAmount, setBetAmount] = useState(0); // Store the bet amount
  const [gameId, setGameId] = useState(null); // To track game session
  const [multiplierSent, setMultiplierSent] = useState(false);
  const [multipliers, setMultipliers] = useState([]);
  const [hitIndex, setHitIndex] = useState(20);
  const [ballsInPlay, setBallsInPlay] = useState(0); // Tracks active balls
  const [errorMessage, setErrorMessage] = useState(null); // State for error messages
  const [isSimulating, setIsSimulating] = useState(false);
  const [pegSize, setPegSize] = useState(4);
  const [ballSize, setBallSize] = useState(7);
  const [boxOffset, setBoxOffset] = useState(50);

  // const { cryptoRandomNumber } = require('../../backend/random/rng');
  const dropSound = new Audio(dropSoundFile);
  const hitSound = new Audio(hitSoundFile);
  const [isMuted, setIsMuted] = useState(false);
  const isMutedRef = useRef(isMuted);
  const [animationKey, setAnimationKey] = useState(0); // Forces re-render on animation trigger
  const [hitBoxes, setHitBoxes] = useState([]);
  const [ballCount, setBallCount] = useState(0);
  const isPlaying = ballCount > 0; // Game is "in progress" if any ball is active

  /* AUTOPLAY CONSTS */
  const [mode, setMode] = useState('Manual'); // 'Manual' or 'Auto'
  const [isAutoPlaying, setIsAutoPlaying] = useState(false);
  const [numberOfGames, setNumberOfGames] = useState(0); // Tracks the number of autoplay games
  const isAutoPlayingRef = useRef(false);
  // const [winAdjustment, setWinAdjustment] = useState('Reset'); // Tracks win adjustment strategy (e.g., Reset or Increase)
  // const [winAdjustmentValue, setWinAdjustmentValue] = useState(0); // Percentage increase on win
  // const [lossAdjustment, setLossAdjustment] = useState('Reset'); // Tracks loss adjustment strategy (e.g., Reset or Increase)
  // const [lossAdjustmentValue, setLossAdjustmentValue] = useState(0); // Percentage increase on loss

  // dropSound.volume = 0.5;
  // hitSound.volume = 0.7;

  const balls = useRef([]);
  const addBallsRef = useRef();

  useEffect(() => {
    isMutedRef.current = isMuted;
  }, [isMuted]);

  const playSound = (sound) => {
    if (!isMutedRef.current) {
      sound.currentTime = 0; // Reset sound playback
      sound.play();
    }
  };

  // Binomial Coefficient
  const binomialCoefficient = (n, k) => {
    if (k === 0 || k === n) return 1;
    let res = 1;
    for (let i = 1; i <= k; i++) {
      res *= (n - i + 1) / i;
    }
    return res;
  };

  // Calculate Probabilities
  const getProbabilities = () => {
    return Array.from({ length: rows + 1 }, (_, k) => {
      const probability = binomialCoefficient(rows, k) * Math.pow(0.5, rows);
      return (probability * 100).toFixed(4); // Convert to percentage
    });
  };

  const probabilities = getProbabilities();

  const getMultipliers = () => {
    let baseMultipliers = [];
    // Cases for Low risk
    if (risk === 'Low' && rows === 8) {
      baseMultipliers = [5.6, 2.1, 1.1, 1, 0.5, 1, 1.1, 2.1, 5.6];
    }
    else if (risk === 'Low' && rows === 9) {
      baseMultipliers = [5.6, 2, 1.6, 1, 0.7, 0.7, 1, 1.6, 2, 5.6];
    }
    else if (risk === 'Low' && rows === 10) {
      baseMultipliers = [8.9, 3, 1.4, 1.1, 1, 0.5, 1, 1.1, 1.4, 3, 8.9];
    }
    else if (risk === 'Low' && rows === 11) {
      baseMultipliers = [8.4, 3, 1.9, 1.3, 1, 0.7, 0.7, 1, 1.3, 1.9, 3, 8.4];
    }
    else if (risk === 'Low' && rows === 12) {
      baseMultipliers = [10, 3, 1.6, 1.4, 1.1, 1, 0.5, 1, 1.1, 1.4, 1.6, 3, 10];
    }
    else if (risk === 'Low' && rows === 13) {
      baseMultipliers = [8.1, 4, 3, 1.9, 1.2, 0.9, 0.7, 0.7, 0.9, 1.2, 1.9, 3, 4, 8.1];
    }
    else if (risk === 'Low' && rows === 14) {
      baseMultipliers = [7.1, 4, 1.9, 1.4, 1.3, 1.1, 1, 0.5, 1, 1.1, 1.3, 1.4, 1.9, 4, 7.1];
    }
    else if (risk === 'Low' && rows === 15) {
      baseMultipliers = [15, 8, 3, 2, 1.5, 1.1, 1, 0.7, 0.7, 1, 1.1, 1.5, 2, 3, 8, 15];
    }
    else if (risk === 'Low' && rows === 16) {
      baseMultipliers = [16, 9, 2, 1.4, 1.4, 1.2, 1.1, 1, 0.5, 1, 1.1, 1.2, 1.4, 1.4, 2, 9, 16];
    }

    // Cases for Medium risk
    if (risk === 'Medium' && rows === 8) {
      baseMultipliers = [13, 3, 1.3, 0.7, 0.4, 0.7, 1.3, 3, 13];
    }
    else if (risk === 'Medium' && rows === 9) {
      baseMultipliers = [18, 4, 1.7, 0.9, 0.5, 0.5, 0.9, 1.7, 4, 18];
    }
    else if (risk === 'Medium' && rows === 10) {
      baseMultipliers = [22, 5, 2, 1.4, 0.6, 0.4, 0.6, 1.4, 2, 5, 22];
    }
    else if (risk === 'Medium' && rows === 11) {
      baseMultipliers = [24, 6, 3, 1.8, 0.7, 0.5, 0.5, 0.7, 1.8, 3, 6, 24];
    }
    else if (risk === 'Medium' && rows === 12) {
      baseMultipliers = [33, 11, 4, 2, 1.1, 0.6, 0.3, 0.6, 1.1, 2, 4, 11, 33];
    }
    else if (risk === 'Medium' && rows === 13) {
      baseMultipliers = [43, 13, 6, 3, 1.3, 0.7, 0.4, 0.4, 0.7, 1.3, 3, 6, 13, 43];
    }
    else if (risk === 'Medium' && rows === 14) {
      baseMultipliers = [58, 15, 7, 4, 1.9, 1, 0.5, 0.2, 0.5, 1, 1.9, 4, 7, 15, 58];
    }
    else if (risk === 'Medium' && rows === 15) {
      baseMultipliers = [88, 18, 11, 5, 3, 1.3, 0.5, 0.3, 0.3, 0.5, 1.3, 3, 5, 11, 18, 88];
    }
    else if (risk === 'Medium' && rows === 16) {
      baseMultipliers = [110, 41, 10, 5, 3, 1.5, 1, 0.5, 0.3, 0.5, 1, 1.5, 3, 5, 10, 41, 110];
    }

    // Cases for High risk
    if (risk === 'High' && rows === 8) {
      baseMultipliers = [29, 4, 1.5, 0.3, 0.2, 0.3, 1.5, 4, 29];
    }
    else if (risk === 'High' && rows === 9) {
      baseMultipliers = [43, 7, 2, 0.6, 0.2, 0.2, 0.6, 2, 7, 43];
    }
    else if (risk === 'High' && rows === 10) {
      baseMultipliers = [76, 10, 3, 0.9, 0.3, 0.2, 0.3, 0.9, 3, 10, 76];
    }
    else if (risk === 'High' && rows === 11) {
      baseMultipliers = [120, 14, 5.2, 1.4, 0.4, 0.2, 0.2, 0.4, 1.4, 5.2, 14, 120];
    }
    else if (risk === 'High' && rows === 12) {
      baseMultipliers = [170, 24, 8.1, 2, 0.7, 0.2, 0.2, 0.2, 0.7, 2, 8.1, 24, 170];
    }
    else if (risk === 'High' && rows === 13) {
      baseMultipliers = [260, 37, 11, 4, 1, 0.2, 0.2, 0.2, 0.2, 1, 4, 11, 37, 260];
    }
    else if (risk === 'High' && rows === 14) {
      baseMultipliers = [420, 56, 18, 5, 1.9, 0.3, 0.2, 0.2, 0.2, 0.3, 1.9, 5, 18, 56, 420];
    }
    else if (risk === 'High' && rows === 15) {
      baseMultipliers = [620, 83, 27, 8, 3, 0.5, 0.2, 0.2, 0.2, 0.2, 0.5, 3, 8, 27, 83, 620];
    }
    else if (risk === 'High' && rows === 16) {
      baseMultipliers = [1000, 130, 26, 9, 4, 2, 0.2, 0.2, 0.2, 0.2, 0.2, 2, 4, 9, 26, 130, 1000];
    }

    // Adjust the number of multiplier boxes based on the number of rows
    const adjustedMultipliers = [];
    for (let i = 0; i <= rows; i++) {
      adjustedMultipliers.push(baseMultipliers[i % baseMultipliers.length]);
    }
    console.log("Adjusted Multipliers:", adjustedMultipliers);
    console.log("Base Multipliers:", baseMultipliers);

    return adjustedMultipliers;
  };

  const getBoxColor = (index, totalBoxes) => {
    if (totalBoxes === 9) {
      if (index === 0 || index === totalBoxes - 1) return '#FF003F';
      if (index === 1 || index === totalBoxes - 2) return '#FF302F';
      if (index === 2 || index === totalBoxes - 3) return '#FF6020';
      if (index === 3 || index === totalBoxes - 4) return '#FF9010';
      return '#FFC000';
    }
    else if (totalBoxes === 10) {
      if (index === 0 || index === totalBoxes - 1) return '#FF003F';
      if (index === 1 || index === totalBoxes - 2) return '#FF2B31';
      if (index === 2 || index === totalBoxes - 3) return '#FF5523';
      if (index === 3 || index === totalBoxes - 4) return '#FF8015';
      if (index === 4 || index === totalBoxes - 5) return '#FFAB07';
    }
    else if (totalBoxes === 11) {
      if (index === 0 || index === totalBoxes - 1) return '#FF003F';
      if (index === 1 || index === totalBoxes - 2) return '#FF2632';
      if (index === 2 || index === totalBoxes - 3) return '#FF4D26';
      if (index === 3 || index === totalBoxes - 4) return '#FF7319';
      if (index === 4 || index === totalBoxes - 5) return '#FF9A0D';
      return '#FFC000';
    }
    else if (totalBoxes === 12) {
      if (index === 0 || index === totalBoxes - 1) return '#FF003F';
      if (index === 1 || index === totalBoxes - 2) return '#FF2334';
      if (index === 2 || index === totalBoxes - 3) return '#FF4628';
      if (index === 3 || index === totalBoxes - 4) return '#FF691D';
      if (index === 4 || index === totalBoxes - 5) return '#FF8C11';
      if (index === 5 || index === totalBoxes - 6) return '#FFAF06';
    }
    else if (totalBoxes === 13) {
      if (index === 0 || index === totalBoxes - 1) return '#FF003F';
      if (index === 1 || index === totalBoxes - 2) return '#FF2035';
      if (index === 2 || index === totalBoxes - 3) return '#FF402A';
      if (index === 3 || index === totalBoxes - 4) return '#FF6020';
      if (index === 4 || index === totalBoxes - 5) return '#FF8015';
      if (index === 5 || index === totalBoxes - 6) return '#FFA00B';
      return '#FFC000';
    }
    else if (totalBoxes === 14) {
      if (index === 0 || index === totalBoxes - 1) return '#FF003F';
      if (index === 1 || index === totalBoxes - 2) return '#FF1E35';
      if (index === 2 || index === totalBoxes - 3) return '#FF3B2C';
      if (index === 3 || index === totalBoxes - 4) return '#FF5922';
      if (index === 4 || index === totalBoxes - 5) return '#FF7618';
      if (index === 5 || index === totalBoxes - 6) return '#FF940F';
      if (index === 6 || index === totalBoxes - 7) return '#FFB105';
    }
    else if (totalBoxes === 15) {
      if (index === 0 || index === totalBoxes - 1) return '#FF003F';
      if (index === 1 || index === totalBoxes - 2) return '#FF1B36';
      if (index === 2 || index === totalBoxes - 3) return '#FF372D';
      if (index === 3 || index === totalBoxes - 4) return '#FF5224';
      if (index === 4 || index === totalBoxes - 5) return '#FF6E1B';
      if (index === 5 || index === totalBoxes - 6) return '#FF8912';
      if (index === 6 || index === totalBoxes - 7) return '#FFA509';
      return '#FFC000';
    }
    else if (totalBoxes === 16) {
      if (index === 0 || index === totalBoxes - 1) return '#FF003F';
      if (index === 1 || index === totalBoxes - 2) return '#FF1A37';
      if (index === 2 || index === totalBoxes - 3) return '#FF332E';
      if (index === 3 || index === totalBoxes - 4) return '#FF4D26';
      if (index === 4 || index === totalBoxes - 5) return '#FF661D';
      if (index === 5 || index === totalBoxes - 6) return '#FF8015';
      if (index === 6 || index === totalBoxes - 7) return '#FF9A0D';
      if (index === 7 || index === totalBoxes - 8) return '#FFB304';
    }
    else if (totalBoxes === 17) {
      if (index === 0 || index === totalBoxes - 1) return '#FF003F';
      if (index === 1 || index === totalBoxes - 2) return '#FF1837';
      if (index === 2 || index === totalBoxes - 3) return '#FF302F';
      if (index === 3 || index === totalBoxes - 4) return '#FF4827';
      if (index === 4 || index === totalBoxes - 5) return '#FF6020';
      if (index === 5 || index === totalBoxes - 6) return '#FF7818';
      if (index === 6 || index === totalBoxes - 7) return '#FF9010';
      if (index === 7 || index === totalBoxes - 8) return '#FFA808';
      return '#FFC000';
    }
  };

  const handleChangeRows = (e) => {
    setRows(parseInt(e.target.value));
  };

  const handleChangeRisk = (e) => {
    setRisk(e.target.value);
  };

  useEffect(() => {
    // Dynamically adjust ball size, peg size, and box offset based on rows
    const newSize = Math.max(3, Math.min(7, 16 / rows * 4));
    setPegSize(newSize);
    setBallSize(newSize * 2); // Scale ball relative to peg size
    setBoxOffset(newSize * 15); // Adjust box offset to maintain spacing
  }, [rows]);

  // UseEffect to initialize the canvas
  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const playButton = document.getElementById('play-button');
    const DECIMAL_MULTIPLIER = 10000;
    const WIDTH = canvas.parentElement.clientWidth;
    const HEIGHT = 600;

    // Dynamically adjust ball and peg sizes based on the number of rows
    const maxBallRadius = 8; // Define max size
    const ballRadius = Math.max(maxBallRadius, Math.max(6, 22 - rows)); // Larger for fewer rows, smaller for more rows
    const obstacleRadius = Math.max(3, 8 - Math.floor(rows / 6)); // Pegs also shrink

    const gravity = 300;
    const horizontalFric = 0.6;
    const verticalFric = 0.4;
    let obstacles = [];

    canvas.width = WIDTH;
    canvas.height = HEIGHT;

    function increase(n) {
      return n * DECIMAL_MULTIPLIER;
    }
    // const handlePlayButtonClick = () => {
    //   console.log("Play button clicked");
    //   addBall();  // Your function to add a new ball
    // };

    function unincrease(n) {
      return Math.floor(n / DECIMAL_MULTIPLIER);
    }

    // Initialize obstacles
    obstacles = [];
    const spacing = WIDTH / (rows + 3);

    for (let row = 1; row <= rows; row++) {
      const numObstacles = row === 1 ? 3 : row + 2;
      const y = row * (HEIGHT / (rows + 1));
      const offsetX = (WIDTH - (numObstacles - 1) * spacing) / 2;
      for (let col = 0; col < numObstacles; col++) {
        const x = offsetX + col * spacing;
        obstacles.push({ x: increase(x), y: increase(y), radius: obstacleRadius });
      }
    }

    class Ball {
      constructor(x, y, radius, color, ctx, obstacles) {
        this.x = x;
        this.y = y;
        this.radius = radius;
        this.color = color;
        this.vx = 0;
        this.vy = 0;
        this.ctx = ctx;
        this.obstacles = obstacles;
        this.landed = false;
      }

      draw() {
        ctx.beginPath();
        ctx.arc(unincrease(this.x), unincrease(this.y), this.radius, 0, Math.PI * 2);
        ctx.fillStyle = this.color;
        ctx.fill();
        ctx.closePath();
      }

      update() {
        if (!this.landed) {
          const maxFallSpeed = 4500; // Adjust for desired fall speed consistency

          // Limit velocity to ensure balls fall at the same speed
          if (this.vy != maxFallSpeed) {
            this.vy = maxFallSpeed;
          }

          this.vy += gravity;
          this.x += this.vx;
          this.y += this.vy;

          const landingZoneY = HEIGHT - 80;  // ~80px above the multiplier boxes

          if (this.y < landingZoneY) {
            this.vx *= 0.1; // Reduce lateral movement
            this.vy = Math.min(this.vy, 3); // Prevent the ball from flying over boxes
          }

          // Collision detection logic
          obstacles.forEach(obstacle => {
            const dist = Math.hypot(this.x - obstacle.x, this.y - obstacle.y);

            if (dist < increase(this.radius + obstacle.radius)) {
              // if (dist < collisionDistance) {
              const angle = Math.atan2(this.y - obstacle.y, this.x - obstacle.x);
              const speed = Math.sqrt(this.vx * this.vx + this.vy * this.vy);
              this.vx = Math.cos(angle) * speed * horizontalFric;
              this.vy = Math.sin(angle) * speed * verticalFric;


              const overlap = this.radius + obstacle.radius - unincrease(dist);
              this.x += increase(Math.cos(angle) * overlap);
              this.y += increase(Math.sin(angle) * overlap);

              // Highlight pegs
              obstacle.highlighted = true;
              setTimeout(() => (obstacle.highlighted = false), 300);
            }
          });

          // Detect when the ball has landed
          if (unincrease(this.y) + ballRadius > HEIGHT - 10 && !this.landed) {
            this.landed = true;
            // Get the final x position of the ball
            const finalX = unincrease(this.x);
            checkMultiplierHit(finalX, this.startX);
            setBallCount(prevCount => Math.max(0, prevCount - 1)); // Decrease count, ensure it doesn’t go negative

            balls.current = balls.current.filter(b => b !== this); // Remove ball after processing
          }
        }
      }
    }

    function checkMultiplierHit(ballX) {
      const multiplierDivs = document.querySelectorAll('.odds-box');
      const canvasRect = canvasRef.current.getBoundingClientRect();
      let hit = null;
      let hitIndex = null;
      let minDistance = Infinity;

      multiplierDivs.forEach((div, index) => {
        const rect = div.getBoundingClientRect();
        const divLeft = rect.left - canvasRect.left;
        const divRight = rect.right - canvasRect.left;

        // Calculate center position of the multiplier box
        const boxCenterTop = (divLeft + divRight) / 2;
        const distance = Math.abs(ballX - boxCenterTop);

        if (ballX >= divLeft && ballX <= divRight && distance < minDistance) {
          hit = multipliers[index];
          console.log('hit: ', hit);
          console.log('index of multipliers: ', index);
          hitIndex = index;
          hitSound.currentTime = 0; // Reset sound if needed
          playSound(hitSound);
        }
      });

      if (hit !== null && hitIndex !== null) {
        console.log('Ball hit multiplier:', hit);
        console.log('Hit Index:', hitIndex);

        // Force re-trigger of animation by incrementing the key
        setAnimationKey(prevKey => prevKey + 1);
        setHitIndex(hitIndex);
        setHitMultiplier(hit);  // ✅ Update the displayed multiplier

        // Forcefully restart animation by toggling the class
        const hitBox = multiplierDivs[hitIndex];
        if (hitBox) {
          hitBox.classList.remove('hit');
          setTimeout(() => hitBox.classList.add('hit'), 1); // Short delay ensures animation restarts
        }

        if (!multiplierSent) {
          updateMultiplier();
          setMultiplierSent(true);
        }
      }
    }

    // Add ball to the game
    const addBall = (startX) => {
      console.log('add ball function x: ', startX);
      const newBall = new Ball(startX, increase(10), ballRadius, '#2EFDD9', ctx, obstacles);
      console.log("Before adding: ", newBall);

      // newBall.startX = startX;

      setBallCount(prevCount => prevCount + 1); // Increment ball count
      balls.current = [...balls.current, newBall];
      // balls.current.push(newBall);
      console.log("After adding: ", balls.current);

      dropSound.currentTime = 0;
      playSound(dropSound);
    };

    addBallsRef.current = addBall;
    // console.log("balls array: ", balls);

    // Function to draw obstacles
    const drawObstacles = () => {
      ctx.fillStyle = 'white';
      obstacles.forEach(obstacle => {
        ctx.beginPath();
        ctx.arc(unincrease(obstacle.x), unincrease(obstacle.y), obstacle.radius, 0, Math.PI * 2);
        ctx.fillStyle = obstacle.highlighted ? 'red' : 'white';
        ctx.fill();
        ctx.closePath();
      });
    };

    const update = () => {
      ctx.clearRect(0, 0, WIDTH, HEIGHT);
      drawObstacles();
      balls.current.forEach(ball => {
        ball.draw();
        ball.update();
      });
      requestAnimationFrame(update);
    };

    update();
  }, [rows, risk, multipliers, isSimulating]);

  useEffect(() => {
    console.log("Risk changed to:", risk);
    console.log("Rows changed to:", rows);
    const updatedMultipliers = getMultipliers();
    setMultipliers(updatedMultipliers);
    setHitMultiplier(null);
    // console.log("Multipliers fully updated:", updatedMultipliers);
    setTimeout(() => {
      setHitMultiplier(null);
    }, 100);
    console.log("Updated Multipliers:", updatedMultipliers);
  }, [rows, risk]);

  function addOpacityToColor(hexColor, amount) {
    // Convert HEX color to RGB
    const hex = hexColor.replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    // Reduce brightness by multiplying each channel by (1 - amount)
    const darken = (channel) => Math.max(0, Math.min(255, Math.floor(channel * (1 - amount))));
    const rDark = darken(r);
    const gDark = darken(g);
    const bDark = darken(b);

    // Return darkened RGB color in rgba format
    return `rgba(${rDark}, ${gDark}, ${bDark}, 1)`;
  }

  const handleAmountChange = (e) => {
    const value = parseFloat(e.target.value);
    if (!isNaN(value) && value >= 0) {
      setBetAmount(value);
    } else if (e.target.value === '') {
      setBetAmount(''); // Allow clearing the input
    }
  };

  // Start game by calling backend
  const startGame = async () => {
    if (!isAutoPlaying && ballsInPlay > 0) return; // Prevents launching if balls are still active

    setBallsInPlay((prev) => prev + 1); // Increment active balls count

    try {
      const response = await axios.post('https://backend-service-643728542364.southamerica-east1.run.app/api/plinko/start', {
        betAmount,
        rows,
        risk,
      }, { withCredentials: true });

      console.log('Game started:', response.data);



      setTimeout(() => {
        setBallsInPlay((prev) => prev - 1, 0); // Decrement active balls count when the ball finishes
      }, 900); // Adjust duration based on gameplay
      setGameId(response.data.gameId);  // Make sure gameId is properly set here
      console.log("Point: ", response.data.point);
      addBallsRef.current(response.data.point); // Add a new ball to the game
      // setStartX(response.data.point);
      setMultiplierSent(false);
    } catch (error) {
      console.error('Error starting Plinko game:', error);
      const errorMsg = error.response?.data?.error || 'An unexpected error occurred.';
      setErrorMessage(errorMsg); // Set error message
      setBallsInPlay((prev) => Math.max(prev - 1, 0)); // Ensure active balls count doesn't go below zero
    }
  };

  const handleAutoPlay = async () => {
    if (isAutoPlaying || ballsInPlay > 0) return; // Prevent restarting while balls are still active

    setIsAutoPlaying(true);
    isAutoPlayingRef.current = true;  // Track autoplay status

    for (let i = 1; i <= numberOfGames; i++) {
      if (!isAutoPlayingRef.current) break;  // Stop autoplay if interrupted

      await startGame(); // Trigger a single game

      await new Promise((resolve) => setTimeout(resolve, 25)); // Delay between games
    }
    setIsAutoPlaying(false);
    isAutoPlayingRef.current = false;
  };

  const stopAutoPlay = () => {
    isAutoPlayingRef.current = false;
    setIsAutoPlaying(false);
  };

  const onBallLanded = () => {
    setBallsInPlay((prev) => Math.max(0, prev - 1)); // Ensure it never goes below 0
  };

  // Send hit multiplier to backend
  const updateMultiplier = async (multiplier) => {
    try {
      const response = await axios.post('https://backend-service-643728542364.southamerica-east1.run.app/api/plinko/multiplier', {
      }, { withCredentials: true });

      console.log('Multiplier updated:', response.data);
    } catch (error) {
      console.error('Error updating multiplier:', error);
    }
  };

  const betMaxAmount = () => {
    if (selectedBalanceType === "primary") {
      if (balance > 500) {
        setBetAmount(500);
      } else {
        setBetAmount(balance);
      }
    } else if (selectedBalanceType === "promotional") {
      setBetAmount(betMax);
    }
  };

  return (
    <div className="main">
      <ErrorModal
        message={errorMessage}
        onClose={() => setErrorMessage(null)}
        duration={5000}
      />
      <div className="game-container plinko-container">
        <div className="side-panel">
          <div className="mode-tabs">
            <button
              className={`mode-tab ${mode === 'Manual' ? 'active' : ''}`}
              onClick={() => setMode('Manual')}
              disabled={isPlaying || isAutoPlaying}
            >
              Manual
            </button>
            <button
              className={`mode-tab ${mode === 'Auto' ? 'active' : ''}`}
              onClick={() => setMode('Auto')}
              disabled={isPlaying || isAutoPlaying}
            >
              Auto
            </button>
          </div>

          {mode === 'Manual' && (
            <div className="manual-controls">
              <div className="side-inputs" style={{ marginBottom: '5px' }}>
                <label>Amount:</label>
                <div className="amount-container">
                  <span className="amount-icon">$</span>
                  <input
                    type="number"
                    value={betAmount}
                    // onChange={(e) => {
                    //   const value = e.target.value;
                    //   if (value === '') {
                    //     setBetAmount('');  // Allow empty input for user convenience
                    //   } else if (/^\d*\.?\d*$/.test(value)) {
                    //     setBetAmount(value);  // Allow valid decimal numbers
                    //   }
                    // }}
                    onChange={handleAmountChange}
                    onBlur={() => {
                      setBetAmount((prev) => (prev === '' ? '0.00' : parseFloat(prev).toFixed(2)));
                    }}
                    className="amount-input"
                    step="0.01"
                    min="0" // This prevents decrementing below 0 using the spinner buttons
                    onFocus={(e) => e.target.select()}  // Selects entire value on click
                    disabled={isPlaying} // Disable when settings are locked
                  />
                  <div className="amount-buttons">
                    <button
                      className="amount-button"
                      // onClick={() => setAmount((prev) => (prev / 2).toFixed(2))}
                      onClick={() => setBetAmount((prev) => (prev / 2))}
                      disabled={isPlaying} // Disable when settings are locked
                    >
                      ½
                    </button>
                    <button
                      className="amount-button"
                      onClick={() => setBetAmount((prev) => (prev * 2))}
                      disabled={isPlaying} // Disable when settings are locked
                    >
                      2×
                    </button>
                    <button
                      className="amount-button"
                      onClick={betMaxAmount}
                      disabled={isPlaying}
                    >
                      MAX
                    </button>
                  </div>
                </div>
              </div>
              <div className="side-inputs" style={{ marginBottom: '5px' }}>
                <label>Risk:</label>
                <select
                  value={risk}
                  // onChange={(e) => setRisk(e.target.value)}
                  onChange={handleChangeRisk}
                  disabled={isPlaying} // Disable when settings are locked
                >
                  <option value="Low">Low</option>
                  <option value="Medium">Medium</option>
                  <option value="High">High</option>
                </select>
              </div>
              <div className="side-inputs">
                <label>Rows:</label>
                <select
                  value={rows}
                  // onChange={(e) => setRows(parseInt(e.target.value))}
                  onChange={handleChangeRows}
                  disabled={isPlaying} // Disable when settings are locked
                >
                  <option value={8}>8</option>
                  <option value={9}>9</option>
                  <option value={10}>10</option>
                  <option value={11}>11</option>
                  <option value={12}>12</option>
                  <option value={13}>13</option>
                  <option value={14}>14</option>
                  <option value={15}>15</option>
                  <option value={16}>16</option>
                </select>
              </div>
              <button id="play-button" onClick={startGame} disabled={betAmount === 0}>Play</button>
            </div>
          )}

          {mode === 'Auto' && (
            <div className="auto-controls">
              <div className="side-inputs">
                <label>Amount:</label>
                <div className="amount-container">
                  <span className="amount-icon">$</span>
                  <input
                    type="number"
                    value={betAmount}
                    onFocus={(e) => e.target.select()}  // Select all text when clicking
                    // onChange={(e) => {
                    //   const value = e.target.value;
                    //   if (value === '') {
                    //     setBetAmount('');  // Allow empty input for user convenience
                    //   } else if (/^\d*\.?\d*$/.test(value)) {
                    //     setBetAmount(value);  // Allow valid decimal numbers
                    //   }
                    // }}
                    onChange={handleAmountChange}
                    onBlur={() => {
                      setBetAmount((prev) => (prev === '' ? '0.00' : parseFloat(prev).toFixed(2)));
                    }}
                    className="amount-input"
                    step="0.01"
                    min="0" // This prevents decrementing below 0 using the spinner buttons
                    disabled={isAutoPlaying}
                  />
                  <div className="amount-buttons">
                    <button
                      className="amount-button"
                      // onClick={() => setAmount((prev) => (prev / 2).toFixed(2))}
                      onClick={() => setBetAmount((prev) => (prev / 2))}
                      disabled={isAutoPlaying}
                    >
                      ½
                    </button>
                    <button
                      className="amount-button"
                      onClick={() => setBetAmount((prev) => (prev * 2))}
                      disabled={isAutoPlaying}
                    >
                      2×
                    </button>
                    <button
                      className="amount-button"
                      onClick={betMaxAmount}
                      disabled={betAmount === 0 || isPlaying}
                    >
                      MAX
                    </button>
                  </div>
                </div>
              </div>
              <div className="side-inputs">
                <label>Risk:</label>
                <select
                  value={risk}
                  // onChange={(e) => setRisk(e.target.value)}
                  onChange={handleChangeRisk}
                  disabled={isAutoPlaying} // Disable when settings are locked
                >
                  <option value="Low">Low</option>
                  <option value="Medium">Medium</option>
                  <option value="High">High</option>
                </select>
              </div>
              <div className="side-inputs">
                <label>Rows:</label>
                <select
                  value={rows}
                  // onChange={(e) => setRows(parseInt(e.target.value))}
                  onChange={handleChangeRows}
                  disabled={isAutoPlaying} // Disable when settings are locked
                >
                  <option value={8}>8</option>
                  <option value={9}>9</option>
                  <option value={10}>10</option>
                  <option value={11}>11</option>
                  <option value={12}>12</option>
                  <option value={13}>13</option>
                  <option value={14}>14</option>
                  <option value={15}>15</option>
                  <option value={16}>16</option>
                </select>
              </div>
              <div className="side-inputs">
                <label>Number of Games:</label>
                <input
                  type="number"
                  value={numberOfGames}
                  onChange={(e) => setNumberOfGames(parseInt(e.target.value, 10) || 0)}
                  onFocus={(e) => e.target.select()}  // Select all text when clicking
                  min="1"
                  disabled={isAutoPlaying}
                />
              </div>
              {isAutoPlaying ? (
                <button id="play-button" onClick={stopAutoPlay}>
                  Stop Autoplay
                </button>
              ) : (
                <button
                  id="play-button"
                  onClick={handleAutoPlay}
                  disabled={numberOfGames === 0 || betAmount === 0 || ballsInPlay > 0}
                  style={{ color: ballsInPlay > 0 ? "#555" : "", cursor: ballsInPlay > 0 ? "not-allowed" : "pointer" }}
                >
                  Start Autoplay
                </button>
              )}
            </div>
          )}

          <div className="side-panel" style={{ position: 'relative' }}>
            <button
              className="mute-button"
              onClick={() => setIsMuted((prev) => !prev)}
            >
              <FontAwesomeIcon icon={isMuted ? faVolumeMute : faVolumeUp} />
            </button>
          </div>
        </div>

        <div className="game-panel plinko">
          <canvas className="plinko-canvas" ref={canvasRef} width="800" height="600"></canvas>
          {/* {hitMultiplier !== null && <div className="multiplier-hit">Hit: {hitMultiplier}x</div>} */}
          {hitMultiplier !== null && hitMultiplier !== undefined ? (
            <div className="multiplier-hit">
              <div className="multiplier-hit-box">
                <img src={RouloBall} alt="Hit Icon" className="hit-icon" />
                Hit: <span className="highlight-multiplier">{hitMultiplier}x</span>
              </div>
            </div>
          ) : (
            <div className="multiplier-hit">
              <div className="multiplier-hit-box">
                <span>No Hit</span>
              </div>
            </div>
          )}

          <div
            className="odds-container"
            style={{
              '--num-boxes': multipliers.length,
              '--container-width': `${multipliers.length * (100 / (rows + 3))}%`,
            }}
          >
            {multipliers.map((multiplier, index) => {
              const netGain = (betAmount * multiplier - betAmount).toFixed(2);
              // const isHit = hitIndex === index; // Check if this specific index was hit

              return (
                <div
                  key={index}
                  className={`odds-box plinko-box ${hitBoxes.includes(index) ? 'bounce' : ''}`} // Apply "hit" class if hit
                  style={{
                    backgroundColor: getBoxColor(index, multipliers.length),
                    // boxShadow: `0px 4px 0px ${getBoxColor(index, multipliers.length)}`,
                    boxShadow: `0px 4px 0px ${addOpacityToColor(getBoxColor(index, multipliers.length), 0.35)}`, // Box shadow with reduced opacity
                    width: `${100 / multipliers.length}%`, /* Ensures even spacing */
                    transition: 'background-color 0.3s ease',
                  }}
                  title={`Chance: ${probabilities[index]}% | Net Gain: $${netGain}`}
                >
                  {multiplier}
                  {/* × <- ADD THIS BACK LATER */}
                  {/* <div className="tooltip">
                    <p>Chance: {probabilities[index]}%</p>
                    <p>Net Gain: ${netGain}</p>
                  </div> */}
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {/* EXAMPLE OF PASSING IN GAME DETAILS */}
      <GameDescCard
        gameTitle="Plinko"
        totalBets={6896668}
        gameDescription="Plinko is a thrilling game of chance where players strategically avoid hidden mines."
        bigWins={[
          { user: 'Player1', bet: 2000, multiplier: 292.94, payout: 585880 },
          { user: 'Player2', bet: 989.59, multiplier: 292.94, payout: 289890.49 },
          { user: 'Player3', bet: 1500, multiplier: 150.5, payout: 225750 },
        ]}
        luckyWins={[
          { user: 'Player3', bet: 1500, multiplier: 150.5, payout: 225750 },
          { user: 'Player2', bet: 989.59, multiplier: 292.94, payout: 289890.49 },
          { user: 'Player1', bet: 2000, multiplier: 292.94, payout: 585880 },
        ]}
        gameImageUrl={gameImg}
      />

    </div>
  );
}

export default Game;