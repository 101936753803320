import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './SlotGame.css';
import RouloLogo from '../imgs/roulo_logo_2.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpand, faArrowsLeftRight } from '@fortawesome/free-solid-svg-icons';
import SimilarSlotsCarousel from '../../components/SimilarSlotsCarousel';
import LiveGameFeed from '../../components/LiveGameFeed';
import GameDescCard from '../../components/GameDescCard';
import gameImg from '../../components/img/plinko.png'
import LoadingGif from '../imgs/Roulo_Loading_360.gif'; // Add your loading GIF here
import ErrorModal from '../../components/ErrorModal/ErrorModal';

const SlotGame = () => {
    const [isTheaterMode, setIsTheaterMode] = useState(false);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [gameURL, setGameURL] = useState('');
    const { gameId } = useParams();
    const [errorMessage, setErrorMessage] = useState(null); // State for error messages
    const [isDemoMode, setIsDemoMode] = useState('REAL'); // Default to Demo mode

    const toggleTheaterMode = () => {
        setIsTheaterMode((prev) => !prev);
    };

    const fetchGameURL = async (symbol) => {
        try {
            const response = await fetch('https://backend-service-643728542364.southamerica-east1.run.app/api/pragmaticplay/game/url', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    symbol: symbol || 'vs20olympx',
                    language: 'en',
                    platform: 'WEB',
                    playMode: isDemoMode
                }),
                credentials: 'include', // Include cookies in the request
            });

            const data = await response.json();

            if (response.ok) {
                setGameURL(data.gameURL);
                // setGameID(data.gameID);
            } else {
                console.error('Failed to fetch game URL:', data.error);
            }
        } catch (error) {
            console.error('Error fetching game URL:', error.message);
        }
    };

    useEffect(() => {
        if(gameId) {
            fetchGameURL(gameId);
        }
    }, [gameId, isDemoMode]);


    useEffect(() => {
        const handleFullScreenChange = () => {
            // Check if the document is in fullscreen mode
            const isCurrentlyFullScreen =
                document.fullscreenElement ||
                document.mozFullScreenElement ||
                document.webkitFullscreenElement ||
                document.msFullscreenElement;

            setIsFullScreen(!!isCurrentlyFullScreen);
        };

        document.addEventListener('fullscreenchange', handleFullScreenChange);
        document.addEventListener('webkitfullscreenchange', handleFullScreenChange);
        document.addEventListener('mozfullscreenchange', handleFullScreenChange);
        document.addEventListener('msfullscreenchange', handleFullScreenChange);

        return () => {
            document.removeEventListener('fullscreenchange', handleFullScreenChange);
            document.removeEventListener('webkitfullscreenchange', handleFullScreenChange);
            document.removeEventListener('mozfullscreenchange', handleFullScreenChange);
            document.removeEventListener('msfullscreenchange', handleFullScreenChange);
        };
    }, []);

    const toggleDemoMode = async () => {
        const newMode = isDemoMode === 'REAL' ? 'DEMO' : 'REAL';
        setIsDemoMode(newMode);

        if (!gameId) {
            console.error("No gameId found to fetch game URL.");
            setErrorMessage("No game selected.");
            return;
        }

        // Fetch a new game URL after toggling demo mode
        try {
            const response = await fetch('https://backend-service-643728542364.southamerica-east1.run.app/api/pragmaticplay/game/url', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    symbol: gameId,
                    language: 'en',
                    platform: 'WEB',
                    playMode: newMode
                }),
                credentials: 'include',
            });

            const data = await response.json();

            if (response.ok) {
                setGameURL(data.gameURL);
            } else {
                console.error('Failed to fetch game URL:', data.error);
                setErrorMessage(data.error || 'Failed to fetch game URL');
            }
        } catch (error) {
            console.error('Error fetching game URL:', error.message);
            setErrorMessage(error.message);
        }
    };

    const toggleFullScreen = () => {
        const iframe = document.getElementById('slot-game-iframe');
        if (!iframe) {
            console.error('Iframe with ID "slot-game-iframe" not found.');
            return;
        }

        if (!isFullScreen) {
            // Enter fullscreen mode
            if (iframe.requestFullscreen) {
                iframe.requestFullscreen();
            } else if (iframe.mozRequestFullScreen) {
                iframe.mozRequestFullScreen();
            } else if (iframe.webkitRequestFullscreen) {
                iframe.webkitRequestFullscreen();
            } else if (iframe.msRequestFullscreen) {
                iframe.msRequestFullscreen();
            }
        } else {
            // Exit fullscreen mode
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }
        }
    };

    // Example data for livefeed box
    const livefeedData = [
        { game: "Roulette", username: "Hidden", betAmount: "0.18", multiplier: "0.00", payout: "0.00" },
        { game: "Baccarat", username: "Hidden", betAmount: "0.18", multiplier: "0.00", payout: "0.00" },
        { game: "Blackjack", username: "Hidden", betAmount: "0.18", multiplier: "0.00", payout: "0.00" },
        { game: "Crash", username: "Hidden", betAmount: "0.18", multiplier: "0.00", payout: "0.00" },
        { game: "50/50 Dice", username: "Hidden", betAmount: "0.18", multiplier: "0.00", payout: "0.00" },
        { game: "Mines", username: "Hidden", betAmount: "0.18", multiplier: "0.00", payout: "0.00" },
    ];

    return (
        <div className="main">
            <ErrorModal
                message={errorMessage}
                onClose={() => setErrorMessage(null)}
                duration={5000}
            />
            <div
                id="slot-game-container"
                className={`slot-game-container ${isTheaterMode ? 'theater-mode' : ''}`}
            >
                {gameURL ? (
                    <iframe
                        id="slot-game-iframe"
                        src={gameURL}
                        className="slot-game-iframe"
                        title="Slot Game"
                        allowFullScreen
                    ></iframe>
                ) : (
                    <div className="slots-loading-overlay">
                        <img src={LoadingGif} alt="Loading..." className="slots-loading-gif" />
                    </div>
                )}

                <div className="slot-game-footer">
                    <span className="footer-logo">
                        <img src={RouloLogo} className="roulo-logo" alt="Roulo" />
                    </span>
                    <span className="footer-game-info">
                        <strong>{gameId}</strong> {/* Change to gameID and gameProvider*/}
                    </span>
                    <div className="game-options">
                        <button className="game-option-btn demo-toggle-btn" onClick={toggleDemoMode}>
                            {isDemoMode === 'DEMO' ? 'DEMO' : 'REAL'}
                        </button>

                        <button className="game-option-btn" onClick={toggleTheaterMode}>
                            <span className="tooltip">Theater Mode</span>
                            <FontAwesomeIcon
                                icon={faArrowsLeftRight}
                                alt="Theater Mode"
                                className="icon"
                            />
                        </button>
                        <button className="game-option-btn" onClick={toggleFullScreen}>
                            <span className="tooltip">Full Screen</span>
                            <FontAwesomeIcon
                                icon={faExpand}
                                alt="Full Screen"
                                className="icon"
                            />
                        </button>
                    </div>
                </div>
            </div>

            {/* EXAMPLE OF PASSING IN GAME DETAILS */}
            <GameDescCard
                gameTitle="Mines"
                totalBets={6896668}
                gameDescription="Mines is a thrilling game of chance where players strategically avoid hidden mines."
                bigWins={[
                    { user: 'Player1', bet: 2000, multiplier: 292.94, payout: 585880 },
                    { user: 'Player2', bet: 989.59, multiplier: 292.94, payout: 289890.49 },
                    { user: 'Player3', bet: 1500, multiplier: 150.5, payout: 225750 },
                ]}
                luckyWins={[
                    { user: 'Player3', bet: 1500, multiplier: 150.5, payout: 225750 },
                    { user: 'Player2', bet: 989.59, multiplier: 292.94, payout: 289890.49 },
                    { user: 'Player1', bet: 2000, multiplier: 292.94, payout: 585880 },
                ]}
                gameImageUrl={gameImg}
            />


            <div className="slots-swiper-container">
                <SimilarSlotsCarousel />
            </div>

            <LiveGameFeed data={livefeedData} />
        </div>
    );
};

export default SlotGame;