import React, { useState } from 'react';
import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faXTwitter, faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import RouloLogo from './img/roulo_logo_2.png';

const Footer = () => {

  const [openSection, setOpenSection] = useState(null);

  const toggleSection = (section) => {
    setOpenSection(openSection === section ? null : section);
  };

  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-left">
          <div className="brand-info">
            {/* <h2>Roulo</h2> */}
            <img src={RouloLogo} className="roulo-logo-footer" />
            <p className="footer-statement">
              Roulo is a brand name of RBGAMING N.V. *CHANGE LATER* Company Address: *INSERT ADDRESS LOCATION HERE*
            </p>
            <p className="footer-statement">
              Roulo is licensed and authorized by the Government of *INSERT GOV HERE* and operates under the Master License of _.
            </p>
          </div>
          <div className="contact-info">
            <p>
              <a href="mailto:support@roulo.com">support@roulo.com</a>
            </p>
            {/* <p>
              <a href="mailto:marketing@roulo.com">marketing@roulo.com</a>
            </p> */}
          </div>
        </div>

        <div className={`footer-section ${openSection === 'roulo' ? 'active' : ''}`} onClick={() => toggleSection('roulo')}>
          <h4>
            Roulo
            <FontAwesomeIcon
              icon={faAngleDown}
              className={`dropdown-icon ${openSection === 'roulo' ? 'rotate' : ''}`}
            />
          </h4>
          <ul className="footer-links">
            <li><a href="/rewards">Rewards</a></li>
            <li><a href="/promo">Promotions</a></li>
            {/* <li><a href="/provably-fair">Provably Fair</a></li> */}
            <li><a href="/contact">Contact Us</a></li>
          </ul>
        </div>

        <div className={`footer-section ${openSection === 'originals' ? 'active' : ''}`} onClick={() => toggleSection('originals')}>
          <h4>
            Originals
            <FontAwesomeIcon
              icon={faAngleDown}
              className={`dropdown-icon ${openSection === 'originals' ? 'rotate' : ''}`}
            />
          </h4>
          <ul className="footer-links">
            <li><a href="/plinko">Plinko</a></li>
            <li><a href="/dice">Dice</a></li>
            <li><a href="/mines">Mines</a></li>
            <li><a href="/keno">Keno</a></li>
            {/* <li><a href="/originals">All Roulo Originals</a></li> */}
          </ul>
        </div>

        <div className={`footer-section ${openSection === 'resources' ? 'active' : ''}`} onClick={() => toggleSection('resources')}>
          <h4>
            Resources
            <FontAwesomeIcon
              icon={faAngleDown}
              className={`dropdown-icon ${openSection === 'resources' ? 'rotate' : ''}`}
            />
          </h4>
          <ul className="footer-links">
            <li><a href="/responsible">Responsible Gambling</a></li>
            <li><a href="/accessibility">Accessibility</a></li>
            <li><a href="/code-of-ethics">Code of Ethics</a></li>
            {/* <li><a href="/modern-slavery">Modern Slavery Statement</a></li>
            <li><a href="/complaints">Complaints Policy</a></li>
            <li><a href="/gdpr-compliance">GDPR Compliance</a></li> */}
          </ul>
        </div>

        <div className={`footer-section ${openSection === 'legal' ? 'active' : ''}`} onClick={() => toggleSection('legal')}>
          <h4>
            Legal
            <FontAwesomeIcon
              icon={faAngleDown}
              className={`dropdown-icon ${openSection === 'legal' ? 'rotate' : ''}`}
            />
          </h4>
          <ul className="footer-links">
            {/* <li><a href="/aml">AML</a></li>
            <li><a href="/sportsbook-rules">Sportsbook Rules</a></li>
            <li><a href="/cookie-policy">Cookie Policy</a></li>
            <li><a href="/editorial-policy">Editorial Policy</a></li> */}
            <li><a href="/disclaimer">Disclaimer</a></li>
            <li><a href="/privacy-policy">Privacy Policy</a></li>
            <li><a href="/terms">Terms and Conditions</a></li>
          </ul>
        </div>
      </div>

      <div className="footer-bottom">
        <div className="social-icons">
          <a id="instagram" href="https://instagram.com" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          <a id="x-twitter" href="https://x.com" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faXTwitter} />
          </a>
          <a id="facebook" href="https://facebook.com" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faFacebook} />
          </a>
        </div>
        <div className="legal-icons">
          <span className="age-restriction">18+</span>
          <span className="gc-icon">GC</span>
        </div>
      </div>

      <div className="footer-copyright">
        <p>© 2024 Roulo. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
