import React from 'react';
import { Link } from 'react-router-dom';
import '../GameElements.css';

function SlotTwoCard() {
    const slotImageUrl = 'https://common-static.ppgames.net/game_pic/square/200/vs20olympgate.png'; // API URL

    return (
        <Link to="/slotgame" style={{ textDecoration: 'none', color: 'inherit' }}>
            <div className="cardElements">
                <img src={slotImageUrl} alt="Slot Game One" />
            </div>
        </Link>
    );
}

export default SlotTwoCard;
