import React from 'react';
// import '../../pages/Settings.css'

function GeneralSettings() {
  return (
    <div className="settings-section">
      <h2>General</h2>
      <label className="email-settings-label">Email Address</label>
      <div className="settings-field email-field">
        <input id="email-address" type="email" placeholder="useremail@example.com" />
        <button className="verify-btn">Verify</button>
      </div>
      <div className="settings-field">
        <label>Referrer</label>
        <input type="text" placeholder="Referred by" />
      </div>
      {/* <div className="linked-accounts">
        <h3>Linked Accounts</h3>
        <div>
          <label className="switch">
            <input type="checkbox" />
            <span className="slider"></span>
          </label>
          <span>Google</span>
        </div>
        <div>
          <label className="switch">
            <input type="checkbox" />
            <span className="slider"></span>
          </label>
          <span>Steam</span>
        </div>
      </div> */}
      <div className="account-closure">
        <h3>Account Closure</h3>
        <p>You may close your account by clicking the "Close My Account" button below. All of your data will be permanently wiped.</p>
        <button className="close-account-btn">Close My Account</button>
      </div>
    </div>
  );
}

export default GeneralSettings;