import React from 'react';
import { Link } from 'react-router-dom';
import Dice from './img/dice_final.png';
import './plinkoCard.css';

function DiceCard(){
    return(
        <Link to="/dice" style={{ textDecoration: 'none', color: 'inherit' }}>
            <div className="cardElements">
                <img src={Dice} alt="Dice Game" />
            </div>
        </Link>
    );
}

export default DiceCard