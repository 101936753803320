import React from 'react';
import { Link } from 'react-router-dom';
import SlotGameEight from '../img/SlotCards/pragmatic_vs20sugarrushx.png';
import '../GameElements.css';

function SlotGameEightCard(){
    return(
        <Link to="/slotgame" style={{ textDecoration: 'none', color: 'inherit' }}>
            <div className="cardElements">
                <img src={SlotGameEight} alt="Slot Game Eight" />
            </div>
        </Link>
    );
}

export default SlotGameEightCard