import React, { useState, useEffect } from 'react';
import GameElements from '../components/GameElements';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { FaSearch } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins, faHatWizard } from '@fortawesome/free-solid-svg-icons';
import SlotOneCard from '../components/SlotCards/SlotOneCard';
import SlotTwoCard from '../components/SlotCards/SlotTwoCard';
import SlotThreeCard from '../components/SlotCards/SlotThreeCard';
import SlotFourCard from '../components/SlotCards/SlotFourCard';
import SlotFiveCard from '../components/SlotCards/SlotFiveCard';
import SlotSixCard from '../components/SlotCards/SlotSixCard';
import SlotSevenCard from '../components/SlotCards/SlotSevenCard';
import SlotEightCard from '../components/SlotCards/SlotEightCard';
import SlotNineCard from '../components/SlotCards/SlotNineCard';
import SlotTenCard from '../components/SlotCards/SlotTenCard';
import LiveGameFeed from '../components/LiveGameFeed';

function Slots() {

    const livefeedData = [
        { game: "Roulette", username: "Hidden", betAmount: "0.18", multiplier: "0.00", payout: "0.00" },
        { game: "Baccarat", username: "Hidden", betAmount: "0.18", multiplier: "0.00", payout: "0.00" },
        { game: "Blackjack", username: "Hidden", betAmount: "0.18", multiplier: "0.00", payout: "0.00" },
        { game: "Crash", username: "Hidden", betAmount: "0.18", multiplier: "0.00", payout: "0.00" },
        { game: "50/50 Dice", username: "Hidden", betAmount: "0.18", multiplier: "0.00", payout: "0.00" },
        { game: "Mines", username: "Hidden", betAmount: "0.18", multiplier: "0.00", payout: "0.00" },
    ];

    return (
        <div className="main">
            <div className="slots-page">
                <div className="swiper-container-slots">
                    <div className="header-slots">
                        <h1>Slots</h1>
                    </div>
                    {/* Search Bar Below */}
                    {/* <div className="header-slots">
                        <div className="search-container">
                            <FaSearch className="icon" />
                            <input type="text" className="search-bar" placeholder="Search..." />
                        </div>
                    </div> */}
                    <div className="sub-main">
                        <div className="sub-main-solos">
                            <SlotOneCard />
                            <SlotTwoCard />
                            <SlotThreeCard />
                            <SlotFourCard />
                            <SlotFiveCard />
                            <SlotSixCard />
                            <SlotSevenCard />
                            <SlotEightCard />
                            <SlotNineCard />
                            <SlotTenCard />
                        </div>
                    </div>
                </div>
            </div>

            <LiveGameFeed data={livefeedData} />
        </div>
    );
}

export default Slots;