import React from 'react';
import { Link } from 'react-router-dom';
import SlotGameThree from '../img/SlotCards/pragmatic_vs20fruitparty.png';
import '../GameElements.css';

function SlotGameThreeCard(){
    return(
        <Link to="/slotgame" style={{ textDecoration: 'none', color: 'inherit' }}>
            <div className="cardElements">
                <img src={SlotGameThree} alt="Slot Game Three" />
            </div>
        </Link>
    );
}

export default SlotGameThreeCard