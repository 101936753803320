import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../GameElements.css';

function PragmaticSlotsCard({ gameId }) {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(`/slotgame/${gameId}`)
    }
    // const slotImageUrl = `https://common-static.ppgames.net/game_pic/square/200/${gameId}.png`;
    const slotImageUrl = `https://common-static.ppgames.net/gs2c/common/lobby/v1/apps/slots-lobby-assets/${gameId}/${gameId}_260x350_B.png`;

    return (
        <div style={{ textDecoration: 'none', color: 'inherit' }} onClick={handleClick}>
            <div className="cardElements">
                <img src={slotImageUrl} alt={`Slot Game ${gameId}`} />
            </div>
        </div>
    );
}

export default PragmaticSlotsCard;
