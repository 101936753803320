import React from 'react';
import './LegalTexts.css';

const CodeOfEthics = () => {
    return (
        <div className="main">
            <div className="legal-container">
                <div className="legal-header">
                    <h1>Code of Ethics</h1>
                </div>
                <div className="legal-content">
                    <p className="legal-body">
                        At Roulo, we are committed to upholding the highest ethical standards in everything we do. This Code of Ethics defines the core principles that guide our business conduct.
                    </p>
                    <div>
                        <h2 className="legal-subheader">Integrity</h2>
                        <p>
                            We act with honesty, integrity, and transparency in all our dealings. We do not tolerate any form of bribery, corruption, fraud, or deceitful practices.
                        </p>
                    </div>
                    <div>
                        <h2 className="legal-subheader">Legal Compliance</h2>
                        <p>
                            We diligently comply with all applicable laws and regulations in the jurisdictions where we operate. We respect intellectual property rights.
                        </p>
                    </div>
                    <div>
                        <h2 className="legal-subheader">Responsible Gaming</h2>
                        <p>
                            We promote responsible gaming and have robust policies to prevent underage gambling, problem gambling, and other gambling-related harm.
                        </p>
                    </div>
                    <div>
                        <h2 className="legal-subheader">Consumer Protection</h2>
                        <p>
                            We safeguard consumer data privacy and provide our products and services fairly, transparently, and as accurately represented.
                        </p>
                    </div>
                    <div>
                        <h2 className="legal-subheader">Workplace Equity</h2>
                        <p>
                            We value diversity and maintain a safe, inclusive, and discrimination-free workplace with equal opportunities for all employees.
                        </p>
                    </div>
                    <div>
                        <h2 className="legal-subheader">Environmental Responsibility</h2>
                        <p>
                            We strive to minimize our environmental impact and incorporate sustainable practices across our operations and supply chain.
                        </p>
                        <p>
                            This Code applies to all Roulo employees, contractors, and business partners. Violations may result in disciplinary action up to termination of employment or business relationships. We encourage everyone to report ethical concerns through designated channels.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CodeOfEthics;
