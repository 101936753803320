import React from 'react';
import { Link } from 'react-router-dom';
import SlotGameSix from '../img/SlotCards/pragmatic_vs20olympx.png';
import '../GameElements.css';

function SlotGameSixCard(){
    return(
        <Link to="/slotgame" style={{ textDecoration: 'none', color: 'inherit' }}>
            <div className="cardElements">
                <img src={SlotGameSix} alt="Slot Game Six" />
            </div>
        </Link>
    );
}

export default SlotGameSixCard