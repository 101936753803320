import React, { useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import ErrorModal from '../components/ErrorModal/ErrorModal';

const SignInModal = ({ isOpen, closeModal }) => {
    const [identifier, setIdentifier] = useState('');
    const [pwd, setPwd] = useState('');
    const [showPwd, setShowPwd] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [isRecoverMode, setIsRecoverMode] = useState(false); // Toggle for Recover Account mode
    const [recoveryIdentifier, setRecoveryIdentifier] = useState(''); // State for recovery input
    const [successMsg, setSuccessMsg] = useState(''); // State for success message
    const { setUsername, setBalance } = useContext(AuthContext);
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            await axios.post('https://backend-service-643728542364.southamerica-east1.run.app/api/users/login', {
                identifier,
                password: pwd,
            }, {
                withCredentials: true,
            });

            const profileResponse = await axios.get('https://backend-service-643728542364.southamerica-east1.run.app/api/users/profile', { withCredentials: true });
            setUsername(profileResponse.data.username);

            const balanceResponse = await axios.get('https://backend-service-643728542364.southamerica-east1.run.app/api/users/balance', { withCredentials: true });
            setBalance(balanceResponse.data.balance);

            closeModal(); // Close the modal after successful login
            navigate('/'); // Redirect to home page
        } catch (error) {
            const errorMsg = 
                error.response?.data?.error || 'An unexpected error occurred.';
            setErrMsg(errorMsg);
        }
    };

    const handleRecovery = async (e) => {
        e.preventDefault();
        try {
            await axios.post('https://backend-service-643728542364.southamerica-east1.run.app/api/users/request-password-reset', {
                emailOrUsername: recoveryIdentifier,
            });
            setSuccessMsg('Recovery email sent! Check your inbox.');
        } catch (err) {
            setErrMsg('Failed to send recovery email. Please try again.');
        }
    };

    if (!isOpen) return null;

    return (
        <div className="model-overlay">
            <div className="signin-container">
                <div className="signin-content">
                    <ErrorModal
                        message={errMsg}
                        onClose={() => setErrMsg(null)}
                        duration={5000}
                    />
                    {isRecoverMode ? (
                        <>
                            <h1 className="signup-head">Recover Account</h1>
                            <p>Enter your email or username, and we will send you a recovery code.</p>
                            <form className="signin-form" onSubmit={handleRecovery}>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="recoveryIdentifier">Username or Email:</label>
                                    <input
                                        type="text"
                                        id="recoveryIdentifier"
                                        name="recoveryIdentifier"
                                        value={recoveryIdentifier}
                                        onChange={(e) => setRecoveryIdentifier(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <button type="submit" className="signin-button" disabled={!recoveryIdentifier}>Recover Account</button>
                                </div>
                                {successMsg && <p className="success-msg">{successMsg}</p>}
                                {errMsg && <p className="error-msg">{errMsg}</p>}
                            </form>
                            <div className="already-have-acct">
                                <p>Already have an account? <a className="sign-up-in-link" href="/?signInModel=signin&tab=signin">Sign in</a></p>
                            </div>
                        </>
                    ) : (
                        <>
                            <h1 className="signup-head">Sign In</h1>
                            <form className="signin-form" onSubmit={handleLogin}>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="identifier">Email or Username:</label>
                                    <input
                                        type="text"
                                        id="identifier"
                                        name="identifier"
                                        value={identifier}
                                        onChange={(e) => setIdentifier(e.target.value)}
                                        required
                                    />
                                </div>

                                <div className="form-group">
                                    <label className="form-label" htmlFor="password">Password:</label>
                                    <div className="password-input-container">
                                        <input
                                            type={showPwd ? "text" : "password"}
                                            id="password"
                                            name="password"
                                            onChange={(e) => setPwd(e.target.value)}
                                            value={pwd}
                                            required
                                        />
                                        <FontAwesomeIcon
                                            icon={showPwd ? faEyeSlash : faEye}
                                            onClick={() => setShowPwd(!showPwd)}
                                            className="eye-icon"
                                        />
                                    </div>
                                </div>

                                <div className="form-group">
                                    <button type="submit" className="signin-button" disabled={!pwd || !identifier}>Sign In</button>
                                </div>

                                <div className="dont-have-acct">
                                    <p>Don't have an account? <a className="sign-up-in-link" href="/?regModel=register&tab=register">Sign up</a></p>
                                </div>
                                <div className="forgot-pwd">
                                    <p>Forgot password? <span className="sign-up-in-link" onClick={() => setIsRecoverMode(true)}>Reset</span></p>
                                </div>
                            </form>
                        </>
                    )}
                    <button id="deposit-btn" className="proceed-btn" style={{ marginTop: 25 }} onClick={closeModal}>Close</button>
                </div>
            </div>
        </div>
    );
};

export default SignInModal;
