import React, { useRef, useEffect, useState } from 'react';
import './Game.css';
import './Plinko.css';
import RouloBall from '../components/img/mini_logo.png';
// import GameFooter from '../components/GameFooter';
import ErrorModal from '../components/ErrorModal/ErrorModal';

function Game() {
    const canvasRef = useRef(null);
    const [rows, setRows] = useState(15);
    const [risk, setRisk] = useState('Low');
    const [betAmount, setBetAmount] = useState(0); // Store the bet amount
    const [multipliers, setMultipliers] = useState([]);
    const [hitIndex, setHitIndex] = useState(20);
    const [errorMessage, setErrorMessage] = useState(null); // State for error messages
    const [isSimulating, setIsSimulating] = useState(false);
    // const [simulationResults, setSimulationResults] = useState([]);
    const [pegSize, setPegSize] = useState(4);
    const [ballSize, setBallSize] = useState(7);
    const [boxOffset, setBoxOffset] = useState(50);
    const [startTopSpotOfPlinkoGrid, setStartTopSpotOfPlinkoGrid] = useState(1);
    const [animationKey, setAnimationKey] = useState(0); // Forces re-render on animation trigger
    const [hitBoxes, setHitBoxes] = useState([]); // Tracks hit boxes for animation
    // const [startX, setStartX] = useState(0); // Forces re-render on animation trigger

    // const { cryptoRandomNumber } = require('../../backend/random/rng');

    const [simulationResults, setSimulationResults] = useState(
        Array.from({ length: 17 }, () => [])
    );

    const balls = useRef([]);
    const addBallsRef = useRef();

    const handleChangeRows = (e) => {
        setRows(parseInt(e.target.value));
    };

    const handleChangeRisk = (e) => {
        setRisk(e.target.value);
    };

    // Binomial Coefficient
    const binomialCoefficient = (n, k) => {
        if (k === 0 || k === n) return 1;
        let res = 1;
        for (let i = 1; i <= k; i++) {
            res *= (n - i + 1) / i;
        }
        return res;
    };

    // Calculate Probabilities
    const getProbabilities = () => {
        return Array.from({ length: rows + 1 }, (_, k) => {
            const probability = binomialCoefficient(rows, k) * Math.pow(0.5, rows);
            return (probability * 100).toFixed(4); // Convert to percentage
        });
    };

    const probabilities = getProbabilities();

    const getMultipliers = () => {
        let baseMultipliers = [];
        // Cases for Low risk
        if (risk === 'Low' && rows === 8) {
            baseMultipliers = [5.6, 2.1, 1.1, 1, 0.5, 1, 1.1, 2.1, 5.6];
        }
        else if (risk === 'Low' && rows === 9) {
            baseMultipliers = [5.6, 2, 1.6, 1, 0.7, 0.7, 1, 1.6, 2, 5.6];
        }
        else if (risk === 'Low' && rows === 10) {
            baseMultipliers = [8.9, 3, 1.4, 1.1, 1, 0.5, 1, 1.1, 1.4, 3, 8.9];
        }
        else if (risk === 'Low' && rows === 11) {
            baseMultipliers = [8.4, 3, 1.9, 1.3, 1, 0.7, 0.7, 1, 1.3, 1.9, 3, 8.4];
        }
        else if (risk === 'Low' && rows === 12) {
            baseMultipliers = [10, 3, 1.6, 1.4, 1.1, 1, 0.5, 1, 1.1, 1.4, 1.6, 3, 10];
        }
        else if (risk === 'Low' && rows === 13) {
            baseMultipliers = [8.1, 4, 3, 1.9, 1.2, 0.9, 0.7, 0.7, 0.9, 1.2, 1.9, 3, 4, 8.1];
        }
        else if (risk === 'Low' && rows === 14) {
            baseMultipliers = [7.1, 4, 1.9, 1.4, 1.3, 1.1, 1, 0.5, 1, 1.1, 1.3, 1.4, 1.9, 4, 7.1];
        }
        else if (risk === 'Low' && rows === 15) {
            baseMultipliers = [15, 8, 3, 2, 1.5, 1.1, 1, 0.7, 0.7, 1, 1.1, 1.5, 2, 3, 8, 15];
        }
        else if (risk === 'Low' && rows === 16) {
            baseMultipliers = [16, 9, 2, 1.4, 1.4, 1.2, 1.1, 1, 0.5, 1, 1.1, 1.2, 1.4, 1.4, 2, 9, 16];
        }

        // Cases for Medium risk
        if (risk === 'Medium' && rows === 8) {
            baseMultipliers = [13, 3, 1.3, 0.7, 0.4, 0.7, 1.3, 3, 13];
        }
        else if (risk === 'Medium' && rows === 9) {
            baseMultipliers = [18, 4, 1.7, 0.9, 0.5, 0.5, 0.9, 1.7, 4, 18];
        }
        else if (risk === 'Medium' && rows === 10) {
            baseMultipliers = [22, 5, 2, 1.4, 0.6, 0.4, 0.6, 1.4, 2, 5, 22];
        }
        else if (risk === 'Medium' && rows === 11) {
            baseMultipliers = [24, 6, 3, 1.8, 0.7, 0.5, 0.5, 0.7, 1.8, 3, 6, 24];
        }
        else if (risk === 'Medium' && rows === 12) {
            baseMultipliers = [33, 11, 4, 2, 1.1, 0.6, 0.3, 0.6, 1.1, 2, 4, 11, 33];
        }
        else if (risk === 'Medium' && rows === 13) {
            baseMultipliers = [43, 13, 6, 3, 1.3, 0.7, 0.4, 0.4, 0.7, 1.3, 3, 6, 13, 43];
        }
        else if (risk === 'Medium' && rows === 14) {
            baseMultipliers = [58, 15, 7, 4, 1.9, 1, 0.5, 0.2, 0.5, 1, 1.9, 4, 7, 15, 58];
        }
        else if (risk === 'Medium' && rows === 15) {
            baseMultipliers = [88, 18, 11, 5, 3, 1.3, 0.5, 0.3, 0.3, 0.5, 1.3, 3, 5, 11, 18, 88];
        }
        else if (risk === 'Medium' && rows === 16) {
            baseMultipliers = [110, 41, 10, 5, 3, 1.5, 1, 0.5, 0.3, 0.5, 1, 1.5, 3, 5, 10, 41, 110];
        }

        // Cases for High risk
        if (risk === 'High' && rows === 8) {
            baseMultipliers = [29, 4, 1.5, 0.3, 0.2, 0.3, 1.5, 4, 29];
        }
        else if (risk === 'High' && rows === 9) {
            baseMultipliers = [43, 7, 2, 0.6, 0.2, 0.2, 0.6, 2, 7, 43];
        }
        else if (risk === 'High' && rows === 10) {
            baseMultipliers = [76, 10, 3, 0.9, 0.3, 0.2, 0.3, 0.9, 3, 10, 76];
        }
        else if (risk === 'High' && rows === 11) {
            baseMultipliers = [120, 14, 5.2, 1.4, 0.4, 0.2, 0.2, 0.4, 1.4, 5.2, 14, 120];
        }
        else if (risk === 'High' && rows === 12) {
            baseMultipliers = [170, 24, 8.1, 2, 0.7, 0.2, 0.2, 0.2, 0.7, 2, 8.1, 24, 170];
        }
        else if (risk === 'High' && rows === 13) {
            baseMultipliers = [260, 37, 11, 4, 1, 0.2, 0.2, 0.2, 0.2, 1, 4, 11, 37, 260];
        }
        else if (risk === 'High' && rows === 14) {
            baseMultipliers = [420, 56, 18, 5, 1.9, 0.3, 0.2, 0.2, 0.2, 0.3, 1.9, 5, 18, 56, 420];
        }
        else if (risk === 'High' && rows === 15) {
            baseMultipliers = [620, 83, 27, 8, 3, 0.5, 0.2, 0.2, 0.2, 0.2, 0.5, 3, 8, 27, 83, 620];
        }
        else if (risk === 'High' && rows === 16) {
            baseMultipliers = [1000, 130, 26, 9, 4, 2, 0.2, 0.2, 0.2, 0.2, 0.2, 2, 4, 9, 26, 130, 1000];
        }

        // Adjust the number of multiplier boxes based on the number of rows
        const adjustedMultipliers = [];
        for (let i = 0; i <= rows; i++) {
            adjustedMultipliers.push(baseMultipliers[i % baseMultipliers.length]);
        }
        console.log("Adjusted Multipliers:", adjustedMultipliers);
        console.log("Base Multipliers:", baseMultipliers);

        return adjustedMultipliers;
    };

    const getBoxColor = (index, totalBoxes) => {
        if (totalBoxes === 9) {
            if (index === 0 || index === totalBoxes - 1) return '#43D9C3';
            if (index === 1 || index === totalBoxes - 2) return '#3cc4af';
            if (index === 2 || index === totalBoxes - 3) return '#34ad9b';
            if (index === 3 || index === totalBoxes - 4) return '#2e9183';
            return '#257e72';
        }
        else if (totalBoxes === 10) {
            if (index === 0 || index === totalBoxes - 1) return '#FF003F';
            if (index === 1 || index === totalBoxes - 2) return '#FF2B31';
            if (index === 2 || index === totalBoxes - 3) return '#FF5523';
            if (index === 3 || index === totalBoxes - 4) return '#FF8015';
            if (index === 4 || index === totalBoxes - 5) return '#FFAB07';
        }
        else if (totalBoxes === 11) {
            if (index === 0 || index === totalBoxes - 1) return '#FF003F';
            if (index === 1 || index === totalBoxes - 2) return '#FF2632';
            if (index === 2 || index === totalBoxes - 3) return '#FF4D26';
            if (index === 3 || index === totalBoxes - 4) return '#FF7319';
            if (index === 4 || index === totalBoxes - 5) return '#FF9A0D';
            return '#FFC000';
        }
        else if (totalBoxes === 12) {
            if (index === 0 || index === totalBoxes - 1) return '#FF003F';
            if (index === 1 || index === totalBoxes - 2) return '#FF2334';
            if (index === 2 || index === totalBoxes - 3) return '#FF4628';
            if (index === 3 || index === totalBoxes - 4) return '#FF691D';
            if (index === 4 || index === totalBoxes - 5) return '#FF8C11';
            if (index === 5 || index === totalBoxes - 6) return '#FFAF06';
        }
        else if (totalBoxes === 13) {
            if (index === 0 || index === totalBoxes - 1) return '#FF003F';
            if (index === 1 || index === totalBoxes - 2) return '#FF2035';
            if (index === 2 || index === totalBoxes - 3) return '#FF402A';
            if (index === 3 || index === totalBoxes - 4) return '#FF6020';
            if (index === 4 || index === totalBoxes - 5) return '#FF8015';
            if (index === 5 || index === totalBoxes - 6) return '#FFA00B';
            return '#FFC000';
        }
        else if (totalBoxes === 14) {
            if (index === 0 || index === totalBoxes - 1) return '#FF003F';
            if (index === 1 || index === totalBoxes - 2) return '#FF1E35';
            if (index === 2 || index === totalBoxes - 3) return '#FF3B2C';
            if (index === 3 || index === totalBoxes - 4) return '#FF5922';
            if (index === 4 || index === totalBoxes - 5) return '#FF7618';
            if (index === 5 || index === totalBoxes - 6) return '#FF940F';
            if (index === 6 || index === totalBoxes - 7) return '#FFB105';
        }
        else if (totalBoxes === 15) {
            if (index === 0 || index === totalBoxes - 1) return '#FF003F';
            if (index === 1 || index === totalBoxes - 2) return '#FF1B36';
            if (index === 2 || index === totalBoxes - 3) return '#FF372D';
            if (index === 3 || index === totalBoxes - 4) return '#FF5224';
            if (index === 4 || index === totalBoxes - 5) return '#FF6E1B';
            if (index === 5 || index === totalBoxes - 6) return '#FF8912';
            if (index === 6 || index === totalBoxes - 7) return '#FFA509';
            return '#FFC000';
        }
        else if (totalBoxes === 16) {
            if (index === 0 || index === totalBoxes - 1) return '#FF003F';
            if (index === 1 || index === totalBoxes - 2) return '#FF1A37';
            if (index === 2 || index === totalBoxes - 3) return '#FF332E';
            if (index === 3 || index === totalBoxes - 4) return '#FF4D26';
            if (index === 4 || index === totalBoxes - 5) return '#FF661D';
            if (index === 5 || index === totalBoxes - 6) return '#FF8015';
            if (index === 6 || index === totalBoxes - 7) return '#FF9A0D';
            if (index === 7 || index === totalBoxes - 8) return '#FFB304';
        }
        else if (totalBoxes === 17) {
            if (index === 0 || index === totalBoxes - 1) return '#FF003F';
            if (index === 1 || index === totalBoxes - 2) return '#FF1837';
            if (index === 2 || index === totalBoxes - 3) return '#FF302F';
            if (index === 3 || index === totalBoxes - 4) return '#FF4827';
            if (index === 4 || index === totalBoxes - 5) return '#FF6020';
            if (index === 5 || index === totalBoxes - 6) return '#FF7818';
            if (index === 6 || index === totalBoxes - 7) return '#FF9010';
            if (index === 7 || index === totalBoxes - 8) return '#FFA808';
            return '#FFC000';
        }
    };

    useEffect(() => {
        // Dynamically adjust ball size, peg size, and box offset based on rows
        const newSize = Math.max(3, Math.min(7, 16 / rows * 4));
        setPegSize(newSize);
        setBallSize(newSize * 2); // Scale ball relative to peg size
        setBoxOffset(newSize * 15); // Adjust box offset to maintain spacing
    }, [rows]);

    // UseEffect to initialize the canvas
    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        const playButton = document.getElementById('play-button');
        const DECIMAL_MULTIPLIER = 10000;
        const WIDTH = canvas.parentElement.clientWidth;
        const HEIGHT = 600;

        // Dynamically adjust ball and peg sizes based on the number of rows
        const ballRadius = Math.max(6, 24 - rows); // Larger for fewer rows, smaller for more rows
        const obstacleRadius = Math.max(3, 8 - Math.floor(rows / 6)); // Pegs also shrink
    
        const gravity = 300;
        const horizontalFric = 0.6;
        const verticalFric = 0.4;
        let obstacles = [];

        canvas.width = WIDTH;
        canvas.height = HEIGHT;

        function increase(n) {
            return n * DECIMAL_MULTIPLIER;
        }

        const handlePlayButtonClick = () => {
            addBall();  // Your function to add a new ball
        };

        function unincrease(n) {
            return Math.floor(n / DECIMAL_MULTIPLIER);
        }

        // Initialize obstacles
        obstacles = [];
        const spacing = WIDTH / (rows + 3);

        for (let row = 1; row <= rows; row++) {
            const numObstacles = row === 1 ? 3 : row + 2;
            const y = row * (HEIGHT / (rows + 1));
            const offsetX = (WIDTH - (numObstacles - 1) * spacing) / 2;
            for (let col = 0; col < numObstacles; col++) {
                const x = offsetX + col * spacing;
                obstacles.push({ x: increase(x), y: increase(y), radius: obstacleRadius });
            }
        }

        class Ball {
            constructor(x, y, radius, color, ctx, obstacles) {
                this.x = x;
                this.y = y;
                this.radius = radius;
                this.color = color;
                this.vx = 0;
                this.vy = 0;
                this.ctx = ctx;
                this.obstacles = obstacles;
                this.landed = false;
                // this.image = new Image();
                // this.image.src = RouloBall; // Load the custom image
                // this.imageWidth = this.radius * 4.5;  // Adjust this value for desired width
                // this.imageHeight = this.radius * 4.5; // Adjust this value for desired height
            }

            draw() {
                ctx.beginPath();
                ctx.arc(unincrease(this.x), unincrease(this.y), this.radius, 0, Math.PI * 2);
                ctx.fillStyle = this.color;
                ctx.fill();
                ctx.closePath();
            }

            update() {
                if (!this.landed) {
                    const maxFallSpeed = 5000; // Adjust for desired fall speed consistency

                    // Limit velocity to ensure balls fall at the same speed
                    if (this.vy > maxFallSpeed) {
                        this.vy = maxFallSpeed;
                    }

                    this.vy += gravity;
                    this.x += this.vx;
                    this.y += this.vy;

                    const landingZoneY = HEIGHT - 80;  // ~80px above the multiplier boxes

                    if (this.y < landingZoneY) {
                        this.vx *= 0.1; // Reduce lateral movement
                        this.vy = Math.min(this.vy, 3); // Prevent the ball from flying over boxes
                    }

                    // Collision detection logic
                    obstacles.forEach(obstacle => {
                        const dist = Math.hypot(this.x - obstacle.x, this.y - obstacle.y);
                        // const collisionDistance = increase(this.radius + obstacle.radius);

                        if (dist < increase(this.radius + obstacle.radius)) {
                            // if (dist < collisionDistance) {
                            const angle = Math.atan2(this.y - obstacle.y, this.x - obstacle.x);
                            const speed = Math.sqrt(this.vx * this.vx + this.vy * this.vy);
                            this.vx = Math.cos(angle) * speed * horizontalFric;
                            this.vy = Math.sin(angle) * speed * verticalFric;

                            const overlap = this.radius + obstacle.radius - unincrease(dist);
                            this.x += increase(Math.cos(angle) * overlap);
                            this.y += increase(Math.sin(angle) * overlap);

                            // Highlight pegs
                            obstacle.highlighted = true;
                            setTimeout(() => (obstacle.highlighted = false), 300);
                        }
                    });


                    // Detect when the ball has landed
                    if (unincrease(this.y) + ballRadius > HEIGHT - 10 && !this.landed) {
                        this.landed = true;

                        // Get the **final x position** of the ball
                        const finalX = unincrease(this.x);
                        checkMultiplierHit(finalX, this.startX);

                        // setTimeout(() => {
                        //     checkMultiplierHit(finalX, this.startX);
                        // }, 50); // Small delay to ensure ball has settled

                        balls.current = balls.current.filter(b => b !== this); // Remove ball after processing
                    }
                }
            }
        }

        const addBall = () => {
            const startX = increase(WIDTH / 2 - 36 + Math.random() * 72);
            const newBall = new Ball(startX, increase(10), ballRadius, '#2EFDD9', ctx, obstacles);

            // ✅ Store the **initial** X position before the ball moves
            newBall.startX = startX;

            balls.current.push(newBall);
            console.log("Ball dropped at:", startX);
        };

        addBallsRef.current = addBall;
        console.log("balls array: ", balls);

        function checkMultiplierHit(ballX, startX) {
            const multiplierDivs = document.querySelectorAll('.odds-box');
            const canvasRect = canvasRef.current.getBoundingClientRect();
            let hit = null;
            let hitIndex = null;
            let minDistance = Infinity;

            const tolerance = 15; // Small margin for error
            multiplierDivs.forEach((div, index) => {
                const rect = div.getBoundingClientRect();

                const divLeft = rect.left - canvasRect.left - tolerance;
                const divRight = rect.right - canvasRect.left + tolerance;
                const boxCenterTop = (divLeft + divRight) / 2;
                const distance = Math.abs(ballX - boxCenterTop);

                if (ballX >= divLeft && ballX <= divRight && distance < minDistance) {
                    hit = multipliers[index];
                    hitIndex = index;
                    return; // Exit the loop as soon as a hit is detected
                }
            });

            if (hitIndex !== null) {
                setSimulationResults(prevResults => {
                    const updatedResults = [...prevResults];

                    // ✅ Ensure the array exists before pushing a new value
                    if (!updatedResults[hitIndex]) {
                        updatedResults[hitIndex] = [];
                    }

                    updatedResults[hitIndex] = [...updatedResults[hitIndex], startX];
                    return updatedResults;
                });

                console.log(`Ball started at ${startX} and landed in bin ${hitIndex}`);

                // Trigger animation
                setAnimationKey(prevKey => prevKey + 1);
                setHitIndex(hitIndex);

                // Restart bounce animation
                const hitBox = multiplierDivs[hitIndex];
                if (hitBox) {
                    hitBox.classList.remove('hit');
                    setTimeout(() => hitBox.classList.add('hit'), 1);
                }
            }

            // Ensure we only store large values and prevent duplicates
            // if (hit !== null && hitIndex !== null && ballX > 1000) {
            //     console.log('Ball hit multiplier:', hit);
            //     console.log('Hit Index:', hitIndex);

            //     // Append to simulation results instead of replacing
            //     // setSimulationResults(prevResults => ({
            //     //     ...prevResults,
            //     //     [hitIndex]: [...(prevResults[hitIndex] || []), ballX] // Append new ballX
            //     // }));

            //     // Force re-trigger of animation by incrementing the key
            //     setAnimationKey(prevKey => prevKey + 1);
            //     setHitIndex(hitIndex);

            //     // Forcefully restart animation by toggling the class
            //     const hitBox = multiplierDivs[hitIndex];
            //     if (hitBox) {
            //         hitBox.classList.remove('hit');
            //         setTimeout(() => hitBox.classList.add('hit'), 1); // Short delay ensures animation restarts
            //     }
            // }
        }

        // Function to draw obstacles
        const drawObstacles = () => {
            ctx.fillStyle = 'white';
            obstacles.forEach(obstacle => {
                ctx.beginPath();
                ctx.arc(unincrease(obstacle.x), unincrease(obstacle.y), obstacle.radius, 0, Math.PI * 2);
                ctx.fillStyle = obstacle.highlighted ? 'red' : 'white';
                // ctx.fillStyle = 'white';
                ctx.fill();
                ctx.closePath();
            });
        };

        if (playButton) {
            playButton.addEventListener('click', handlePlayButtonClick);
        }

        const update = () => {
            ctx.clearRect(0, 0, WIDTH, HEIGHT);
            drawObstacles();
            balls.current.forEach(ball => {
                ball.draw();
                ball.update();
            });
            requestAnimationFrame(update);
        };

        update();
        if (isSimulating) {
            // simulate();
            runSimulation();
        }

        return () => {
            if (playButton) {
                playButton.removeEventListener('click', handlePlayButtonClick);
            }
        };
    }, [rows, risk, multipliers, isSimulating]);

    useEffect(() => {
        if (multipliers.length > 0) {
            setSimulationResults(() => Array.from({ length: multipliers.length }, () => []));
        }
    }, [multipliers]);


    const runSimulation = async () => {
        for (let i = 0; i < 15000; i++) {
            if (i % 10 === 0) console.log(`Dropping ball #${i + 1}`);
            // addBall();
            addBallsRef.current(); // Add a new ball to the game
            await new Promise(resolve => setTimeout(resolve, 1)); // Adjust timing as needed
        }
        console.log("Simulation Complete!", simulationResults);
    };

    useEffect(() => {
        console.log("Risk changed to:", risk);
        console.log("Rows changed to:", rows);
        const updatedMultipliers = getMultipliers();
        setMultipliers(updatedMultipliers);
        console.log("Updated Multipliers:", updatedMultipliers);
    }, [rows, risk]);

    function addOpacityToColor(hexColor, amount) {
        // Convert HEX color to RGB
        const hex = hexColor.replace('#', '');
        const r = parseInt(hex.substring(0, 2), 16);
        const g = parseInt(hex.substring(2, 4), 16);
        const b = parseInt(hex.substring(4, 6), 16);

        // Reduce brightness by multiplying each channel by (1 - amount)
        const darken = (channel) => Math.max(0, Math.min(255, Math.floor(channel * (1 - amount))));
        const rDark = darken(r);
        const gDark = darken(g);
        const bDark = darken(b);

        // Return darkened RGB color in rgba format
        return `rgba(${rDark}, ${gDark}, ${bDark}, 1)`;
    }

    // const handleBallCollision = (boxIndex) => {
    //     setHitBoxes((prevHits) => {
    //         if (!prevHits.includes(boxIndex)) {
    //             return [...prevHits, boxIndex]; // Add box to hit list
    //         }
    //         return prevHits;
    //     });

    //     // Remove the box from the list after animation duration
    //     setTimeout(() => {
    //         setHitBoxes((prevHits) => prevHits.filter((index) => index !== boxIndex));
    //     }, 300); // Adjust time to match your CSS bounce animation
    // };

    return (
        <div className="main">
            <div>
                <h3>Simulation Results:</h3>
                <pre style={{}}>
                    {JSON.stringify(
                        simulationResults.reduce((acc, arr, index) => {
                            acc[index] = arr;
                            return acc;
                        }, {}),
                        null,
                        2
                    )}
                </pre>
            </div>
            <ErrorModal
                message={errorMessage}
                onClose={() => setErrorMessage(null)}
                duration={5000}
            />
            <div className="game-container plinko-container">
                <div className="side-panel">
                    <div className="side-inputs" style={{ marginBottom: '5px' }}>
                        <label>Risk:</label>
                        <select
                            value={risk}
                            onChange={handleChangeRisk}
                        >
                            <option value="Low">Low</option>
                            <option value="Medium">Medium</option>
                            <option value="High">High</option>
                        </select>
                    </div>
                    <div className="side-inputs">
                        <label>Rows:</label>
                        <select
                            value={rows}
                            onChange={handleChangeRows}
                        >
                            {[...Array(9).keys()].map(i => (
                                <option key={i} value={8 + i}>{8 + i}</option>
                            ))}
                        </select>
                    </div>
                    {/* ADD LOGIC FOR AUTOPLAY HERE */}
                    <button onClick={() => setIsSimulating(true)}>
                        Start Simulation
                    </button>
                </div>

                <div className="game-panel plinko">
                    <canvas className="plinko-canvas" ref={canvasRef} width="800" height="600"></canvas>
                    <div
                        className="odds-container"
                        style={{
                            '--num-boxes': multipliers.length,
                            '--container-width': `${multipliers.length * (100 / (rows + 3))}%`,
                        }}
                    >
                        {multipliers.map((multiplier, index) => {
                            const netGain = (betAmount * multiplier - betAmount).toFixed(2);
                            return (
                                <div
                                    // key={`${index}-${animationKey}`} // Unique key forces re-render
                                    key={index}
                                    // className={`odds-box plinko-box ${hitIndex === index ? 'hit' : ''}`} // Apply "hit" class if hit
                                    className={`odds-box plinko-box ${hitBoxes.includes(index) ? 'bounce' : ''}`} // Apply "hit" class if hit
                                    style={{
                                        backgroundColor: getBoxColor(index, multipliers.length),
                                        // boxShadow: `0px 4px 0px ${getBoxColor(index, multipliers.length)}`,
                                        boxShadow: `0px 4px 0px ${addOpacityToColor(getBoxColor(index, multipliers.length), 0.35)}`, // Box shadow with reduced opacity
                                        width: `${100 / multipliers.length}%`, /* Ensures even spacing */
                                        transition: 'background-color 0.3s ease',
                                    }}
                                    // onClick={() => handleBallCollision(index)}
                                    title={`Chance: ${probabilities[index]}% | Net Gain: $${netGain}`}
                                >
                                    {multiplier}
                                </div>
                            );
                        })}
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Game;