import React from 'react';
import './LegalTexts.css';

const PrivacyPolicy = () => {
    return (
        <div className="main">
            <div className="legal-container">
                <div className="legal-header">
                    <h1>Privacy Policy</h1>
                </div>
                <div className="legal-content">
                    <p>
                        At Roulo, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, disclose, and protect the information you provide to us when you visit our website.
                    </p>

                    <h2 className="legal-subheader">Information We Collect</h2>
                    <ol className="legal-list">
                        <li>
                            <strong>Personal Information:</strong> We may ask you to provide certain personally identifiable information, including but not limited to:
                            <ol>
                                <li>Email address</li>
                                <li>First name and last name</li>
                                <li>Phone number</li>
                                <li>Address, State, Province, ZIP/Postal code, City</li>
                                <li>Bank account information for transactions</li>
                                <li>Date of birth</li>
                            </ol>
                        </li>
                        <li>
                            When you pay for a product or service by wire transfer, additional details may be collected:
                            <ol>
                                <li>Passport or National ID card</li>
                                <li>Bank card statement</li>
                                <li>Other address-linking information</li>
                            </ol>
                        </li>
                        <li>Usage Information: Details like IP address, browser type, and operating system.</li>
                        <li>Cookies: For improving user experience and preferences.</li>
                    </ol>

                    <h2 className="legal-subheader">How We Use Your Information</h2>
                    <ol className="legal-list">
                        <li>To provide and maintain our services.</li>
                        <li>To personalize user experience and provide relevant content.</li>
                        <li>To communicate updates and promotional offers.</li>
                        <li>To detect and prevent fraudulent activities.</li>
                        <li>To compile data for business purposes.</li>
                    </ol>

                    <h2 className="legal-subheader">Information Sharing and Disclosure</h2>
                    <p>
                        We may share your personal information with trusted third-party service providers for business operations. Information may also be disclosed to comply with legal obligations or protect user safety.
                    </p>

                    <h2 className="legal-subheader">Data Security</h2>
                    <p>
                        We implement security measures to protect personal data but acknowledge no method is 100% secure.
                    </p>

                    <h2 className="legal-subheader">Third-Party Links</h2>
                    <p>
                        Our website may contain links to external websites. We are not responsible for their content or privacy practices.
                    </p>

                    <h2 className="legal-subheader">Children's Privacy</h2>
                    <p>
                        Our website is not intended for individuals under 18. We do not knowingly collect information from children.
                    </p>

                    <h2 className="legal-subheader">GDPR Compliance</h2>
                    <p>
                        For users in the EEA, you have rights under GDPR, including access to, correction, and deletion of your data. Contact us to exercise these rights.
                    </p>

                    <h2 className="legal-subheader">Updates to this Privacy Policy</h2>
                    <p>
                        We may update this Privacy Policy periodically. Updates will be posted on this page with a "Last Updated" date.
                    </p>

                    <h2 className="legal-subheader">Contact Us</h2>
                    <p>
                        For inquiries, contact us at <a href="mailto:support@roulo.com">support@roulo.com</a>.
                    </p>
                    <p>Last Updated: 7 May 2024</p>
                </div>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
