import React, { useState, useEffect } from 'react';
import GameCarousel from './GameCarousel';
import PragmaticSlotsCard from '../components/SlotCards/pragmaticSlotCard';
// import SlotOneCard from './SlotCards/SlotOneCard';
// import SlotTwoCard from './SlotCards/SlotTwoCard';
// import SlotThreeCard from './SlotCards/SlotThreeCard';
// import SlotFourCard from './SlotCards/SlotFourCard';
// import SlotFiveCard from './SlotCards/SlotFiveCard';
// import SlotSixCard from './SlotCards/SlotSixCard';
// import SlotSevenCard from './SlotCards/SlotSevenCard';
// import SlotEightCard from './SlotCards/SlotEightCard';
// import SlotNineCard from './SlotCards/SlotNineCard';
// import SlotTenCard from './SlotCards/SlotTenCard';
// import DiceCard from './DiceCard';
// import MinesCard from './MinesCard';
// import BlackjackCard from './BlackJackCard';
// import CrashCard from './CrashCard';
// import KenoCard from './KenoCard';
// import BaccaratCard from './BaccaratCard';
// import GameElements from '../components/GameElements';
import { faCircleDollarToSlot } from '@fortawesome/free-solid-svg-icons';

const gameIds = [
  'vs20olympx',
  'vs20olympgate',
  'vs20sugarrushx',
  'vswaysmfreya',
  'vs20fruitswx',
  'vs20starlightx',
  'vswayslions',
  'vs20fruitsw',
  'vs10bbxext',
  'vs20starlight',
  'vswayspowzeus',
  'vswaysmahwin2',
  'vs20sugarrush',
  'vs25pandagold',
  'vswaysrabbits',
  'vs15godsofwar',
  'vs20gatotx',
  'vswaysdogs',
  'vs20procountx',
  'vswayswbounty',
  'vs20tweethouse',
  'vs40wildwest',
  'vswaysbufking',
  'vswaysanime',
  'vs20sbxmas',
  'vs20pbonanza',
  'vswaysrhino',
  'vswaysrockblst',
  'vswaysfreezet',
  'vs5himalaw',
  'vs20doghouse',
  'vs25checaishen',
  'vswaysmegareel',
  'vs20saiman',
  'vs20amuleteg',
  'vswayshammthor',
  'vs5joker',
  'vs25pandatemple',
  'vs12tropicana',
  'vs20rujakbnz',
  'vs20portals',
  'vs5aztecgems',
  'vswayspizza',
  'vs15seoultrain',
  'vs20procount',
  'vswaysstampede',
  'vs12bbb',
  'vs15samurai4',
];

const shuffleArray = (array) => {
  return array
    .map((item) => ({ item, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ item }) => item);
};

const SimilarSlotsCarousel = () => {
  // const originalCards = [
  //   <SlotEightCard />,
  //   <SlotNineCard />,
  //   <SlotFourCard />,
  //   <SlotOneCard />,
  //   <SlotFiveCard />,
  //   <SlotSixCard />,
  //   <SlotTenCard />,
  //   <SlotTwoCard />,
  //   <SlotThreeCard />,
  //   <SlotSevenCard />,
  //   <DiceCard />,
  //   <MinesCard />,
  //   <BlackjackCard />,
  //   <CrashCard />,
  //   <KenoCard />,
  //   <BaccaratCard />,
  //   <DiceCard />,
  //   <MinesCard />,
  //   <BlackjackCard />,
  //   <CrashCard />,
  // ];

  const [shuffledCards, setShuffledCards] = useState([]);

  useEffect(() => {
    setShuffledCards(shuffleArray(gameIds));
  }, []);

  // Shuffle cards on component mount
  // useEffect(() => {
  //   const shuffleArray = (array) => {
  //     const shuffled = [...array];
  //     for (let i = shuffled.length - 1; i > 0; i--) {
  //       const j = Math.floor(Math.random() * (i + 1));
  //       [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  //     }
  //     return shuffled;
  //   };

  //   setShuffledCards(shuffleArray(originalCards));
  // }, []);

  const recommendedCards = shuffledCards.map((gameId) => (
    <PragmaticSlotsCard key={gameId} gameId={gameId} />
  ));

  return <GameCarousel cards={recommendedCards} title="Similar Slots" icon={faCircleDollarToSlot} />;
};

export default SimilarSlotsCarousel;