import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import './LegalTexts.css';

const LegalTexts = () => {
  return (
    <div className="main">
      <div className="legal-container">
        <div className="legal-header">
          <h1>Responsible Gaming</h1>
          <p>Last updated: April 8, 2023</p>
        </div>
        <div className="legal-content">
          <h2>Interpretation</h2>
          <p>
            The words with the initial letter capitalized have meanings defined under
            the following conditions. The following definitions shall have the same
            meaning regardless of whether they appear in singular or in plural.
          </p>
          <h3>Definitions</h3>
          <p>
            <strong>Account:</strong> Means a unique account created for You to access
            our Service or parts of our Service.
          </p>
          <p>
            <strong>Responsible Gaming:</strong> Responsible Gambling means
            entertainment and fun while acknowledging that gambling may have negative
            side effects. In the medical science, it is known as pathological gambling,
            a recognized serious sickness. Our commitment is to support users in maintaining
            control and avoiding negative side effects from gambling.
          </p>
          <h3>Support</h3>
          <p>
            If you need help or have questions, you can contact our support team at any time, via <FontAwesomeIcon icon={faEnvelope} /> <a href="mailto:support@rainbet.com"> support@roulo.com</a>.
          </p>
          {/* <p>
            <FontAwesomeIcon icon={faEnvelope} />
            <a href="mailto:support@rainbet.com"> support@roulo.com</a>
          </p> */}
          <p>
            Helpful links for responsible gaming and gambling addiction:
          </p>
          <ul>
            <li>
              <a href="https://www.begambleaware.org/gambling-problems/do-i-have-a-gambling-problem/" target="_blank" rel="noopener noreferrer">
                Self-Test: Do I Have a Gambling Problem?
              </a>
            </li>
            <li>
              <a href="https://www.begambleaware.org/safer-gambling/" target="_blank" rel="noopener noreferrer">
                Safer Gambling Resources
              </a>
            </li>
          </ul>
          <h3>Tips for Responsible Gambling</h3>
          <p>
            We recommend the following tips to help keep your gambling fun and safe:
          </p>
          <ul>
            <li>Set a budget before you start to gamble and stick to it.</li>
            <li>Only play with money you can afford to lose.</li>
            <li>Set a time limit on your gambling sessions.</li>
            <li>Take regular breaks from gambling.</li>
            <li>Never chase your losses, and remember that gambling should never be seen as a way to make money.</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default LegalTexts;
