import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useLoading } from '../context/LoadingContext';

const RouteChangeHandler = () => {
  const location = useLocation();
  const { setIsLoading } = useLoading();

  useEffect(() => {
    const handleRouteChangeStart = () => {
      setIsLoading(true); // Start loading
    };

    const handleRouteChangeComplete = () => {
      setTimeout(() => setIsLoading(false), 500); // Stop loading (simulate delay if needed)
    };

    handleRouteChangeStart(); // Start loading on location change
    handleRouteChangeComplete(); // Stop loading after render

    return () => {
      handleRouteChangeComplete(); // Cleanup if necessary
    };
  }, [location]);

  return null;
};

export default RouteChangeHandler;
