import React, { useState } from 'react';
import axios from 'axios';

function SecuritySettings() {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');

  const handleChangePassword = async () => {
    if (newPassword !== confirmPassword) {
      setMessage('Passwords do not match!');
      return;
    }

    try {
      const response = await axios.post(
        'https://backend-service-643728542364.southamerica-east1.run.app/api/users/change-password',
        { newPassword },
        { withCredentials: true } // Include cookies for authentication
      );
      setMessage(response.data.message || 'Password changed successfully!');
    } catch (error) {
      console.error('Error changing password:', error);
      setMessage(error.response?.data?.message || 'Failed to change password. Please try again.');
    }
  };

  return (
    <div className="settings-section">
      <h2>Security</h2>
      <div className="settings-field">
        <label className="security-settings-label">Change Password</label>
        <input
          className="security-settings-input"
          type="password"
          placeholder="New Password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <input
          className="security-settings-input"
          type="password"
          placeholder="Confirm New Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <button className="security-btn" onClick={handleChangePassword}>
          Change Password
        </button>
        {message && <p>{message}</p>}
      </div>

      {/* <div className="settings-field">
        <label>Two-Factor Authentication</label>
        <p>Enabling two-factor authentication will require a code when updating or viewing sensitive information. Need Help? <a href="#">Read our 2FA Guide</a></p>
        <button className="security-btn">Enable 2FA</button>
      </div> */}

      {/* <div className="settings-field">
        <label>Multi-Device Logout</label>
        <p>If you think your account may have been compromised, reset your password and then hit the button below to end all current active sessions, including this one.</p>
        <div className="multi-device-buttons">
          <button className="security-btn">View Recent Sessions</button>
          <button className="security-btn logout-btn">Logout Everywhere</button>
        </div>
      </div> */}
    </div>
  );
}

export default SecuritySettings;
