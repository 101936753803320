import React, { useState } from 'react';
import './GameDescCard.css'; // Make sure to adjust styles accordingly

const GameDetails = ({ gameTitle, totalBets, gameDescription, bigWins, luckyWins, gameImageUrl }) => {
    const [activeTab, setActiveTab] = useState('bigWins');
    // const gameDescription = "";

    return (
        <div className="big-wins-container">
            <div className="game-header">
                <div className="game-info">
                    {gameImageUrl && <img src={gameImageUrl} alt="Game Img" className="game-image" />}
                    <div className="game-details">
                        <h2 className="game-title">{gameTitle}</h2>
                        <p className="game-bets">Total Bets: <span className="bets-value">{totalBets.toLocaleString()}</span></p>
                    </div>
                </div>

                <div className="tabs">
                    <button className={`tab-btn ${activeTab === 'bigWins' ? 'active' : ''}`} onClick={() => setActiveTab('bigWins')}>Big Wins</button>
                    <button className={`tab-btn ${activeTab === 'luckyWins' ? 'active' : ''}`} onClick={() => setActiveTab('luckyWins')}>Lucky Wins</button>
                    <button className={`tab-btn ${activeTab === 'description' ? 'active' : ''}`} onClick={() => setActiveTab('description')}>Description</button>
                </div>
            </div>

            <div className="tab-content">
                {activeTab === 'bigWins' && (
                    <div>
                        <div className="win-header">
                            <span>#</span>
                            <span>User</span>
                            <span>Bet Amount</span>
                            <span>Multiplier</span>
                            <span>Payout</span>
                        </div>
                        <div className="big-wins-list">
                            {bigWins.length > 0 ? (
                                bigWins.map((win, index) => (
                                    <div key={index} className="win-item">
                                        <span>{index + 1}</span>
                                        <span>{win.user}</span>
                                        <span>${win.bet?.toFixed(2) || '0.00'}</span>
                                        <span>{win.multiplier}x</span>
                                        <span>${win.payout?.toFixed(2) || '0.00'}</span>
                                    </div>
                                ))
                            ) : (
                                <p>No big wins to display.</p>
                            )}
                        </div>
                    </div>
                )}

                {activeTab === 'luckyWins' && (
                    <div>
                        <div className="win-header">
                            <span>#</span>
                            <span>User</span>
                            <span>Bet Amount</span>
                            <span>Multiplier</span>
                            <span>Payout</span>
                        </div>
                        <div className="big-wins-list">
                            {luckyWins.length > 0 ? (
                                luckyWins.map((win, index) => (
                                    <div key={index} className="win-item">
                                        <span>{index + 1}</span>
                                        <span>{win.user}</span>
                                        <span>${win.bet?.toFixed(2) || '0.00'}</span>
                                        <span>{win.multiplier}x</span>
                                        <span>${win.payout?.toFixed(2) || '0.00'}</span>
                                    </div>
                                ))
                            ) : (
                                <p>No lucky wins to display.</p>
                            )}
                        </div>
                    </div>
                )}

                {activeTab === 'description' && (
                    <div className="game-description">
                        <p>{gameDescription}</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default GameDetails;
