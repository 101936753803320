import React, { useState, useEffect } from 'react';
import GameElements from '../components/GameElements';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
// import './Slots.css';
import { FaSearch } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins, faHatWizard } from '@fortawesome/free-solid-svg-icons';
import DiceCard from '../components/DiceCard';
import MinesCard from '../components/MinesCard';
import KenoCard from '../components/KenoCard';
import LiveGameFeed from '../components/LiveGameFeed';

function AllGames() {
    const livefeedData = [
        { game: "Roulette", username: "Hidden", betAmount: "0.18", multiplier: "0.00", payout: "0.00" },
        { game: "Baccarat", username: "Hidden", betAmount: "0.18", multiplier: "0.00", payout: "0.00" },
        { game: "Blackjack", username: "Hidden", betAmount: "0.18", multiplier: "0.00", payout: "0.00" },
        { game: "Crash", username: "Hidden", betAmount: "0.18", multiplier: "0.00", payout: "0.00" },
        { game: "50/50 Dice", username: "Hidden", betAmount: "0.18", multiplier: "0.00", payout: "0.00" },
        { game: "Mines", username: "Hidden", betAmount: "0.18", multiplier: "0.00", payout: "0.00" },
    ];

    return (
        <div className="main">
            <div className="slots-page">
                <div className="swiper-container-slots">
                    <div className="header-slots">
                        <h1>All Games</h1>
                    </div>
                    {/* Search Bar Below */}
                    {/* <div className="header-slots">
                        <div className="search-container">
                            <FaSearch className="icon" />
                            <input type="text" className="search-bar" placeholder="Search..." />
                        </div>
                    </div> */}
                    <div className="sub-main">
                        <div className="sub-main-solos">
                            <GameElements />
                            <DiceCard />
                            <MinesCard />
                            <KenoCard />
                            {/* NEED TO ADD SLOT GAMES HERE */}
                        </div>

                    </div>
                </div>
            </div>

            <LiveGameFeed data={livefeedData} />
        </div>
    );
}

export default AllGames;