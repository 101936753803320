import React, { useState, useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import './Profile.css';

const ProfileModal = ({ isOpen, closeModal }) => {
  const { username, wagers, bets } = useContext(AuthContext);
  if (!isOpen) return null;

  return (
    <div className="model-overlay">
      <div className="model-content">
        <div className="profile-header">
          {/* <div className="profile-avatar">
            <div className="avatar-icon">🔷</div>
          </div> */}
          <h2 className="profile-username-heading">{username}</h2>
        </div>

        {/* <div className="profile-rank">
          <div className="rank-section">
            <p>Current Rank</p>
            <h3>Beginner</h3>
          </div>
          <div className="rank-section">
            <p>Wager for Next Rank</p>
            <div className="progress-bar">
              <div className="progress"></div>
            </div>
            <p>Remaining $1,000.00</p>
          </div>
        </div> */}

        <div className="profile-stats">
          <div className="stat-box">
            <p>Wagered</p>
            <h3>${wagers.toFixed(2)}</h3>
          </div>
          <div className="stat-box">
            <p>Total Bets</p>
            <h3>{bets}</h3>
          </div>
          <div className="stat-box">
            <p>Average Wager</p>
            <h3>${(wagers / bets).toFixed(2)}</h3>
          </div>
        </div>

        <button id="deposit-btn" className="proceed-btn" style={{marginTop: 25}} onClick={closeModal}>Close</button>
        </div>
    </div>
  );
};

export default ProfileModal;
