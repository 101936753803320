import React from 'react';
import { Link } from 'react-router-dom';
import './FeatureCards.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGamepad, faDollarSign } from '@fortawesome/free-solid-svg-icons';

const FeatureCards = () => {
    return (
        <div className="feature-cards">
            <Link to="/originals" className="feature-card">
                <div className="card-icon">
                    <FontAwesomeIcon icon={faGamepad} />
                </div>
                <h3>Roulo OG's</h3>
                {/* <p>Experience our classic, in house originals!</p> */}
                <p>Experience our in house originals!</p>
                <div className="card-tags">
                    <span>Plinko</span>
                    <span>Keno</span>
                    <span>Mines</span>
                </div>
            </Link>

            <Link to="/slots" className="feature-card">
                <div className="card-icon">
                    <FontAwesomeIcon icon={faDollarSign} />
                </div>
                <h3>Slot Machines</h3>
                <p>Test your luck on the slots!</p>
                <div className="card-tags">
                    <span>Boo Bonanza</span>
                    <span>Sugar Rush</span>
                    <span>Fruit Party</span>
                </div>
            </Link>
        </div>
    );
};

export default FeatureCards;
