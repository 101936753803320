import React, { useState, useEffect } from 'react';
// import React from 'react';
import './ErrorModal.css';

const ErrorModal = ({ message, onClose, duration = 5000 }) => {

    const [isFadingOut, setIsFadingOut] = useState(false);

    useEffect(() => {
        if (!message) return;

        const fadeTimeout = setTimeout(() => {
            setIsFadingOut(true);
        }, duration - 300); // Start fading out 300ms before the modal disappears

        const closeTimeout = setTimeout(() => {
            onClose();
            setIsFadingOut(false); // Reset fade-out state
        }, duration);

        return () => {
            clearTimeout(fadeTimeout);
            clearTimeout(closeTimeout);
        };
    }, [message, onClose, duration]);

    useEffect(() => {
        if (!message) return;

        const timeout = setTimeout(() => {
            onClose();
        }, duration);

        return () => clearTimeout(timeout);
    }, [message, onClose, duration]);

    if (!message) return null;

    return (
        <div className="error-modal-backdrop">
            <div className={`error-modal ${isFadingOut ? 'fade-out' : ''}`}>
                <h2>Error</h2>
                <p>{message}</p>
                <div className="progress-bar-container">
                    <div className="error-modal-progress-bar"></div>
                </div>
                <button className="error-modal-close-btn" onClick={onClose}>✕</button>
            </div>
        </div>
    );
};

export default ErrorModal;
