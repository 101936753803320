import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App.jsx';

// Remove splash screen after React has mounted
const removeSplashScreen = () => {
  const splash = document.getElementById('splash-screen');
  if (splash) {
    splash.style.opacity = '0'; // Fade out effect
    setTimeout(() => splash.remove(), 500); // Remove the splash after fade-out
  }
};

const root = ReactDOM.createRoot(document.getElementById('root')); 
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

removeSplashScreen(); // Optional callback, React doesn't accept callbacks here like the older API
