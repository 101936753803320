import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom'; // To parse query params
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import ErrorModal from '../components/ErrorModal/ErrorModal';

const ResetPassword = () => {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [searchParams] = useSearchParams(); // Parse query params
    const [showPwd, setShowPwd] = useState(false);
    const [showMatchPwd, setShowMatchPwd] = useState(false);
    const token = searchParams.get('token'); // Get the token from the URL
    const [errorMessage, setErrorMessage] = useState(null); // State for error messages

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (newPassword !== confirmPassword) {
            setMessage('Passwords do not match!');
            return;
        }

        try {
            // Call the reset password API
            const response = await axios.post('https://backend-service-643728542364.southamerica-east1.run.app/api/users/reset-password', {
                token,
                newPassword,
            });

            setMessage(response.data.message || 'Password reset successfully!');
        } catch (error) {
            console.error('Error resetting password:', error);
            const errorMsg =
                error.response?.data?.error || 'An unexpected error occurred.';
            setMessage(errorMsg || 'Failed to reset password. Please try again.');
        }
    };

    return (
        <div className="signin-container reset-password-container">
            <ErrorModal
                message={errorMessage}
                onClose={() => setErrorMessage(null)}
                duration={5000}
            />

            <div className="signin-content">
                <h1 className="signup-head">Reset Your Password</h1>
                {message && <p>{message}</p>}
                <form className="signin-form" onSubmit={handleSubmit}>
                    <div className="form-group input-wrapper">
                        <label className="form-label" htmlFor="identifier">New Password:</label>
                        <input
                            type={showPwd ? "text" : "password"}
                            id="new-password"
                            onChange={(e) => setNewPassword(e.target.value)}
                            value={newPassword}
                            required
                        />
                        <FontAwesomeIcon
                            icon={showPwd ? faEyeSlash : faEye}
                            onClick={() => setShowPwd(!showPwd)}
                            className="resetpw-eye-icon"
                        />
                    </div>
                    <div className="form-group input-wrapper">
                        <label className="form-label" htmlFor="identifier">Confirm Password:</label>
                        <input
                            type={showMatchPwd ? "text" : "password"}
                            id="confirm-password"
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            value={confirmPassword}
                            required
                        />
                        <FontAwesomeIcon
                            icon={showMatchPwd ? faEyeSlash : faEye}
                            onClick={() => setShowMatchPwd(!showMatchPwd)}
                            className="resetpw-eye-icon"
                        />
                    </div>
                    <div className="form-group">
                        <button type="submit" className="signin-button">Reset Password</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ResetPassword;
