import React, { useState, useEffect, useRef, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import RouloChips from './imgs/roulo_chips_blue.png';
import FeatureCards from '../components/FeatureCards';
import RecommendedCarousel from '../components/RecommendedCarousel';
import OriginalsCarousel from '../components/OriginalsCarousel';
import LiveGameFeed from '../components/LiveGameFeed';

function Home() {
  const gameIds = [
    'vs20olympx',
    'vs20olympgate',
    'vs20sugarrushx',
    'vswaysmfreya',
    'vs20fruitswx',
    'vs20starlightx',
    'vswayslions',
    'vs20fruitsw',
    'vs10bbxext',
    'vs20starlight'
  ];

  const location = useLocation();
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  // const [username, setUsername] = useState('');
  const { username, balance } = useContext(AuthContext);
  // const [category, setCategory] = useState('lobby');
  const [isRegisterModelOpen, setIsRegisterModelOpen] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const regModel = queryParams.get('regModel');
    const tab = queryParams.get('tab');

    if (regModel === 'register' && tab === 'register') {
      setIsRegisterModelOpen(true);
    }
  }, [location]);

  const openRegisterModel = () => navigate('/?regModel=register&tab=register');

  // useEffect(() => {
  //   if (location.state?.user) {
  //     setUsername(location.state.user.username);
  //     setShowPopup(true);
  //   }
  // }, [location.state]);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  // Example data for livefeed box
  const livefeedData = [
    { game: "Roulette", username: "Hidden", betAmount: "0.18", multiplier: "0.00", payout: "0.00" },
    { game: "Baccarat", username: "Hidden", betAmount: "0.18", multiplier: "0.00", payout: "0.00" },
    { game: "Blackjack", username: "Hidden", betAmount: "0.18", multiplier: "0.00", payout: "0.00" },
    { game: "Crash", username: "Hidden", betAmount: "0.18", multiplier: "0.00", payout: "0.00" },
    { game: "50/50 Dice", username: "Hidden", betAmount: "0.18", multiplier: "0.00", payout: "0.00" },
    { game: "Mines", username: "Hidden", betAmount: "0.18", multiplier: "0.00", payout: "0.00" },
  ];

  return (
    <div className="main">
      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <h2>Welcome, {username}!</h2>
            <p>Enjoy the website!</p>
            <button onClick={handleClosePopup}>OK</button>
          </div>
        </div>
      )}
      <div className="swiper-container">
        <div className="hero-banner">
          <div className="hero-text">
            {username ? (
              <>
                <h1>Welcome back, {username}!</h1>
                <p>Current balance: ${balance.toFixed(2)}</p> {/* Replace with actual balance */}
                <a href="/?model=profile&tab=profile" className="btn-reg hero-profile-btn">View Profile</a>
              </>
            ) : (
              <>
                <h1>Welcome to the Best Crypto Casino</h1>
                <p>Luck is just a spin away. Sign up now for free spins and bonuses.</p>
                <a onClick={openRegisterModel} className="btn-reg hero-reg-btn">Register</a>
              </>
            )}
          </div>
          <img src={RouloChips} alt="Roulo Casino Img" className="hero-image" />
        </div>

        <FeatureCards />

        <RecommendedCarousel />
        <OriginalsCarousel />
      </div>

      <LiveGameFeed data={livefeedData} />
    </div>
  );
}

export default Home;