import React, { useState, useContext, useEffect, useRef } from 'react';
import axios from 'axios';
import './Dice.css';
import ErrorModal from '../components/ErrorModal/ErrorModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleDot, faDiceD6, faVolumeUp, faVolumeMute } from '@fortawesome/free-solid-svg-icons';
import tickSoundFile from '../pages/game_sounds/Dice/Dice_slider1.mp3'; // Path to your tick sound
import dingSoundFile from '../pages/game_sounds/Dice/Dice_Win.mp3'; // Path to your ding sound
import lossSoundFile from '../pages/game_sounds/Keno/Keno_Wrong.mp3'; // Path to your ding sound
import { AuthContext } from '../context/AuthContext';
// import SignInModal from './SignIn';
import GameDescCard from '../components/GameDescCard';
import gameImg from '../components/img/dice_final.png'

// const DiceGame = ({ openSignInModal }) => {
const DiceGame = () => {
  const { balance, bonus, selectedBalanceType, setSelectedBalanceType, betMax } = useContext(AuthContext); // Assuming `username` determines if the user is logged in
  const [amount, setAmount] = useState(0);
  const [multiplier, setMultiplier] = useState(0);
  const [winChance, setWinChance] = useState(50);
  const [rollOver, setRollOver] = useState(50.50);
  const [winnings, setWinnings] = useState(2.0);
  const [sliderValue, setSliderValue] = useState(50.50);
  const [loading, setLoading] = useState(false);  // To handle loading state
  const [errorMessage, setErrorMessage] = useState(null); // State for error messages
  const [errorKey, setErrorKey] = useState(0); // Unique key for resetting
  const [rollOutcome, setRollOutcome] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false); // Track whether a game is running
  const tickSound = new Audio(tickSoundFile);
  const dingSound = new Audio(dingSoundFile);
  const lossSound = new Audio(lossSoundFile);
  const [isMuted, setIsMuted] = useState(false);
  const isMutedRef = useRef(isMuted);
  const tickSoundRef = useRef(tickSound);
  const lastPlayedStep = useRef(null); // Stores last slider position to track changes
  const activeTickSounds = useRef([]); // Tracks active tick sounds to stop them later

  /* MANUAL OR AUTOPLAY CONSTS */
  const [mode, setMode] = useState('Manual'); // 'Manual' or 'Auto'
  const [isAutoPlaying, setIsAutoPlaying] = useState(false);
  const isAutoPlayingRef = useRef(false);
  const [numberOfGames, setNumberOfGames] = useState(0); // Tracks the number of autoplay games
  // const [winAdjustment, setWinAdjustment] = useState('Reset'); // Tracks win adjustment strategy (e.g., Reset or Increase)
  // const [winAdjustmentValue, setWinAdjustmentValue] = useState(0); // Percentage increase on win
  // const [lossAdjustment, setLossAdjustment] = useState('Reset'); // Tracks loss adjustment strategy (e.g., Reset or Increase)
  // const [lossAdjustmentValue, setLossAdjustmentValue] = useState(0); // Percentage increase on loss

  dingSound.volume = 0.5;
  lossSound.volume = 0.7;
  tickSound.volume = 0.7;

  let tickSoundPlaying = false; // Track whether the sound is actively playing
  // let tickSoundTimeout;

  useEffect(() => {
    isMutedRef.current = isMuted;
  }, [isMuted]);

  const playSound = (sound) => {
    if (!isMutedRef.current) {
      sound.currentTime = 0; // Reset sound playback
      sound.play();
    }
  };

  const handleSliderChange = (e) => {
    let value = parseFloat(e.target.value);
    value = Math.max(5, Math.min(98, value)); // Clamp value between 0.01 and 99.99
    const newWinChance = (100 - value).toFixed(2);
    const newRollOver = (1 * value).toFixed(2);
    const calculatedMultiplier = 96 / (100 - value);
    // const newWinnings = (96 / winChance).toFixed(4);
    setSliderValue(value);
    setWinChance(newWinChance);
    setRollOver(newRollOver);
    setWinnings(calculatedMultiplier.toFixed(4));

    const potentialPayout = ((calculatedMultiplier * amount)).toFixed(2);
    setMultiplier(potentialPayout); // Save the multiplier
    updateSliderBackground(value);

    // Check if the slider moved to a new step before playing sound
    if (lastPlayedStep.current !== Math.round(value)) {
      lastPlayedStep.current = Math.round(value); // Update last step

      tickSound.currentTime = 0;
      tickSound.play().catch(err => console.error("Audio play failed:", err));

      // Store active sounds to stop them later
      activeTickSounds.current.push(tickSound);
    }
  };

  const handleSliderRelease = () => {
    // Stop and remove all active tick sounds
    activeTickSounds.current.forEach((tick) => {
      tick.pause();
      tick.currentTime = 0;
    });

    activeTickSounds.current = []; // Clear active sounds
    lastPlayedStep.current = null; // Reset last step
  };

  const updateSliderBackground = (value) => {
    const slider = document.getElementById('dice-slider');
    slider.style.background = `linear-gradient(to right, #ff0000 ${value}%, #00ff00 ${value}%)`;
  };

  useEffect(() => {
    updateSliderBackground(sliderValue); // Set the initial gradient when the component mounts
  }, []); // Empty dependency array ensures this runs once

  const handleAmountChange = (e) => {
    const value = parseFloat(e.target.value);
    if (!isNaN(value) && value >= 0) {
      setAmount(value);

      // Recalculate potential payout dynamically
      const calculatedMultiplier = 96 / (100 - sliderValue); // Ensure this matches your slider logic
      const potentialPayout = ((calculatedMultiplier * value)).toFixed(2); // Remove subtraction of value if you want Total Payout instead of Net Gain
      setMultiplier(potentialPayout); // Update multiplier for net gain
    } else if (e.target.value === '') {
      setAmount(''); // Allow clearing the input
    }
  };

  const updatePayout = (newAmount) => {
    setAmount(newAmount);
    const calculatedMultiplier = 96 / (100 - sliderValue);
    setMultiplier((calculatedMultiplier * newAmount).toFixed(2)); // Update Payout
  };

  const betHalfAmount = () => {
    updatePayout((amount / 2).toFixed(2));
  };

  const betDoubleAmount = () => {
    updatePayout((amount * 2).toFixed(2));
  };

  const handlePlay = async () => {
    // if (!isLoggedIn) {
    //   // If user is not logged in, show the sign-in modal
    //   setShowSignInModal(true);
    //   return;
    // }

    if (isPlaying) return; // Prevent multiple clicks
    setIsPlaying(true); // Lock the slider

    setLoading(true);  // Start loading
    try {
      const response = await axios.post(
        'https://backend-service-643728542364.southamerica-east1.run.app/api/dice/play/dice',
        {
          betAmount: amount,
          selectedNumber: sliderValue,
          winChance
        },
        { withCredentials: true }  // Ensures cookies (JWT token) are sent with the request
      );

      const { win, winAmount, newBalance, rollOutcome } = response.data;
      setRollOutcome({
        value: rollOutcome,
        color: win ? '#00ff00' : '#ff0000', // Green for win, red for loss
      });

      playSound(win ? dingSound : lossSound);
      // setMultiplier(winAmount);

      // Update the UI with the result
      // setBalance(newBalance);  // Update the balance state
    } catch (error) {
      console.error('Error placing bet:', error);
      const errorMsg =
        error.response?.data?.error || 'An unexpected error occurred.';
      setErrorMessage(errorMsg); // Set error message
      // alert(`Error placing bet: ${error.response ? error.response.data.error : error.message}`);
    }
    setLoading(false);  // End loading
    setIsPlaying(false); // Unlock the slider
  };

  const triggerError = (message) => {
    setErrorMessage(''); // Clear existing message
    setTimeout(() => {
      setErrorMessage(message); // Set new error message
      setErrorKey((prevKey) => prevKey + 1); // Increment key to force re-render
    }, 0); // Ensure state updates happen in the correct order
  };

  const handleAutoPlay = async () => {
    if (isAutoPlayingRef.current) {
      setIsAutoPlaying(false);
      isAutoPlayingRef.current = false; // Ensure ref is reset immediately
      return;
    }

    setIsAutoPlaying(true);
    setLoading(true); // Indicate that autoplay is running
    isAutoPlayingRef.current = true; // Start autoplay

    for (let i = 0; i < numberOfGames; i++) {
      if (!isAutoPlayingRef.current) break; // Check if autoplay is stopped

      try {
        await handlePlay(); // Assuming handlePlay places a single bet
      } catch (error) {
        console.error('Error during autoplay:', error);
      }
      await new Promise(resolve => setTimeout(resolve, 200)); // 🔹 Small delay between rounds
    };
    setIsAutoPlaying(false);
    isAutoPlayingRef.current = false; // Ensure autoplay is fully stopped
    setLoading(false); // Indicate that autoplay is stopped
  }

  const betMaxAmount = () => {
    let maxBet = selectedBalanceType === "primary" ? Math.min(balance, 500) : betMax;
    updatePayout(maxBet);
    // if (selectedBalanceType === "primary") {
    //   if (balance > 500) {
    //     setAmount(500);
    //   } else {
    //     setAmount(balance);
    //   }
    // } else if (selectedBalanceType === "promotional") {
    //   setAmount(betMax);
    // }
  };

  return (
    <div className="main">
      {errorMessage && (
        <ErrorModal
          key={errorKey} // Unique key ensures reset
          message={errorMessage}
          onClose={() => setErrorMessage('')}
        />
      )}
      {/* <SignInModal
        isOpen={showSignInModal}
        onClose={() => setShowSignInModal(false)} // Close sign-in modal
        onSignInSuccess={() => {
          setIsLoggedIn(true); // Update login status upon successful sign-in
          setShowSignInModal(false); // Close the modal
        }}
      /> */}

      <div className="game-container dice-game-container">
        <div className="side-panel dice-side-panel">
          {/* <h2 className="game-head">Controls</h2> */}
          <div className="mode-tabs">
            <button
              className={`mode-tab ${mode === 'Manual' ? 'active' : ''}`}
              onClick={() => setMode('Manual')}
              disabled={isPlaying || isAutoPlaying}
            >
              Manual
            </button>
            <button
              className={`mode-tab ${mode === 'Auto' ? 'active' : ''}`}
              onClick={() => setMode('Auto')}
              disabled={isPlaying || isAutoPlaying}
            >
              Auto
            </button>
          </div>

          {mode === 'Manual' && (
            <div className="manual-controls">
              <div className="side-inputs">
                <label>Amount:</label>
                <div className="amount-container">
                  <span className="amount-icon">$</span>
                  <input
                    type="number"
                    value={amount}
                    onFocus={(e) => e.target.select()}  // Select all text when clicking
                    onChange={handleAmountChange}
                    onBlur={() => {
                      setAmount((prev) => (prev === '' ? '0.00' : parseFloat(prev).toFixed(2)));
                    }}
                    className="amount-input"
                    step="0.01"
                    min="0"
                    disabled={isPlaying}
                  />
                  <div className="amount-buttons">
                    <button
                      className="amount-button"
                      onClick={betHalfAmount}
                      disabled={isPlaying}
                    >
                      ½
                    </button>
                    <button
                      className="amount-button"
                      onClick={betDoubleAmount}
                      disabled={isPlaying}
                    >
                      2×
                    </button>
                    <button
                      className="amount-button"
                      onClick={betMaxAmount}
                      disabled={isPlaying}
                    >
                      MAX
                    </button>
                  </div>
                </div>
              </div>
              <button id="play-button" onClick={handlePlay} disabled={loading || amount === 0}>
                {loading ? 'Playing...' : 'Play'}
              </button>
            </div>
          )}

          {mode === 'Auto' && (
            <div className="auto-controls">
              <div className="side-inputs">
                <label>Amount:</label>
                <div className="amount-container">
                  <span className="amount-icon">$</span>
                  <input
                    type="number"
                    value={amount}
                    onFocus={(e) => e.target.select()}  // Select all text when clicking
                    onChange={handleAmountChange} // Use the external function here
                    className="amount-input"
                    onBlur={() => {
                      setAmount((prev) => (prev === '' ? '0.00' : parseFloat(prev).toFixed(2)));
                    }}
                    min="0"
                    step="0.01"
                    disabled={isAutoPlaying}
                  />
                  <div className="amount-buttons">
                    <button
                      className="amount-button"
                      onClick={betHalfAmount}
                      disabled={isAutoPlaying}
                    >
                      ½
                    </button>
                    <button
                      className="amount-button"
                      onClick={betDoubleAmount}
                      disabled={isAutoPlaying}
                    >
                      2×
                    </button>
                    <button
                      className="amount-button"
                      onClick={betMaxAmount}
                      disabled={isAutoPlaying}
                    >
                      MAX
                    </button>
                  </div>
                </div>
                <label>Number of Games:</label>
                <input
                  type="number"
                  min="0"
                  value={numberOfGames}
                  onChange={(e) => setNumberOfGames(e.target.value)}
                  disabled={isAutoPlaying}
                />
              </div>

              {/* <div className="side-inputs adjustment-section">
                <label for="win-adjustment">On Win:</label>
                <div className="auto-adjustments">
                  <input
                    type="number"
                    value={winAdjustmentValue}
                    onChange={(e) => setWinAdjustmentValue(e.target.value)}
                  />
                  <span>%</span>
                  <button className="amount-button reset-button" onClick={() => setWinAdjustment('Reset')}>Reset</button>
                </div>
              </div>

              <div className="side-inputs adjustment-section">
                <label>On Loss:</label>
                <div className="auto-adjustments">
                  <input
                    type="number"
                    value={lossAdjustmentValue}
                    onChange={(e) => setLossAdjustmentValue(e.target.value)}
                  />
                  <span>%</span>
                  <button className="amount-button reset-button" onClick={() => setLossAdjustment('Reset')}>Reset</button>
                </div>
              </div> */}

              <button id="play-button" onClick={handleAutoPlay} disabled={numberOfGames === 0 || amount === 0}>
                {isAutoPlaying ? 'Stop Autoplay' : 'Start Autoplay'}
              </button>
            </div>
          )}

          <div className="payout-container">
            <h3 className="payout-heading">Payout on Win</h3>
            <div className="payout-value">
              <span className="payout-currency">$</span>
              {multiplier}
            </div>
          </div>

          <div className="side-panel" style={{ position: 'relative' }}>
            <button
              className="mute-button dice-mute-button"
              onClick={() => setIsMuted((prev) => !prev)}
            >
              <FontAwesomeIcon icon={isMuted ? faVolumeMute : faVolumeUp} />
            </button>
          </div>

        </div>

        <div className="game-panel dice-game-panel">
          <div className="slider-controls">
            <div className="slider-labels">
              <span>0</span>
              <span style={{ marginLeft: 15 }}>25</span>
              <span>50</span>
              <span>75</span>
              <span>100</span>
            </div>
            <div className="slider-container">
              <input
                id="dice-slider"
                type="range"
                min="0"
                max="100"
                value={sliderValue}
                onChange={handleSliderChange}
                onMouseUp={handleSliderRelease} // Stops sound immediately when letting go
                onTouchEnd={handleSliderRelease} // Mobile support
                className="dice-slider"
                disabled={isPlaying || isAutoPlaying} // Lock slider when a game is in progress
              />
              {rollOutcome !== null && (
                <div
                  className="roll-outcome-display"
                  style={{
                    left: `calc(${rollOutcome.value}% - ${rollOutcome.value < 20 ? '-20px' :
                      rollOutcome.value < 30 ? '-15px' :
                        rollOutcome.value < 40 ? '-10px' :
                          rollOutcome.value < 60 ? '0px' :
                            rollOutcome.value < 80 ? '10px' :
                              rollOutcome.value < 90 ? '5px' :
                                '25px'
                      })`,
                    transform: "translateX(-50%)",  // Center align
                    color: rollOutcome.color, // Dynamically set the color
                  }}
                >
                  <FontAwesomeIcon icon={faDiceD6} className="outcome-icon" />
                  <span className="roll-value" style={{ color: rollOutcome.color }}>
                    {rollOutcome.value.toFixed(2)} {/* Access the value property */}
                  </span>
                </div>
              )}
            </div>

            <div className="winning-odds">
              <div className="wo-item">
                <label>Multiplier:</label>
                <input className="wo-item-input" type="number" value={winnings} readOnly />
              </div>
              <div className="wo-item">
                <label>Roll Over:</label>
                <input className="wo-item-input" type="number" value={rollOver} readOnly />
              </div>
              <div className="wo-item">
                <label>Win Chance:</label>
                <input className="wo-item-input" type="number" value={winChance} readOnly />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* EXAMPLE OF PASSING IN GAME DETAILS */}
      <GameDescCard
        gameTitle="Dice"
        totalBets={6896668}
        gameDescription="Step into the thrilling world of Dice, a fast-paced betting game where strategy and luck collide! In this game, you choose a target number and place your bet—then roll the dice to see if you win. The closer your number is to the winning range, the higher your chances of success, but lower payouts. Riskier bets offer greater rewards, making every roll an exciting challenge!"
        bigWins={[
          { user: 'Player1', bet: 2000, multiplier: 292.94, payout: 585880 },
          { user: 'Player2', bet: 989.59, multiplier: 292.94, payout: 289890.49 },
          { user: 'Player3', bet: 1500, multiplier: 150.5, payout: 225750 },
        ]}
        luckyWins={[
          { user: 'Player3', bet: 1500, multiplier: 150.5, payout: 225750 },
          { user: 'Player2', bet: 989.59, multiplier: 292.94, payout: 289890.49 },
          { user: 'Player1', bet: 2000, multiplier: 292.94, payout: 585880 },
        ]}
        gameImageUrl={gameImg}
      />
    </div>
  );
};

export default DiceGame;