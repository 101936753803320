import React from 'react';
import './LegalTexts.css';

const TermsAndConditions = () => {
    return (
        <div className="main">
            <div className="legal-container">
                <div className="legal-header">
                    <h1>Terms and Conditions</h1>
                    <p>Last updated: [Insert Date]</p>
                </div>

                <div className="legal-content">
                    <section>
                        <h2 className="legal-subheader">1. Introduction</h2>
                        <p>
                            These Terms and Conditions ("Terms") govern your use of [Your Company Name]'s website and services (collectively, the "Service"). By accessing or using the Service, you agree to be bound by these Terms. If you do not agree, please refrain from using the Service.
                        </p>
                    </section>

                    <section>
                        <h2 className="legal-subheader">2. General Terms</h2>
                        <p>
                            We reserve the right to modify these Terms at any time. Changes will be effective immediately upon posting on this page. Your continued use of the Service after changes are posted constitutes your acceptance of the revised Terms.
                        </p>
                    </section>

                    <section>
                        <h2 className="legal-subheader">3. User Obligations</h2>
                        <ul className="legal-list">
                            <li>You must be at least 18 years old or the legal age in your jurisdiction to use the Service.</li>
                            <li>You agree to provide accurate and complete information during registration and maintain the confidentiality of your account credentials.</li>
                            <li>You are responsible for all activities that occur under your account.</li>
                            <li>You agree not to use the Service for any unlawful or prohibited activities.</li>
                        </ul>
                    </section>

                    <section>
                        <h2 className="legal-subheader">4. Prohibited Uses</h2>
                        <ul className="legal-list">
                            <li>Engaging in fraudulent activities.</li>
                            <li>Transmitting harmful or malicious software.</li>
                            <li>Infringing on intellectual property rights.</li>
                            <li>Collecting personal information of others without consent.</li>
                        </ul>
                    </section>

                    <section>
                        <h2 className="legal-subheader">5. Account Termination</h2>
                        <p>
                            We reserve the right to suspend or terminate your account at our discretion, without prior notice, if you violate these Terms or engage in conduct detrimental to the Service.
                        </p>
                    </section>

                    <section>
                        <h2 className="legal-subheader">6. Limitation of Liability</h2>
                        <p>
                            To the fullest extent permitted by law, [Your Company Name] shall not be liable for any indirect, incidental, or consequential damages arising from your use of the Service.
                        </p>
                    </section>

                    <section>
                        <h2 className="legal-subheader">7. Governing Law</h2>
                        <p>
                            These Terms are governed by the laws of [Your Jurisdiction]. Any disputes arising from these Terms shall be resolved in the courts of [Your Jurisdiction].
                        </p>
                    </section>

                    <section>
                        <h2 className="legal-subheader">8. Contact Information</h2>
                        <p>
                            For any questions or concerns regarding these Terms, please contact us at <a href="mailto:support@roulo.com">support@roulo.com</a>.
                        </p>
                    </section>
                </div>
            </div>
        </div>
    );
};

export default TermsAndConditions;
