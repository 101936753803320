import React, { useState, useEffect, useRef, useContext } from 'react';
import './Game.css';
import './Keno.css';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';
import ErrorModal from '../components/ErrorModal/ErrorModal';
import KenoGem from './imgs/keno_gem.png'
import matchSoundFile from '../pages/game_sounds/Keno/Keno_Gem.mp3'; // Path to your tick sound
import missSoundFile from '../pages/game_sounds/Keno/Keno_Wrong2.mp3'; // Path to your ding sound
import selectSoundFile from '../pages/game_sounds/Keno/Keno_Selection.mp3'; // Path to your ding sound
import GameDescCard from '../components/GameDescCard';
import gameImg from '../components/img/keno_final.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeUp, faVolumeMute } from '@fortawesome/free-solid-svg-icons';

function Keno() {
  const { balance, bonus, selectedBalanceType, betMax } = useContext(AuthContext); // Assuming `username` determines if the user is logged in
  const [selectedNumbers, setSelectedNumbers] = useState([]);
  const [amount, setAmount] = useState(0);
  const [risk, setRisk] = useState('Medium');
  const [result, setResult] = useState([]); // Result state for yellow tiles
  const [hits, setHits] = useState(0); // Track hits
  const [revealedResult, setRevealedResult] = useState([]); // Numbers revealed one at a time
  const [winnings, setWinnings] = useState(0); // Track winnings
  const [probabilities, setProbabilities] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null); // State for error messages
  const missSound = new Audio(missSoundFile);
  const matchSound = new Audio(matchSoundFile);
  const selectSound = new Audio(selectSoundFile);
  const [isMuted, setIsMuted] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false); // Track whether a game is running
  const [isAutoPicking, setIsAutoPicking] = useState(false); // Track whether auto pick is running
  const isMutedRef = useRef(isMuted);
  const [showWinDisplay, setShowWinDisplay] = useState(false); // Track whether to show win display
  const [isGameActive, setIsGameActive] = useState(false); // Tracks active game

  /* AUTOPLAY CONSTS */
  const [mode, setMode] = useState('Manual'); // 'Manual' or 'Auto'
  const [isAutoPlaying, setIsAutoPlaying] = useState(false); // Track autoplay state
  const isAutoPlayingRef = useRef(false);
  const [isStoppingAutoPlay, setIsStoppingAutoPlay] = useState(false); // Track if stopping is in progress
  const [numberOfGames, setNumberOfGames] = useState(0); // For autoplay
  const [autoResults, setAutoResults] = useState([]); // Store results for autoplay
  const [progress, setProgress] = useState(0); // Track progress as a percentage
  // const [winAdjustment, setWinAdjustment] = useState('Reset'); // Tracks win adjustment strategy (e.g., Reset or Increase)
  // const [winAdjustmentValue, setWinAdjustmentValue] = useState(0); // Percentage increase on win
  // const [lossAdjustment, setLossAdjustment] = useState('Reset'); // Tracks loss adjustment strategy (e.g., Reset or Increase)
  // const [lossAdjustmentValue, setLossAdjustmentValue] = useState(0); // Percentage increase on loss

  matchSound.volume = 0.1;
  missSound.volume = 0.1;
  selectSound.volume = 0.4;

  const maxSelections = 10;

  useEffect(() => {
    isMutedRef.current = isMuted;
  }, [isMuted]);

  const playSound = (sound) => {
    if (!isMutedRef.current) {
      sound.currentTime = 0; // Reset sound playback
      sound.play();
    }
  };

  useEffect(() => {
    if (selectedNumbers.length > 0) {
      calculateProbabilities();
    }
  }, [selectedNumbers, risk]);

  const handleNumberClick = (number) => {
    if (isPlaying || isAutoPicking || (selectedNumbers.length >= maxSelections && !selectedNumbers.includes(number))) {
      return; // Block clicking on tiles when limit is reached
    }

    if (result.length > 0 || revealedResult.length > 0) {
      // If game results exist, reset board first
      setResult([]);
      setRevealedResult([]);
      setHits(0);
      setWinnings(0);
    }

    // if (selectedNumbers.includes(number)) {
    //   setSelectedNumbers(selectedNumbers.filter((n) => n !== number));
    if (selectedNumbers.includes(number)) {
      setSelectedNumbers((prev) => {
        const newSelection = prev.filter((n) => n !== number);
        if (prev.length === 10) {
          // Reset board if a tile is deselected when 10 were selected
          setResult([]);
          setRevealedResult([]);
          setHits(0);
          setWinnings(0);
        }
        return newSelection;
      });
    } else if (selectedNumbers.length < maxSelections) {
      setSelectedNumbers([...selectedNumbers, number]);
    }

    playSound(selectSound); // Play sound on tile selection
  };

  const handlePlayClick = async () => {
    if (isPlaying) return;
    setIsPlaying(true);
    setShowWinDisplay(false);
    setIsGameActive(true); // Set game as active
    setRevealedResult([]); // Clear previous results

    try {
      const response = await axios.post('https://backend-service-643728542364.southamerica-east1.run.app/api/keno/play/keno', {
        selectedNumbers,
        betAmount: amount,
        risk
      }, { withCredentials: true });

      const { outcome, hits, winnings, newBalance } = response.data;
      setResult(outcome);  // Set the result for yellow tiles
      setHits(hits);
      setWinnings(winnings);

      setTimeout(() => {
        setIsPlaying(false);
        if (winnings > 0) setShowWinDisplay(true);
      }, 1600); // Unlock after game ends and show win display if applicable
    } catch (error) {
      console.error('Error playing Keno:', error);
      const errorMsg = error.response?.data?.error || 'An unexpected error occurred.';
      setErrorMessage(errorMsg);
    }
    setTimeout(() => {
      setIsGameActive(false); // Ensure highlights disappear on reset
    }, 30000);

    // Fade out after 2.5 seconds (needs fix if necessary)
    // setTimeout(() => {
    //   setShowWinDisplay(false);
    // }, 2500);
  };

  const handleAutoPick = async () => {
    if (isAutoPicking) return;
    setIsAutoPicking(true);
    setSelectedNumbers([]); // Clear previous selections

    const uniqueNumbers = new Set();
    for (let i = 0; i < maxSelections; i++) {
      await new Promise((resolve) => setTimeout(resolve, 150)); // Delay between picks
      let randomNumber;
      do {
        randomNumber = Math.floor(Math.random() * 40) + 1; // Generate a random number
      } while (uniqueNumbers.has(randomNumber)); // Ensure unique numbers

      uniqueNumbers.add(randomNumber);
      setSelectedNumbers((prev) => [...prev, randomNumber]); // Add to selected numbers
      playSound(selectSound); // Play selection sound
    }

    setIsAutoPicking(false);
  };

  const handleClearTable = () => {
    resetBoard();
  };

  const probabilityTable = {
    Low: {
      1: [0.70, 1.85],
      2: [0.00, 2.00, 3.80],
      3: [0.00, 1.10, 1.38, 26.00],
      4: [0.00, 0.00, 2.20, 7.90, 90.00],
      5: [0.00, 0.00, 1.50, 4.20, 13.00, 300.00],
      6: [0.00, 0.00, 1.10, 2.00, 6.20, 100.00, 700.00],
      7: [0.00, 0.00, 1.10, 1.60, 3.50, 15.00, 225.00, 700.00],
      8: [0.00, 0.00, 1.10, 1.50, 2.00, 5.50, 39.00, 100.00, 800.00],
      9: [0.00, 0.00, 1.10, 1.30, 1.70, 2.50, 7.50, 50.00, 250.00, 1000.00],
      10: [0.00, 0.00, 1.10, 1.20, 1.30, 1.80, 3.50, 13.00, 50.00, 250.00, 1000.00]
    },
    Medium: {
      1: [0.40, 2.75],
      2: [0.00, 1.80, 5.10],
      3: [0.00, 0.00, 2.80, 50.00],
      4: [0.00, 0.00, 1.70, 10.00, 100.00],
      5: [0.00, 0.00, 1.40, 4.00, 14.00, 390.00],
      6: [0.00, 0.00, 0.00, 3.00, 9.00, 180.00, 710.00],
      7: [0.00, 0.00, 0.00, 2.00, 7.00, 30.00, 400.00, 800.00],
      8: [0.00, 0.00, 0.00, 2.00, 4.00, 11.00, 67.00, 400.00, 900.00],
      9: [0.00, 0.00, 0.00, 2.00, 2.50, 5.00, 15.00, 100.00, 500.00, 1000.00],
      10: [0.00, 0.00, 0.00, 1.60, 2.00, 4.00, 7.00, 26.00, 100.00, 500.00, 1000.00]
    },
    High: {
      1: [0.00, 3.96],
      2: [0.00, 0.00, 17.10],
      3: [0.00, 0.00, 0.00, 81.50],
      4: [0.00, 0.00, 0.00, 10.00, 259.00],
      5: [0.00, 0.00, 0.00, 4.50, 48.00, 450.00],
      6: [0.00, 0.00, 0.00, 0.00, 11.00, 350.00, 710.00],
      7: [0.00, 0.00, 0.00, 0.00, 7.00, 90.00, 400.00, 800.00],
      8: [0.00, 0.00, 0.00, 0.00, 5.00, 20.00, 270.00, 600.00, 900.00],
      9: [0.00, 0.00, 0.00, 0.00, 4.00, 11.00, 56.00, 500.00, 800.00, 1000.00],
      10: [0.00, 0.00, 0.00, 0.00, 3.50, 8.00, 13.00, 63.00, 500.00, 800.00, 1000.00]
    }
  };

  const calculateProbabilities = () => {
    if (!probabilityTable[risk] || !probabilityTable[risk][selectedNumbers.length]) {
      setProbabilities([]);
      return;
    }

    const riskProbabilities = probabilityTable[risk][selectedNumbers.length];
    const newProbabilities = riskProbabilities.map((probability, index) => ({
      hits: index,
      probability: `${probability.toFixed(2)}x`
    }));

    setProbabilities(newProbabilities);
  };

  const handleAutoPlay = async () => {
    if (isAutoPlayingRef.current) {
      setIsStoppingAutoPlay(true); // Disable button until the current round finishes
      setIsAutoPlaying(false);
      isAutoPlayingRef.current = false; // Ensure the ref updates
      return; // Stop autoplay
    }

    setIsAutoPlaying(true);
    isAutoPlayingRef.current = true; // Start autoplay
    setProgress(0); // Reset progress
    setAutoResults([]); // Clear previous results
    setShowWinDisplay(false); // Reset the win display before autoplay starts

    for (let i = 0; i < numberOfGames; i++) {
      if (!isAutoPlayingRef.current) break; // Now dynamically checks if autoplay is stopped

      setRevealedResult([]); // Reset revealed results
      setIsGameActive(true); // Ensure the game state resets properly
      setShowWinDisplay(false); // Hide win display at start of each round

      try {
        const response = await axios.post('https://backend-service-643728542364.southamerica-east1.run.app/api/keno/play/keno', {
          selectedNumbers,
          betAmount: amount,
          risk,
        }, { withCredentials: true });

        const { outcome, hits, winnings } = response.data;

        setHits(hits); // Update hits
        setWinnings(winnings); // Update winnings
        await revealResult(outcome); // Reveal numbers one at a time

        // Only show the win display if the player wins something
        if (winnings > 0) {
          setTimeout(() => {
            setShowWinDisplay(true);
          }, 10); // Delay ensures it only appears after results are revealed
        }

        // Save the result of this round
        setAutoResults((prevResults) => [
          ...prevResults,
          { game: i + 1, hits, winnings },
        ]);

        // Update progress bar
        setProgress(((i + 1) / numberOfGames) * 100);

        // Add a buffer for each round
        await new Promise((resolve) => setTimeout(resolve, 300));
      } catch (error) {
        console.error('Error during autoplay:', error);
        setErrorMessage(error.response?.data?.error || 'An unexpected error occurred.');
        break; // Stop autoplay on error
      }
    }
    setIsAutoPlaying(false);
    isAutoPlayingRef.current = false;
    setIsStoppingAutoPlay(false); // Re-enable button after finishing
    setShowWinDisplay(false); // Ensure it disappears when autoplay ends.
  };

  // Function to reset board
  const resetBoard = () => {
    setSelectedNumbers([]);
    setResult([]);
    setRevealedResult([]);
    setHits(0);
    setWinnings(0);
    setIsGameActive(false); // Ensure highlights disappear on reset
  };

  const revealResult = async (numbers) => {
    setRevealedResult([]); // Reset revealed numbers at the start
    setShowWinDisplay(false); // Hide win display at the start of a round

    for (let i = 0; i < numbers.length; i++) {
      await new Promise((resolve) => setTimeout(resolve, 150)); // Delay for each number
      const number = numbers[i];
      setRevealedResult((prev) => [...prev, number]); // Add the next number

      if (selectedNumbers.includes(number)) {
        playSound(matchSound); // Play match sound
      } else {
        playSound(missSound); // Play miss sound
      }
    }

    setTimeout(() => {
      if (winnings > 0) {
        setShowWinDisplay(true); // Show the win display only after numbers are fully revealed
      }
    }, 200); // Small delay to sync timing
  };

  useEffect(() => {
    if (result.length > 0) {
      revealResult(result); // Trigger reveal logic
    }
  }, [result]);

  const calculateDynamicHitsAndWinnings = () => {
    const dynamicHits = revealedResult.filter((num) => selectedNumbers.includes(num)).length;

    // Get the multiplier based on the number of hits
    const multipliers = probabilityTable[risk][selectedNumbers.length];
    const multiplier = multipliers[dynamicHits] || 0; // Default to 0 if no multiplier is defined

    // Calculate winnings based on the multiplier and the bet amount
    const dynamicWinnings = amount * multiplier;

    return { dynamicHits, dynamicWinnings };
  };

  const handleAmountChange = (e) => {
    const value = parseFloat(e.target.value);
    if (!isNaN(value) && value >= 0) {
      setAmount(value);
      if (isPlaying && value > 0) {
        setIsPlaying(false); // Unlock game if amount is updated
      }
    } else if (e.target.value === '') {
      setAmount(''); // Allow clearing the input
    }
  };

  const betMaxAmount = () => {
    if (selectedBalanceType === "primary") {
      if (balance > 500) {
        setAmount(500);
      } else {
        setAmount(balance);
      }
    } else if (selectedBalanceType === "promotional") {
      setAmount(betMax);
    }
  };

  return (
    <div className="main">
      <ErrorModal
        message={errorMessage}
        onClose={() => setErrorMessage(null)}
        duration={5000}
      />
      <div className="game-container">
        <div className="side-panel">
          <div className="mode-tabs">
            <button
              className={`mode-tab ${mode === 'Manual' ? 'active' : ''}`}
              onClick={() => setMode('Manual')}
              disabled={isPlaying || isAutoPlaying}
            >
              Manual
            </button>
            <button
              className={`mode-tab ${mode === 'Auto' ? 'active' : ''}`}
              onClick={() => setMode('Auto')}
              disabled={isPlaying || isAutoPlaying}
            >
              Auto
            </button>
          </div>

          {mode === 'Manual' && (
            <div className="manual-controls">
              <div className="side-inputs" style={{ marginBottom: '5px' }}>
                <label>Amount:</label>
                <div className="amount-container">
                  <span className="amount-icon">$</span>
                  <input
                    type="number"
                    value={amount}
                    onFocus={(e) => e.target.select()}  // Select all text when clicking
                    // onChange={(e) => {
                    //   const value = e.target.value;
                    //   if (value === '') {
                    //     setAmount('');  // Allow empty input for user convenience
                    //   } else if (/^\d*\.?\d*$/.test(value)) {
                    //     setAmount(value);  // Allow valid decimal numbers
                    //   }
                    // }}
                    onChange={handleAmountChange}
                    onBlur={() => {
                      setAmount((prev) => (prev === '' ? '0.00' : parseFloat(prev).toFixed(2)));
                    }}
                    disabled={isPlaying}
                    className="amount-input"
                    step="0.01"
                    min="0" // This prevents decrementing below 0 using the spinner buttons
                  />
                  <div className="amount-buttons">
                    <button
                      className="amount-button"
                      // onClick={() => setAmount((prev) => (prev / 2).toFixed(2))}
                      onClick={() => setAmount((prev) => (prev / 2))}
                      disabled={isPlaying}
                    >
                      ½
                    </button>
                    <button
                      className="amount-button"
                      onClick={() => setAmount((prev) => (prev * 2))}
                      disabled={isPlaying}
                    >
                      2×
                    </button>
                    <button
                      className="amount-button"
                      onClick={betMaxAmount}
                      disabled={isPlaying}
                    >
                      MAX
                    </button>
                  </div>
                </div>
              </div>
              <div className="side-inputs">
                <label>Risk</label>
                <select value={risk} onChange={(e) => setRisk(e.target.value)} disabled={isPlaying}>
                  <option>Low</option>
                  <option>Medium</option>
                  <option>High</option>
                </select>
              </div>
              <div className="side-inputs">
                <div className="auto-clear-btns" id="keno-auto-btns" style={{ width: '116.5%' }}>
                  <button className="auto-pick" onClick={handleAutoPick} disabled={isPlaying || isAutoPicking} style={{ color: isPlaying || isAutoPicking ? "#555" : "" }}>
                    {isAutoPicking ? "Picking..." : "Auto Pick"}
                  </button>
                  <button className="clear-table" onClick={handleClearTable} disabled={isPlaying || isAutoPicking} style={{ color: isPlaying || isAutoPicking ? "#555" : "" }}>
                    Clear Picks
                  </button>
                </div>
              </div>
              <button id="play-button" onClick={handlePlayClick} disabled={amount === 0 || isAutoPicking || isPlaying}>Play</button>
            </div>
          )}

          {mode === 'Auto' && (
            <div className="auto-controls">
              <div className="side-inputs">
                <label>Amount:</label>
                <div className="amount-container">
                  <span className="amount-icon">$</span>
                  <input
                    type="number"
                    value={amount}
                    onFocus={(e) => e.target.select()}  // Select all text when clicking
                    onChange={handleAmountChange}
                    // onChange={(e) => {
                    //   const value = e.target.value;
                    //   if (value === '') {
                    //     setAmount('');  // Allow empty input for user convenience
                    //   } else if (/^\d*\.?\d*$/.test(value)) {
                    //     setAmount(value);  // Allow valid decimal numbers
                    //   }
                    // }}
                    onBlur={() => {
                      setAmount((prev) => (prev === '' ? '0.00' : parseFloat(prev).toFixed(2)));
                    }}
                    disabled={isAutoPlaying}
                    className="amount-input"
                    step="0.01"
                    min="0" // This prevents decrementing below 0 using the spinner buttons
                  />
                  <div className="amount-buttons">
                    <button
                      className="amount-button"
                      // onClick={() => setAmount((prev) => (prev / 2).toFixed(2))}
                      onClick={() => setAmount((prev) => (prev / 2))}
                      disabled={isAutoPlaying}
                    >
                      ½
                    </button>
                    <button
                      className="amount-button"
                      onClick={() => setAmount((prev) => (prev * 2))}
                      disabled={isAutoPlaying}
                    >
                      2×
                    </button>
                    <button
                      className="amount-button"
                      onClick={betMaxAmount}
                      disabled={isAutoPlaying}
                    >
                      MAX
                    </button>
                  </div>
                </div>
              </div>
              <div className="side-inputs">
                <label>Risk</label>
                <select value={risk} onChange={(e) => setRisk(e.target.value)} disabled={isAutoPlaying}>
                  <option>Low</option>
                  <option>Medium</option>
                  <option>High</option>
                </select>
              </div>
              <div className="side-inputs">
                <label>Number of Games:</label>
                <input
                  type="number"
                  value={numberOfGames}
                  onChange={(e) => setNumberOfGames(parseInt(e.target.value, 10))}
                  disabled={isAutoPlaying}
                  min="1"
                />
              </div>
              <div className="side-inputs">
                <div className="auto-clear-btns" id="keno-auto-btns" style={{ width: '116.5%' }}>
                  <button className="auto-pick" onClick={handleAutoPick} disabled={isAutoPicking || isAutoPlaying} style={{ color: isAutoPlaying || isAutoPicking ? "#555" : "" }}>
                    {isAutoPicking ? "Picking..." : "Auto Pick"}
                  </button>
                  <button className="clear-table" onClick={handleClearTable} disabled={isAutoPicking || isAutoPlaying} style={{ color: isAutoPlaying || isAutoPicking ? "#555" : "" }}>
                    Clear Picks
                  </button>
                </div>
              </div>
              <button
                id="play-button"
                onClick={handleAutoPlay}
                disabled={amount === 0 || numberOfGames === 0 || isAutoPicking || isStoppingAutoPlay}
                style={{ color: isStoppingAutoPlay ? "#555" : "" }}
              >
                {isStoppingAutoPlay ? "Stopping..." : isAutoPlaying ? "Stop Autoplay" : "Start Autoplay"}
              </button>
            </div>
          )}

          <div className="side-panel" style={{ position: 'relative' }}>
            <button
              className="mute-button keno-mute-button"
              onClick={() => setIsMuted((prev) => !prev)}
            >
              <FontAwesomeIcon icon={isMuted ? faVolumeMute : faVolumeUp} />
            </button>
          </div>

        </div>

        <div className="game-panel">
          <div className="keno-grid-container">
            <div className={`keno-grid ${isPlaying ? 'playing' : ''}`}>
              {Array.from({ length: 40 }, (_, i) => i + 1).map((number) => {
                const isSelected = selectedNumbers.includes(number); // Check if the player selected the number
                const isRevealed = revealedResult.includes(number); // Check if the number has been revealed
                const isHit = isRevealed && isSelected; // Revealed and selected
                const isMismatch = isRevealed && !isSelected; // Revealed but not selected

                return (
                  <div
                    key={number}
                    className={`keno-number 
                        ${isSelected ? 'selected' : ''} 
                        ${isHit ? 'result' : ''} 
                        ${isMismatch ? 'mismatch' : ''} 
                        ${selectedNumbers.length >= maxSelections && !isSelected ? 'disabled' : ''}
                        ${isPlaying || isAutoPicking ? 'disabled-hover' : ''}`}
                    onClick={() => handleNumberClick(number)}
                  >
                    {isRevealed ? (
                      <>
                        {isHit && <img src={KenoGem} alt="Winning Keno Number" className="keno-gem-image" />}
                        <span>{number}</span>
                      </>
                    ) : (
                      <span>{number}</span>
                    )}
                  </div>
                );
              })}
            </div>

            {showWinDisplay && winnings > 0 && (
              <div className={`center-display ${showWinDisplay ? "keno-win-fadein" : "keno-win-fadeout"}`}>
                <div className="multiplier">{(winnings / amount).toFixed(2)}x</div>
                <div className="divider"></div>
                <div className="keno-winnings">${winnings.toFixed(2)}</div>
              </div>
            )}

            <div className="keno-results">
              {revealedResult.length > 0 ? (
                (() => {
                  const { dynamicHits, dynamicWinnings } = calculateDynamicHitsAndWinnings();
                  return (
                    <h3>
                      Result: {dynamicHits} Hits, Winnings: ${dynamicWinnings.toFixed(2)}
                    </h3>
                  );
                })()
              ) : (
                <h3>Result: 0 Hits, Winnings: $0.00</h3>
              )}
            </div>

            {mode === 'Auto' && (
              <div className="autoplay-progress-container">
                <div className="autoplay-progress-bar" style={{ width: `${progress}%` }}>
                  {/* {numberOfGames > 0 && <p className="keno-autoplay-progress-bar">Playing game {autoResults.length} of {numberOfGames}</p>} */}
                </div>
              </div>
            )}

            {selectedNumbers.length === 0 ? (
              <div className="keno-instructions">
                <p>Select 1 - 10 numbers to play</p>
              </div>
            ) : (
              <div className="keno-probabilities">
                <h3>Probabilities</h3>
                <div className="probabilities-container">
                  {probabilities.map((prob, index) => (
                    <div key={index} className="probability-box">
                      {prob.hits} Hit: {prob.probability}
                    </div>
                  ))}
                </div>

                <div className="keno-hits-container">
                  {probabilities.map((prob, index) => {
                    const { dynamicHits } = calculateDynamicHitsAndWinnings(); // Get real-time hits

                    return (
                      <div key={index} className="keno-hit-wrapper">
                        <div className={`keno-hit-box ${isGameActive && revealedResult.length > 0 && prob.hits === dynamicHits
                          ? 'keno-hit-highlight'
                          : ''
                          }`}
                        >
                          {prob.hits}×
                        </div>
                        <div className={`keno-hit-indicator ${isGameActive && revealedResult.length > 0 && prob.hits === dynamicHits
                          ? 'keno-hit-indicator-active'
                          : ''
                          }`}
                        ></div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* EXAMPLE OF PASSING IN GAME DETAILS */}
      <GameDescCard
        gameTitle="Keno"
        totalBets={6896668}
        gameDescription="Keno is a thrilling lottery-style game where you pick your lucky numbers and watch as the draw unfolds! The more numbers you match, the bigger your payout. Whether you play it safe with a few selections or go all-in for massive rewards, Keno offers exciting gameplay and strategic betting options for every player."
        bigWins={[
          { user: 'Player1', bet: 2000, multiplier: 292.94, payout: 585880 },
          { user: 'Player2', bet: 989.59, multiplier: 292.94, payout: 289890.49 },
          { user: 'Player3', bet: 1500, multiplier: 150.5, payout: 225750 },
        ]}
        luckyWins={[
          { user: 'Player3', bet: 1500, multiplier: 150.5, payout: 225750 },
          { user: 'Player2', bet: 989.59, multiplier: 292.94, payout: 289890.49 },
          { user: 'Player1', bet: 2000, multiplier: 292.94, payout: 585880 },
        ]}
        gameImageUrl={gameImg}
      />
    </div>
  );
}

export default Keno;