import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
const Carousel = ({ cards, title, icon }) => {
  const [cardsPerPage, setCardsPerPage] = useState(8);
  const [currentPage, setCurrentPage] = useState(0);

  // Dynamically update cards per page based on screen size
  useEffect(() => {
    const updateCardsPerPage = () => {
      if (window.innerWidth <= 480) {
        setCardsPerPage(3); // Mobile view
      } else if (window.innerWidth <= 854) {
        setCardsPerPage(4);
      } else if (window.innerWidth <= 1021) {
        setCardsPerPage(5);
      } else if (window.innerWidth <= 1366) {
        setCardsPerPage(6);
      } else {
        setCardsPerPage(8); // Desktop view
      }
    };

    updateCardsPerPage(); // Set initial value
    window.addEventListener('resize', updateCardsPerPage);

    return () => window.removeEventListener('resize', updateCardsPerPage);
  }, []);

  const maxPages = Math.ceil(cards.length / cardsPerPage);

  const visibleCards = cards.slice(
    currentPage * cardsPerPage,
    currentPage * cardsPerPage + cardsPerPage
  );

  const nextPage = () => {
    if (currentPage < maxPages - 1) setCurrentPage((prev) => prev + 1);
  };

  const prevPage = () => {
    if (currentPage > 0) setCurrentPage((prev) => prev - 1);
  };

  return (
    <div className="carousel-container">
      <div className="carousel-header">
        <h1 className="game-slider-header">
          <FontAwesomeIcon icon={icon} className="game-icons slider-head-icon" />
          {title}
        </h1>
        {cards.length > cardsPerPage && (
          <div className="carousel-controls">
            <button
              className={`carousel-arrow ${currentPage === 0 ? 'disabled' : ''}`}
              onClick={prevPage}
              disabled={currentPage === 0}
            >
              <FontAwesomeIcon icon={faAngleLeft} />
            </button>
            <button
              className={`carousel-arrow ${currentPage === maxPages - 1 ? 'disabled' : ''}`}
              onClick={nextPage}
              disabled={currentPage === maxPages - 1}
            >
              <FontAwesomeIcon icon={faAngleRight} />
            </button>
          </div>
        )}
      </div>
      <div className="carousel-track-wrapper">
        <div className="carousel-track">
          {visibleCards.map((card, index) => (
            <div key={index} className="carousel-card">{card}</div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Carousel;
