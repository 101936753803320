import React, { useState, useEffect } from 'react';
import './SuccessModal.css';

const SuccessModal = ({ message, onClose, duration = 5000 }) => {

    const [isFadingOut, setIsFadingOut] = useState(false);

    useEffect(() => {
        if (!message) return;

        const fadeTimeout = setTimeout(() => {
            setIsFadingOut(true);
        }, duration - 300);

        const closeTimeout = setTimeout(() => {
            onClose();
            setIsFadingOut(false);
        }, duration);

        return () => {
            clearTimeout(fadeTimeout);
            clearTimeout(closeTimeout);
        };
    }, [message, onClose, duration]);

    useEffect(() => {
        if (!message) return;

        const timeout = setTimeout(() => {
            onClose();
        }, duration);

        return () => clearTimeout(timeout);
    }, [message, onClose, duration]);

    if (!message) return null;

    return (
        <div className="success-modal-backdrop">
            <div className={`success-modal ${isFadingOut ? 'fade-out' : ''}`}>
                <h2>Success</h2>
                <p>{message}</p>
                <div className="progress-bar-container">
                    <div className="success-modal-progress-bar"></div>
                </div>
                <button className="success-modal-close-btn" onClick={onClose}>✕</button>
            </div>
        </div>
    );
};

export default SuccessModal;
