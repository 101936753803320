import React from 'react';
import './LegalTexts.css';

const Disclaimer = () => {
    return (
        <div className="main">
            <div className="legal-container">
                <div className="legal-header">
                    <h1>Disclaimer</h1>
                </div>
                <div className="legal-content">

                    <p>
                        Welcome to Roulo. By accessing or using this website and its services, you agree to the following disclaimer.
                    </p>
                    <section>
                        <h2 className="legal-subheader">No Warranties</h2>
                        <p>
                            All information, software, products, and services on this website are provided "as is" without warranties of any kind. Use is at your own risk.
                        </p>
                    </section>
                    <section>
                        <h2 className="legal-subheader">Limitation of Liability</h2>
                        <p>
                            Under no circumstances shall RouloBets be liable for any damages arising from the use or inability to use this website.
                        </p>
                    </section>
                    <section>
                        <h2 className="legal-subheader">Third-Party Links</h2>
                        <p>
                            This website may contain links to third-party websites. RouloBets is not responsible for the content or practices of these websites.
                        </p>
                    </section>
                    <section>
                        <h2 className="legal-subheader">User Content</h2>
                        <p>
                            Users are responsible for their own submissions, interactions, and generated content on this website.
                        </p>
                    </section>
                    <section>
                        <h2 className="legal-subheader">Restricted Locations</h2>
                        <p>
                            Certain jurisdictions may restrict access to RouloBets. It is your responsibility to ensure compliance with local laws.
                        </p>
                    </section>
                    <section>
                        <h2 className="legal-subheader">Modifications</h2>
                        <p>
                            Roulo reserves the right to modify this disclaimer. Continued use of the website implies acceptance of these changes.
                        </p>
                        <p>
                            This disclaimer is governed by the laws of Curaçao. Disputes will be resolved through binding arbitration.
                        </p>
                        <p>
                            If you disagree with this disclaimer, please discontinue use of the website. Contact us for questions.
                        </p>
                    </section>
                </div>
            </div>
        </div>
    );
};

export default Disclaimer;
