import React from 'react';
import { Link } from 'react-router-dom';
import Mines from './img/mines_final.png';
import './GameElements.css';

function MinesCard(){
    return(
        <Link to="/mines" style={{ textDecoration: 'none', color: 'inherit' }}>
            <div className="cardElements">
                <img src={Mines} alt="Mines Game" />
            </div>
        </Link>
    );
}

export default MinesCard