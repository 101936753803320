import React from 'react';
import './LegalTexts.css';

const AccessibilityStatement = () => {
    return (
        <div className="main">
            <div className="legal-container">
                <div className="legal-header">
                    <h1>Accessibility Statement</h1>
                </div>
                <div className="legal-content">

                    <p className="legal-paragraph">
                        At Roulo, we are committed to making our website and services accessible to individuals with disabilities. We aim to meet internationally recognized best practices and guidelines including:
                    </p>
                    <ul className="legal-list">
                        <li>Web Content Accessibility Guidelines (WCAG) 2.1 Level AA</li>
                        <li>Americans with Disabilities Act (ADA) Standards</li>
                        <li>EN 301 549 European Accessibility Requirements</li>
                    </ul>
                    <p className="legal-paragraph">Our ongoing efforts include:</p>

                    <div>
                        <h2 className="legal-subheader">Website Compatibility</h2>
                        <p>
                            We design and code Roulobets.com to be compatible with current and future assistive technologies like screen readers, speech recognition software, and keyboard navigation tools.
                        </p>
                    </div>

                    <div>
                        <h2 className="legal-subheader">Accessible Content</h2>
                        <p>
                            We provide text alternatives for non-text content, ensure proper heading and link structure, allow for adjustable text sizing and contrast ratios, and avoid potential seizure triggers.
                        </p>
                    </div>

                    <div>
                        <h2 className="legal-subheader">User Experience Testing</h2>
                        <p>
                            We regularly audit our user experience and interfaces through manual and automated testing with assistive technologies. We promptly fix any accessibility barriers identified.
                        </p>
                    </div>

                    <div>
                        <h2 className="legal-subheader">Feedback and Reporting</h2>
                        <p>
                            We welcome feedback on how to improve Roulobets.com's accessibility through our dedicated email:
                            <a href="mailto:accessibility@rainbet.com"> accessibility@rainbet.com</a>.
                            You can also report any accessibility issues or barriers encountered.
                        </p>
                        <p>
                            While we are committed to maximizing the accessibility of our website, we recognize that some accessibility limitations may still exist. We appreciate your patience and partnership as we continually improve in this important area.
                        </p>
                        <p>
                            For any specific accommodation requests or accessibility-related questions, please contact our support team at
                            <a href="mailto:support@rainbet.com"> support@rainbet.com</a>.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AccessibilityStatement;
