import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [username, setUsername] = useState(null);
  const [balance, setBalance] = useState(0);
  const [bonus, setBonus] = useState(0);
  const [betMax, setBetMax] = useState(0);
  const [selectedBalanceType, setSelectedBalanceType] = useState('primary');
  const [wagers, setWagers] = useState(0);
  const [bets, setBets] = useState(0);
  const [promoActivatedButEnded, setPromoActivatedButEnded] = useState(false);
  const [promotion, setPromotion] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const profileRes = await axios.get('https://backend-service-643728542364.southamerica-east1.run.app/api/users/profile', {
          withCredentials: true,
        });
        setUsername(profileRes.data.username);

        const balanceRes = await axios.get('https://backend-service-643728542364.southamerica-east1.run.app/api/users/balance', {
          withCredentials: true,
        });
        setBalance(balanceRes.data.balance);

        const bonusRes = await axios.get('https://backend-service-643728542364.southamerica-east1.run.app/api/users/bonus', {
          withCredentials: true,
        });
        setBonus(bonusRes.data.bonus);

        const selectedBalanceTypeRes = await axios.get('https://backend-service-643728542364.southamerica-east1.run.app/api/users/selectedBalanceType', {
          withCredentials: true,
        });
        setSelectedBalanceType(selectedBalanceTypeRes.data.selectedBalanceType || 'primary');

        const wagersRes = await axios.get('https://backend-service-643728542364.southamerica-east1.run.app/api/users/totalCashWagers', {
          withCredentials: true,
        });
        setWagers(wagersRes.data.totalCashWagers);

        const betsRes = await axios.get('https://backend-service-643728542364.southamerica-east1.run.app/api/users/totalBets', {
          withCredentials: true,
        });
        setBets(betsRes.data.totalBets);

        const promoActivatedButEndedRes = await axios.get('https://backend-service-643728542364.southamerica-east1.run.app/api/users/promoActivatedButEnded', {
          withCredentials: true,
        });
        setPromoActivatedButEnded(promoActivatedButEndedRes.data.promoActivatedButEnded);

        const promotionRes = await axios.get('https://backend-service-643728542364.southamerica-east1.run.app/api/users/promotion', {
          withCredentials: true,
        });
        setPromotion(promotionRes.data.promotion);

        const betMaxRes = await axios.get('https://backend-service-643728542364.southamerica-east1.run.app/api/users/maxBetBonus', {
          withCredentials: true,
        });
        setBetMax(betMaxRes.data.maxBet);
      } catch (error) {
        console.error('Could not fetch profile or balance');
      } finally {
        setLoading(false);
      }
    };

    fetchUserProfile();
  }, []);

  useEffect(() => {
    if (!username) return; // Skip polling if the user is not logged in

    const refreshTokenInterval = setInterval(async () => {
      try {
        await axios.post('https://backend-service-643728542364.southamerica-east1.run.app/api/users/refresh-token', {}, { withCredentials: true });
        console.log('Token refreshed successfully');
      } catch (error) {
        console.error('Failed to refresh token:', error);
      }
    }, 50 * 60 * 1000);  // 50 minutes

    return () => clearInterval(refreshTokenInterval);
  }, [username]);

  useEffect(() => {
    if (!username) return; // Skip polling if the user is not logged in

    const interval = setInterval(async () => {
      try {
        const balanceRes = await axios.get('https://backend-service-643728542364.southamerica-east1.run.app/api/users/balance', {
          withCredentials: true,
        });
        setBalance(balanceRes.data.balance);

        const bonusRes = await axios.get('https://backend-service-643728542364.southamerica-east1.run.app/api/users/bonus', {
          withCredentials: true,
        });
        setBonus(bonusRes.data.bonus);

        const wagersRes = await axios.get('https://backend-service-643728542364.southamerica-east1.run.app/api/users/totalCashWagers', {
          withCredentials: true,
        });
        setWagers(wagersRes.data.totalCashWagers);

        const betsRes = await axios.get('https://backend-service-643728542364.southamerica-east1.run.app/api/users/totalBets', {
          withCredentials: true,
        });
        setBets(betsRes.data.totalBets);

        const promoActivatedButEndedRes = await axios.get('https://backend-service-643728542364.southamerica-east1.run.app/api/users/promoActivatedButEnded', {
          withCredentials: true,
        });
        setPromoActivatedButEnded(promoActivatedButEndedRes.data.promoActivatedButEnded);

        const promotionRes = await axios.get('https://backend-service-643728542364.southamerica-east1.run.app/api/users/promotion', {
          withCredentials: true,
        });
        setPromotion(promotionRes.data.promotion);

        const betMaxRes = await axios.get('https://backend-service-643728542364.southamerica-east1.run.app/api/users/maxBetBonus', {
          withCredentials: true,
        });
        setBetMax(betMaxRes.data.betMax);
      } catch (error) {
        console.error('Error during polling', error);
      }
    }, 1000); // Poll every 1 seconds

    return () => clearInterval(interval);
  }, [username]); // Re-run when username is set

  return (
    <AuthContext.Provider value={{ username, setUsername, balance, setBalance, bonus, setBonus, loading, wagers, setWagers, bets, setBets, promoActivatedButEnded, setPromoActivatedButEnded, promotion, setPromotion, selectedBalanceType, setSelectedBalanceType, betMax, setBetMax }}>
      {children}
    </AuthContext.Provider>
  );
};
