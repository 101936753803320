import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './Navbar.css';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';
import RouloLogo from './img/roulo_logo_2_1.png';
import MobileLogo from './img/mini_logo.png';
import RouloGif from '../pages/imgs/Roulo_Loading_360.gif'
import DepositModal from './DepositModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faGear, faUser, faGift } from '@fortawesome/free-solid-svg-icons';
import ProfileModel from './ProfileModel';
import RegisterModel from './Register';
import SignInModel from './SignIn';

const Navbar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { username, balance, loading, bonus, selectedBalanceType, setSelectedBalanceType } = useContext(AuthContext);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isOpen, setIsOpen] = useState(false);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [isRegisterModelOpen, setIsRegisterModelOpen] = useState(false);
  const [isSignInModelOpen, setIsSignInModelOpen] = useState(false);
  const [isBalanceDropdownOpen, setIsBalanceDropdownOpen] = useState(false);
  // const [isWalletOpen, setIsWalletOpen] = useState(false);
  // const [selectedBalance, setSelectedBalance] = useState('primary'); // Default selected type

  // Define restricted game routes
  const restrictedRoutes = ['/plinko', '/dice', '/keno', '/mines', '/slotgame'];

  // Check if the current route is restricted
  const isRestricted = restrictedRoutes.includes(location.pathname);

  const toggleBalanceMenu = () => {
    setIsBalanceDropdownOpen(!isBalanceDropdownOpen);
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const modal = queryParams.get('modal');
    const model = queryParams.get('model');
    const regModel = queryParams.get('regModel');
    const signInModel = queryParams.get('signInModel');
    const tab = queryParams.get('tab');

    if (modal === 'cashier' && tab === 'deposit') {
      setIsModalOpen(true);
    }
    if (model === 'profile' && tab === 'profile') {
      setIsModelOpen(true);
    }
    if (regModel === 'register' && tab === 'register') {
      setIsRegisterModelOpen(true);
    }
    if (signInModel === 'signin' && tab === 'signin') {
      setIsSignInModelOpen(true);
    }
  }, [location]);

  // Handle screen resize
  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleBalanceSelection = async (type) => {
    if (!isRestricted) {
      try {
        setSelectedBalanceType(type); // Update the selected balance type in AuthContext
        setIsBalanceDropdownOpen(false); 
  
        // Make the API call to update the balance type on the server
        await axios.post(
          'https://backend-service-643728542364.southamerica-east1.run.app/api/users/updateBalanceType',
          { selectedBalanceType: type },
          { withCredentials: true } 
        );
  
        console.log(`Updated balance type to: ${type}`);
      } catch (error) {
        console.error('Failed to update selected balance type:', error);
      }
    }
  };
  

  const openModal = () => navigate('/?modal=cashier&tab=deposit');
  const closeModal = () => {
    setIsModalOpen(false);
    navigate('/');
  };

  const openModel = () => navigate('/?model=profile&tab=profile');
  const closeModel = () => {
    setIsModelOpen(false);
    navigate('/');
  };

  const openRegisterModel = () => navigate('/?regModel=register&tab=register');
  const closeRegisterModel = () => {
    setIsRegisterModelOpen(false);
    navigate('/');
  }

  const openSignInModel = () => navigate('/?signInModel=signin&tab=signin');
  const closeSignInModel = () => {
    setIsSignInModelOpen(false);
    navigate('/');
  }

  const handleUserOutsideClick = (e) => {
    if (isOpen && !e.target.closest(".navbar-username")) {
      setIsOpen(false);
    }
  };
  useEffect(() => {
    window.addEventListener('click', handleUserOutsideClick);

    return () => {
      window.removeEventListener('click', handleUserOutsideClick);
    };
  }, [isOpen]);

  const handleBalanceOutsideClick = (e) => {
    if (isBalanceDropdownOpen && !e.target.closest(".account-dropdown-menu") && !e.target.closest(".account-dropdown-btn")) {
      setIsBalanceDropdownOpen(false);
    }
  };
  useEffect(() => {
    window.addEventListener('click', handleBalanceOutsideClick);

    return () => {
      window.removeEventListener('click', handleBalanceOutsideClick);
    };
  }, [isBalanceDropdownOpen]);

  // const handleWalletOutsideClick = (e) => {
  //   if (isWalletOpen && !e.target.closest("/?modal=cashier&tab=deposit") && !e.target.closest("/?modal=cashier&tab=deposit")) {
  //     setIsWalletOpen(false);
  //   }
  // };
  // useEffect(() => {
  //   window.addEventListener('click', handleWalletOutsideClick);

  //   return () => {
  //     window.removeEventListener('click', handleWalletOutsideClick);
  //   };
  // }, [isWalletOpen]);

  const handleLogout = async () => {
    try {
      const response = await fetch('https://backend-service-643728542364.southamerica-east1.run.app/api/users/logout', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include' // This ensures cookies are sent and received
      });

      if (response.ok) {
        // Handle frontend state or redirection after logout
        window.location.href = '/';
      } else {
        console.error('Logout failed');
      }
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  return (
    <>
      <header className="header">
        <a href="/" className="logo">
          {/* <img src={RouloLogo} className="roulo-logo" alt="Roulo" /> */}
          <img
            src={screenWidth <= 600 ? MobileLogo : RouloLogo}
            className="roulo-logo"
            alt="Roulo"
          />
        </a>
        <button className="menu-toggle" onClick={toggleMenu}>
          <i className={`fas ${isOpen ? 'fa-times' : 'fa-bars'}`}></i>
        </button>
        <nav className="rightNav">
          {loading ? (
            <span className="navbar-username">
              <img
                src={RouloGif}
                alt="Loading..."
                className="navbar-loading-gif"
              />
            </span>
          ) : (
            username ? (
              <>
                {/* <div className="middleNav-container"> */}
                <div className="middleNav deposit-container">
                  <button className="account-dropdown-btn" onClick={toggleBalanceMenu}>
                    {selectedBalanceType === 'primary' ? (
                      <>
                        <span className="user-balance">
                          ${balance.toFixed(2)}
                          <img src={MobileLogo} alt="Logo" className="primary-balance-icon navbar-primary-balance-icon" />
                        </span>
                        <i className={`fas fa-caret-down ${isBalanceDropdownOpen ? 'open' : ''}`}></i>
                      </>
                    ) : (
                      <>
                        <span className="user-balance">
                          ${bonus.toFixed(2)}
                          <FontAwesomeIcon icon={faGift} className="promo-balance-icon navbar-promo-balance-icon" />
                        </span>
                        <i className={`fas fa-caret-down ${isBalanceDropdownOpen ? 'open' : ''}`}></i>
                      </>
                    )}
                  </button>
                  <div className={`account-dropdown-menu ${isBalanceDropdownOpen ? 'open' : ''}`}>
                    {/* Primary Balance */}
                    <div className="account-dropdown-item" onClick={() => handleBalanceSelection('primary')}>
                      <div className="account-dropdown-content">
                        <div className="promo-balance-title-container">
                          <span className="account-balance-title">Primary Balance</span>
                          <img src={MobileLogo} alt="Logo" className="primary-balance-icon" />
                        </div>
                        <span className="account-balance-amount">${balance.toFixed(2)}</span>
                      </div>
                      <div
                        className={`account-balance-circle-indicator ${selectedBalanceType === 'primary' ? 'selected' : ''
                          }`}
                      ></div>
                    </div>
                    {/* Promotional Balance */}
                    <div className="account-dropdown-item" onClick={() => handleBalanceSelection('promotional')}>
                      <div className="account-dropdown-content">
                        <div className="promo-balance-title-container">
                          <span className="account-balance-title">Promotional Balance</span>
                          <FontAwesomeIcon icon={faGift} className="promo-balance-icon" />
                        </div>
                        <span className="account-balance-amount">${bonus.toFixed(2)}</span>
                      </div>
                      <div
                        className={`account-balance-circle-indicator ${selectedBalanceType === 'promotional' ? 'selected' : ''}`}
                      ></div>
                    </div>
                    <div className="account-dropdown-divider"></div>
                    <div className="currency-notice">
                      <span>* Currency displayed in USD</span>
                    </div>
                  </div>
                </div>

                <div className="middleNav deposit-container">
                  <button className="deposit" onClick={openModal}>
                    Wallet
                  </button>
                </div>

                <div className="btn-reg btn-username navbar-profile" onClick={toggleMenu}>
                  <span className="navbar-username">
                    {username}
                    <i className={`fas fa-caret-down ${isOpen ? 'open' : ''}`}></i>
                  </span>
                  <div className={`user-dropdown-menu ${isOpen ? 'open' : ''}`}>
                    <a onClick={openModel} className="user-dropdown-item">
                      <FontAwesomeIcon icon={faUser} className="user-icon" />
                      Profile
                    </a>
                    <Link to="/settings" className="user-dropdown-item">
                      <FontAwesomeIcon icon={faGear} className="user-icon" />
                      Settings
                    </Link>
                    <a onClick={handleLogout} className="user-dropdown-item">
                      <FontAwesomeIcon icon={faSignOutAlt} className="user-icon" />
                      Logout
                    </a>
                  </div>
                </div>
                {/* </div> */}
              </>
            ) : (
              <>
                <div className="signin-reg-container">
                  <a onClick={openSignInModel} className="btn-in">Sign In</a>
                  <a onClick={openRegisterModel} className="btn-reg">Register</a>
                </div>
              </>
            )
          )}
        </nav>
      </header>
      <DepositModal isOpen={isModalOpen} closeModal={closeModal} />
      <ProfileModel isOpen={isModelOpen} closeModal={closeModel} />
      <SignInModel isOpen={isSignInModelOpen} closeModal={closeSignInModel} />
      <RegisterModel isOpen={isRegisterModelOpen} closeModal={closeRegisterModel} />
    </>
  );
};

export default Navbar;