import React from 'react';
import GameCarousel from './GameCarousel';
import DiceCard from '../components/DiceCard';
import MinesCard from '../components/MinesCard';
// import BlackjackCard from '../components/BlackJackCard';
// import CrashCard from '../components/CrashCard';
import KenoCard from '../components/KenoCard';
// import BaccaratCard from '../components/BaccaratCard';
import GameElements from '../components/GameElements';
import { faMoneyBillWave } from '@fortawesome/free-solid-svg-icons';

const originalsCards = [
  <GameElements />,
  <DiceCard />,
  <MinesCard />,
  <KenoCard />
];

const OriginalsCarousel = () => {
  return <GameCarousel cards={originalsCards} title="Originals" icon={faMoneyBillWave} />;
};

export default OriginalsCarousel;
