import React, { useState, useRef, useEffect } from 'react';
import './SidebarNav.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faBars, faHome, faGamepad, faCircleDollarToSlot, faCircleDot, faDice, faBomb, faSquareXmark, faGift } from '@fortawesome/free-solid-svg-icons';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const SidebarNav = ({ toggleSidebar, isOpen, setIsOpen }) => {
    const [openTab, setOpenTab] = useState(null);
    const submenuRef = useRef(null);
    const location = useLocation();
    const navigate = useNavigate();
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 1300);
    // const [profileMenuOpen, setProfileMenuOpen] = useState(false);
    const [isModelOpen, setIsModelOpen] = useState(false);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const model = queryParams.get('model');
        const tab = queryParams.get('tab');

        if (model === 'profile' && tab === 'profile') {
            setIsModelOpen(true);
        }
    }, [location]);

    // const openModel = () => navigate('/?model=profile&tab=profile');

    // const toggleProfileMenu = () => {
    //     setProfileMenuOpen((prevState) => !prevState);
    // };

    // useEffect(() => {
    //     const handleResize = () => setIsMobileView(window.innerWidth <= 1300);
    //     window.addEventListener('resize', handleResize);
    //     return () => window.removeEventListener('resize', handleResize);
    // }, []);

    const toggleTab = (tab) => {
        if (openTab === tab) {
            setOpenTab(null);
        } else {
            setOpenTab(tab);
        }
    };

    const handleResize = () => {
        const isNowMobileView = window.innerWidth <= 1300;
        setIsMobileView(isNowMobileView);

        // Automatically close the sidebar if switching to mobile view
        if (isNowMobileView) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (submenuRef.current) {
            submenuRef.current.style.height = openTab === 'games' ? `${submenuRef.current.scrollHeight}px` : '0px';
        }
    }, [openTab]);

    useEffect(() => {
        if (isOpen) {
            document.body.classList.add('sidebar-open');
        } else {
            document.body.classList.remove('sidebar-open');
        }
    }, [isOpen]);

    const handleOriginalsClick = () => {
        if (isMobileView) {
            navigate('/originals'); // Redirect to Originals page
        } else {
            toggleTab('games'); // Open submenu in desktop view
        }
    };

    const handleLogout = async () => {
        try {
            const response = await fetch('https://backend-service-643728542364.southamerica-east1.run.app/api/users/logout', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include' // This ensures cookies are sent and received
            });

            if (response.ok) {
                // Handle frontend state or redirection after logout
                window.location.href = '/';
            } else {
                console.error('Logout failed');
            }
        } catch (error) {
            console.error('Logout error:', error);
        }
    };

    return (
        <div className={`sidenav-main-container ${isOpen ? 'sidebar-open' : ''}`}>
            <nav className={`sidebar ${isOpen ? 'open' : ''}`}>
                <div className="sidebar-header">
                    <button id="sidebar-btn" onClick={toggleSidebar} className={isOpen ? 'rotate' : ''}>
                        <FontAwesomeIcon icon={faBars} />
                    </button>
                </div>
                <ul className="sidebar-list">
                    <ul className="sidebar-items">
                        <li className="sidebar-item top">
                            <a href="./" className="sidebar-link">
                                <FontAwesomeIcon icon={faHome} title="Home" />
                                <span>Home</span>
                            </a>
                        </li>
                        <li className={`sidebar-item games ${openTab === 'games' ? 'active' : ''}`} onClick={handleOriginalsClick}>                                <div className="sidebar-link">
                            <a href="./originals" className="sidebar-link">
                                <FontAwesomeIcon icon={faGamepad} title="Games" />
                                <span>Originals</span>
                            </a>
                            <FontAwesomeIcon
                                icon={faAngleDown}
                                className={`dropdown-arrow ${openTab === 'games' ? 'rotate' : ''}`}
                            />
                            {/* {isOpen ? <span>Games</span> : <span className="tooltip">Games</span>} */}
                        </div>
                            {isOpen && openTab === 'games' && (
                                <ul className="submenu">
                                    <li><a href="./plinko"><FontAwesomeIcon icon={faCircleDot} className="game-icons" /> Plinko</a></li>
                                    <li><a href="./dice"><FontAwesomeIcon icon={faDice} className="game-icons" /> Dice</a></li>
                                    <li><a href="./mines"><FontAwesomeIcon icon={faBomb} className="game-icons" /> Mines</a></li>
                                    <li><a href="./keno"><FontAwesomeIcon icon={faSquareXmark} className="game-icons" /> Keno</a></li>
                                </ul>
                            )}
                        </li>
                        <li className={`sidebar-item bottom ${openTab === 'slots' ? 'active' : ''}`} onClick={() => isOpen && toggleTab('slots')}>
                            <div className="sidebar-link">
                                <a href="./slots" className="sidebar-link">
                                    <FontAwesomeIcon icon={faCircleDollarToSlot} title="Slots" />
                                    <span>Slots</span>
                                </a>
                            </div>
                        </li>
                        <li className={`sidebar-item promo ${openTab === 'promo' ? 'active' : ''}`} onClick={() => isOpen && toggleTab('promo')}>
                            <div className="sidebar-link">
                                <Link to="/promo" className="sidebar-link">
                                    <FontAwesomeIcon icon={faGift} title="Promo" />
                                    <span>Promotions</span>
                                </Link>
                            </div>
                        </li>
                        {/* {isMobileView && (
                            <li className="sidebar-item profile-section">
                                <a href="./?model=profile&tab=profile" className="sidebar-link">
                                    <FontAwesomeIcon icon={faUser} title="Profile" />
                                    <span>Profile</span>
                                </a>
                            </li>
                        )} */}
                    </ul>
                </ul>
            </nav>
        </div>
    );
};

export default SidebarNav;